interface ReturnSetHistoryDataProps {
  setHistoryImgActive: (
    value: React.SetStateAction<{
      img01: boolean;
      img02: boolean;
      img03: boolean;
      img04: boolean;
      img05: boolean;
      img06: boolean;
      img07: boolean;
      img08: boolean;
      img09: boolean;
      img10: boolean;
      img11: boolean;
      img12: boolean;
      img13: boolean;
      img14: boolean;
      img15: boolean;
      img16: boolean;
      img17: boolean;
      img18: boolean;
      img19: boolean;
    }>
  ) => void;
  setHistoryPercents: (value: React.SetStateAction<number>) => void;
  setInHistory: (value: React.SetStateAction<boolean>) => void;
  ScrollY: number;
  endHeigth: any;
  startHeigth: any;
  selectedYear: string;
  type: string;
}


export const ReturnSetHistoryData = (props: ReturnSetHistoryDataProps) => {
    const {
      setHistoryImgActive,
      setHistoryPercents,
      setInHistory,
      ScrollY,
      endHeigth,
      startHeigth,
      selectedYear,
      type
    } = props;
    const osType = type === "PC" ? pcHistoryPercent : mobileHistoryPercent;
    const lineHeigth = type === "PC" ? pcHistoryLine : mobileHistoryLine;
    
  if (ScrollY > startHeigth && ScrollY < endHeigth) {
    setInHistory(true);
    const percent = (ScrollY / endHeigth) * 100;
    console.log(percent);
    if (selectedYear) {
      setHistoryPercents(type === "PC" ? 68 : 58);
    } else {
       if (percent <= osType["img01"]) {
        
        
         setHistoryImgActive({
           img01: false,
           img02: false,
           img03: false,
           img04: false,
           img05: false,
           img06: false,
           img07: false,
           img08: false,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
       }

       
       if (
         percent > osType["img01"] &&
         percent <= osType["img02"]
       ) {
        console.log(percent, "/", osType["img01"]);
         setHistoryImgActive({
           img01: true,
           img02: false,
           img03: false,
           img04: false,
           img05: false,
           img06: false,
           img07: false,
           img08: false,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth['img01']);
       }
       if (
         percent > osType["img02"] &&
         percent <= osType["img03"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: false,
           img04: false,
           img05: false,
           img06: false,
           img07: false,
           img08: false,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img02"]);
       }
       if (
         percent > osType["img03"] &&
         percent <= osType["img04"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: false,
           img05: false,
           img06: false,
           img07: false,
           img08: false,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img03"]);
       }
       if (
         percent > osType["img04"] &&
         percent <= osType["img05"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: false,
           img06: false,
           img07: false,
           img08: false,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img04"]);
       }
       if (
         percent > osType["img05"] &&
         percent <= osType["img06"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: false,
           img07: false,
           img08: false,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img05"]);
       }
       if (
         percent > osType["img06"] &&
         percent <= osType["img07"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: false,
           img08: false,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img06"]);
       }
       if (
         percent > osType["img07"] &&
         percent <= osType["img08"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: false,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img07"]);
       }
       if (
         percent > osType["img08"] &&
         percent <= osType["img09"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: false,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img08"]);
       }
       if (
         percent > osType["img09"] &&
         percent <= osType["img10"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: false,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img09"]);
       }
       if (
         percent > osType["img10"] &&
         percent <= osType["img11"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: false,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img10"]);
       }
       if (
         percent > osType["img11"] &&
         percent <= osType["img12"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: false,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img11"]);
       }
       if (
         percent > osType["img12"] &&
         percent <= osType["img13"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: true,
           img13: false,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img12"]);
       }
       if (
         percent > osType["img13"] &&
         percent <= osType["img14"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: true,
           img13: true,
           img14: false,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img13"]);
       }
       if (
         percent > osType["img14"] &&
         percent <= osType["img15"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: true,
           img13: true,
           img14: true,
           img15: false,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img14"]);
       }
       if (
         percent > osType["img15"] &&
         percent <= osType["img16"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: true,
           img13: true,
           img14: true,
           img15: true,
           img16: false,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img15"]);
       }
       if (
         percent > osType["img16"] &&
         percent <= osType["img17"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: true,
           img13: true,
           img14: true,
           img15: true,
           img16: true,
           img17: false,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img16"]);
       }
       if (
         percent > osType["img17"] &&
         percent <= osType["img18"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: true,
           img13: true,
           img14: true,
           img15: true,
           img16: true,
           img17: true,
           img18: false,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img17"]);
       }
       if (
         percent > osType["img18"] &&
         percent <= osType["img19"]
       ) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: true,
           img13: true,
           img14: true,
           img15: true,
           img16: true,
           img17: true,
           img18: true,
           img19: false,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img18"]);
       }
       if (percent > osType["img19"]) {
         setHistoryImgActive({
           img01: true,
           img02: true,
           img03: true,
           img04: true,
           img05: true,
           img06: true,
           img07: true,
           img08: true,
           img09: true,
           img10: true,
           img11: true,
           img12: true,
           img13: true,
           img14: true,
           img15: true,
           img16: true,
           img17: true,
           img18: true,
           img19: true,
         });
         setHistoryPercents((ScrollY / endHeigth) * 100 + lineHeigth["img19"]);
       }
    }
  } else {
    setInHistory(false);
  }
};

export const pcHistoryPercent = {
  img01: 43,
  img02: 46,
  img03: 49,
  img04: 52,
  img05: 55,
  img06: 58,
  img07: 61,
  img08: 64,
  img09: 67,
  img10: 70,
  img11: 73,
  img12: 76,
  img13: 79,
  img14: 82,
  img15: 85,
  img16: 88,
  img17: 91,
  img18: 94,
  img19: 97,
};
export const mobileHistoryPercent = {
  img01: 50,
  img02: 53,
  img03: 56,
  img04: 58,
  img05: 61,
  img06: 64,
  img07: 67,
  img08: 70,
  img09: 73,
  img10: 76,
  img11: 79,
  img12: 82,
  img13: 85,
  img14: 88,
  img15: 91,
  img16: 93,
  img17: 96,
  img18: 98,
  img19: 99,
};
export const pcHistoryLine = {
  img01: 25,
  img02: 27,
  img03: 28,
  img04: 29,
  img05: 30,
  img06: 33,
  img07: 34,
  img08: 35,
  img09: 40,
  img10: 42,
  img11: 50,
  img12: 51,
  img13: 52,
  img14: 59,
  img15: 62,
  img16: 64,
  img17: 65,
  img18: 69,
  img19: 70
};
export const mobileHistoryLine = {
  img01: 8,
  img02: 9,
  img03: 10,
  img04: 11,
  img05: 14,
  img06: 19,
  img07: 21,
  img08: 22,
  img09: 26,
  img10: 29,
  img11: 36,
  img12: 37,
  img13: 39,
  img14: 46,
  img15: 49,
  img16: 52,
  img17: 53,
  img18: 55,
  img19: 58,
};

// export const pcHistoryPercent = {
//   img01: 38,
//   img02: 41,
//   img03: 42,
//   img04: 46,
//   img05: 47,
//   img06: 52,
//   img07: 53,
//   img08: 56,
//   img09: 60,
//   img10: 63,
//   img11: 69,
//   img12: 72,
//   img13: 77,
//   img14: 82,
//   img15: 86,
//   img16: 88,
//   img17: 93,
//   img18: 96,
//   img19: 99,
// };

export const defaultHistoryImg = {
  img01: false,
  img02: false,
  img03: false,
  img04: false,
  img05: false,
  img06: false,
  img07: false,
  img08: false,
  img09: false,
  img10: false,
  img11: false,
  img12: false,
  img13: false,
  img14: false,
  img15: false,
  img16: false,
  img17: false,
  img18: false,
  img19: false
};

const test = [];

const setHistoryImg = () => {
  
}