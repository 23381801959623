import axios from "axios";
import { BASE_URL } from "./config";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(
    (response: any) => {
        if (response?.data?.errorCode === 'UNAUTHORIZED') {
            // alert("로그인 정보가 만료되었거나 다른 곳에서 로그인을 시도하였습니다.");
            // setAuthorization(null);
            // sessionStorage.removeItem(AUTH_SESSION_KEY);
            // window.location.href = "/errorUnauthorized"
        }
        return response;
    },
    (error) => {
        // TODO: 에러처리 진행해야함
        console.log(error);
        
    }
);

// const AUTH_SESSION_KEY = 'piece_admin_user';
// const ID_SESSION_KEY = 'piece_admin_id';

// const setAuthorization = (token: string | null) => {
//     if (token) instance.defaults.headers.common['X-AUTH-TOKEN'] = token;
//     else delete instance.defaults.headers.common['X-AUTH-TOKEN'];
// }

// const getUserFromCookie = () => {
//     const user = sessionStorage.getItem(AUTH_SESSION_KEY);
//     return user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
// }

class APICore {
     /**
     * Fetches data from given url
     */
      get = (url: string, params: any) => {
        let response;
        
        if (params) {
            var queryString = params.params
                ? Object.keys(params.params)
                      .map((key) => key + '=' + params.params[key])
                      .join('&')
                : '';
            response = instance.get(`${url}?${queryString}`, params.params);
        } else {
            response = instance.get(`${url}`, params);
        }
        return response;
    };
    
    getWithBody = (url: string, body: any) => {
        let response;
        
        response = instance.get(`${url}`, body);    
        return response;
    };

    getFile = (url: string, params: any) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = instance.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = instance.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls: string, params: any) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url: string, data: any) => {
        return instance.post(url, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url: string, data: any) => {
        return instance.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: any) => {

        
        return instance.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url: string) => {
        return instance.delete(url);
    };

    deleteWithBody = (url: string, data: any) => {
        return instance.delete(url, {data});
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url: string, data: any, fileDvn?: string) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append('file', data[k]);
            if (fileDvn) formData.append('fileDvn', fileDvn);
            
        }
        const config: any = {
            headers: {
                ...instance.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return instance.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...instance.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return instance.patch(url, formData, config);
    };

}

export { APICore };
