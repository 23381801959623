import { Route, RouteProps } from 'react-router-dom';

import EmployeeBenefits from '../pages/employeeBenefits/EmployeeBenefits';
import Employment from '../pages/employment/Employment';
import EmploymentDetail from '../pages/employmentDetail/EmploymentDetail';
import Login from '../pages/main/Main';
import Main from '../pages/main/Main';
import News from '../pages/news/News';
import NewsDetail from '../pages/newsDetail/NewsDetail';
// components
import PrivateRoute from './PrivateRoute';
import React from 'react';
import Root from './Root';

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Login />,
    route: Route,
};

// auth
const pageRoutes: RoutesProps[] = [
    {
        path: '/main',
        name: 'Main',
        component: Main,
        route: Route,
    },
    {
        path: '/news/:slug',
        name: 'News',
        component: News,
        route: Route,
    },
    {
        path: '/newsDetail/:slug',
        name: 'NewsDetail',
        component: NewsDetail,
        route: Route,
    },

    {
        path: '/employee_benefits',
        name: 'EmployeeBenefits',
        component: EmployeeBenefits,
        route: Route,
    },
    {
        path: '/employment/:slug',
        name: 'Employment',
        component: Employment,
        route: Route,
    },
    {
        path: '/employmentDetail/:slug',
        name: 'EmploymentDetail',
        component: EmploymentDetail,
        route: Route,
    },
];

const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};
const homeRoutes = [rootRoute, ...pageRoutes];
const publicHomeRoutesRoutes = flattenRoutes([...homeRoutes]);
export { homeRoutes, publicHomeRoutesRoutes };
