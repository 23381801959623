import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Pagination, { PaginationProps } from '../../components/Paging/Paging';
import { getBoardDetail } from '../../helpers/api/boards';
import { useFetchPressDetail } from '../../hooks/useFetchBoards';
import IconCall from '../../assets/images/common/icon-call.svg';
import { Link } from 'react-router-dom';
import { useFetchEmploymentsDetail } from '../../hooks/useFetchEmployment';
const EmploymentDetail = ({match}: any) => {
    const { slug } = match.params;
    const { item } = useFetchEmploymentsDetail(slug);
	const [employmentDetail, setEmploymentDetail] = useState<any[] | []>([]);
	useEffect(() => {  
        console.log({item});
        console.log({item});
        console.log({item});
        console.log({item});
        if(item) {
            
        console.log({item});    
			setEmploymentDetail(item)
        }
        
    }, [item])
    return (
        <>
            <div className="sub">
			<div className="sub-title">RECRUIT</div>
			<div className="sub-content bbs-view">
				<div className="sub-content-cont">
					{/* <!-- 뷰페이지 상단 출력 --> */}
					<div className="bbs-view-header">
						<div className="title">{item?.statusName || ''}</div>
						{/* <div className="date">{item.statusName}</div> */}
					</div>
					{/* <!-- 뷰페이지 상단 출력 --> */}
					{/* <!-- 뷰페이지 내용 출력 --> */}
					<div className="bbs-view-cont">
						<div className="bbs-view-cont-img">
							<img src={item?.boadrThumbnailPath || ''} />
						</div>
						<div className="bbs-view-cont-text">
                            <div dangerouslySetInnerHTML={{ __html: item?.contents || '' }}></div>
						</div>
					</div>
					{/* <!-- 뷰페이지 내용 출력 --> */}
				</div>
				<div className="bbs-btns">
					<Link to="/employment">
						<a><span className="bbs-btn">목록</span></a>
					</Link>
				</div>
			</div>
		</div>
        </>
    );
};

export default EmploymentDetail;
