import { Box, Button, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { TBoard, useTopBoardsQuery } from '../../generated/graphql';

import { MakeDotDotDot } from '../../utills/dotdotdot';
import moment from 'moment';
import { motion } from 'framer-motion';

interface TopBoardsProps {}

const TopBoardsSection = () => {
    const history = useHistory();

    const [slideCount, setSlideCount] = useState(0); // 슬라이드의 총 개수를 설정하세요
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const imgSettings: Settings = {
        dots: false,
        infinite: true,
        // autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        onSwipe: direction => {
            // direction은 'left' 또는 'right'일 수 있습니다.
            console.log('Swiped direction:', direction);
            if (direction) {
                if (direction === 'right') {
                    slider2 && slider2.slickPrev();
                    if (currentSlide === 0) {
                        setCurrentSlide(slideCount - 1);
                    } else {
                        setCurrentSlide(currentSlide - 1);
                    }
                } else {
                    slider2 && slider2.slickNext();
                    if (currentSlide === slideCount - 1) {
                        setCurrentSlide(0);
                    } else {
                        setCurrentSlide(currentSlide + 1);
                    }
                }
            }

            // 마우스로 슬라이드를 시켰을 때 원하는 작업 수행
        },
        // beforeChange: index => {
        //     setCurrentSlide(index);
        // },
    };
    const mobileSettings: Settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };
    const settings: Settings = {
        dots: false,
        infinite: true,
        // autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: true, // 세로 슬라이드를 사용하도록 설정
        verticalSwiping: true, // 세로 스와이프를 사용하도록 설정
        onSwipe: direction => {
            // direction은 'left' 또는 'right'일 수 있습니다.
            console.log('Swiped direction:', direction);
            if (direction) {
                if (direction === 'right') {
                    slider1 && slider1.slickPrev();
                    if (currentSlide === 0) {
                        setCurrentSlide(slideCount - 1);
                    } else {
                        setCurrentSlide(currentSlide - 1);
                    }
                } else {
                    slider1 && slider1.slickNext();
                    if (currentSlide === slideCount - 1) {
                        setCurrentSlide(0);
                    } else {
                        setCurrentSlide(currentSlide + 1);
                    }
                }
            }

            // 마우스로 슬라이드를 시켰을 때 원하는 작업 수행
        },
        // afterChange: index => {
        //     if (index === slideCount - 1) {
        //         slider1 && slider1.slickNext();
        //     } else {
        //         slider1 && slider1.slickGoTo(index);
        //     }
        //     setCurrentSlide(index);
        // },
    };

    let slider1: Slider | null;
    let slider2: Slider | null;
    let slider3: Slider | null;

    const goToSlide = (index: number) => {
        if (index !== currentSlide) {
            console.log(slider2);

            if (currentSlide === slideCount - 1) {
                // slider3 && slider3.slickNext();
                slider2 && slider2.slickNext();
                slider1 && slider1.slickNext();
            } else {
                // slider3 && slider3.slickGoTo(index);
                slider2 && slider2.slickGoTo(index);
                slider1 && slider1.slickGoTo(index);
            }

            setCurrentSlide(index);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentSlide === slideCount - 1) {
                // slider3 && slider3.slickNext();
                slider2 && slider2.slickNext();
                slider1 && slider1.slickNext();
                setCurrentSlide(0);
            } else {
                goToSlide(currentSlide + 1);
            }
        }, 3000);

        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시에 clearInterval 호출
    }, [currentSlide, slideCount]);

    const { data } = useTopBoardsQuery();

    // 새롭게 작성하는 코드

    useEffect(() => {
        setSlideCount(data?.topBoards?.boards?.length || 0);
    }, [data?.topBoards?.boards]);
    return (
        <>
            <Flex w={'100%'} mt={'80px'} p={0} className="main_new_image_web">
                <Grid templateColumns="repeat(2, 1fr)" gap={48}>
                    <GridItem
                        bg="blue.500"
                        maxW={'696px'}
                        width={'100%'}
                        borderRadius={'10px'}
                        overflow={'hidden'}
                        border={'1px solid rgba(0, 0, 0, 0.1)'}
                        h={'435px'}
                    >
                        <Slider {...imgSettings} ref={(sliderRef: Slider | null) => (slider1 = sliderRef)}>
                            {data?.topBoards?.boards?.map((item: TBoard) => {
                                const { board_id, boadr_thumbnail_path } = item;
                                return (
                                    // <Box>

                                    // </Box>
                                    <Box h={'435px'} w={'696px'} position="relative" overflow={'hidden'}>
                                        <Image
                                            src={boadr_thumbnail_path || ''}
                                            key={board_id}
                                            backgroundColor={'gray'}
                                            position="absolute"
                                            top="50%"
                                            left="50%"
                                            transform="translate(-50%, -50%)"
                                            minWidth="100%"
                                            minHeight="100%"
                                            objectFit={'cover'}
                                            // px={'48px'}
                                        ></Image>
                                    </Box>
                                );
                            })}
                        </Slider>
                    </GridItem>
                    <GridItem bg="blue.500" maxW={'696px'} width={'100%'} h={'435px'} cursor={'pointer'}>
                        <Box width={'696px'} h={'435px'}>
                            <Flex>
                                {/* <Slider
                                                {...settings}
                                                ref={(sliderRef: Slider | null) => (slider = sliderRef)}
                                            > */}
                                <Box h={'435px'}>
                                    <Grid
                                        templateAreas={`                  "nav main"
                  "nav footer"`}
                                        gridTemplateRows={'300px 1fr 30px'}
                                        gridTemplateColumns={'170px 1fr'}
                                        // h="500px"
                                        gap="1"
                                        color="blackAlpha.700"
                                        fontWeight="bold"
                                    >
                                        <GridItem pl="2" bg="pink.300" area={'nav'} px={'48px'}>
                                            <Slider
                                                {...settings}
                                                ref={(sliderRef: Slider | null) => (slider2 = sliderRef)}
                                            >
                                                {data?.topBoards?.boards?.map((item: TBoard) => {
                                                    const { created_at, board_id } = item;
                                                    return (
                                                        <Flex flexDir={'column'} w={'80px !important'} key={board_id}>
                                                            <Text
                                                                fontWeight={'500'}
                                                                fontSize={'20px'}
                                                                lineHeight={'30px'}
                                                                letterSpacing="-0.03em"
                                                                m={0}
                                                            >
                                                                {moment(created_at).format('YYYY.MM')}
                                                            </Text>
                                                            <Text
                                                                fontWeight={700}
                                                                fontSize={'60px'}
                                                                lineHeight={'60px'}
                                                                letterSpacing="-0.03em"
                                                                m={0}
                                                            >
                                                                {moment(created_at).format('DD')}
                                                            </Text>
                                                        </Flex>
                                                    );
                                                })}
                                            </Slider>
                                        </GridItem>
                                        <GridItem
                                            pl="2"
                                            bg="green.300"
                                            area={'main'}
                                            onClick={() => {
                                                history.push(
                                                    '/newsDetail/' + data?.topBoards?.boards[currentSlide]?.board_id
                                                );
                                            }}
                                        >
                                            <Box position={'relative'} h={'435px'}>
                                                <Box w={'472px'} pr={'48px'}>
                                                    <Box w={'100%'}>
                                                        {data?.topBoards?.boards?.map((item: TBoard, index: number) => {
                                                            const { created_at, board_id, title, contents } = item;
                                                            return (
                                                                <>
                                                                    {currentSlide === index && (
                                                                        <>
                                                                            <motion.div
                                                                                key={board_id}
                                                                                initial={{
                                                                                    opacity: 0,
                                                                                    y: 30,
                                                                                }} // 초기 상태
                                                                                animate={{
                                                                                    opacity: 1,
                                                                                    y: 0,
                                                                                }} // 애니메이션 상태
                                                                                transition={{
                                                                                    duration: 0.5,
                                                                                }} // 애니메이션 지속 시간
                                                                            >
                                                                                <Text
                                                                                    fontWeight={700}
                                                                                    fontSize={'40px'}
                                                                                    lineHeight={'60px'}
                                                                                    letterSpacing="-0.03em"
                                                                                    m={0}
                                                                                    mb={'16px'}
                                                                                >
                                                                                    {title}
                                                                                </Text>
                                                                            </motion.div>
                                                                            <motion.div
                                                                                initial={{
                                                                                    opacity: 0,
                                                                                    y: 30,
                                                                                }} // 초기 상태
                                                                                animate={{
                                                                                    opacity: 1,
                                                                                    y: 0,
                                                                                }} // 애니메이션 상태
                                                                                transition={{
                                                                                    duration: 0.5,
                                                                                }} // 애니메이션 지속 시간
                                                                            >
                                                                                <Box
                                                                                    fontWeight={500}
                                                                                    fontSize={'18px'}
                                                                                    lineHeight={'27px'}
                                                                                    color={'#757983'}
                                                                                    letterSpacing="-0.03em"
                                                                                    m={0}
                                                                                    h={'250px'}
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                            MakeDotDotDot({
                                                                                                length: 114,
                                                                                                text: contents || '',
                                                                                            }) || '',
                                                                                    }}
                                                                                ></Box>
                                                                            </motion.div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </Box>
                                                </Box>

                                                <Flex
                                                    position={'absolute'}
                                                    bottom={'0px'}
                                                    w={'100%'}
                                                    onClick={(event: any) => {
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    <Flex
                                                        justifyContent={'space-between'}
                                                        w={'100%'}
                                                        alignItems={'center'}
                                                        flexDirection={'row'}
                                                    >
                                                        <Box>
                                                            {Array.from({ length: slideCount }).map((_, index) => (
                                                                <span
                                                                    key={index}
                                                                    onClick={() => goToSlide(index)}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        marginRight: '5px',
                                                                        fontSize: '8px',
                                                                        color:
                                                                            currentSlide === index
                                                                                ? '#131313'
                                                                                : 'rgba(19, 19, 19, 0.2)',
                                                                    }}
                                                                >
                                                                    ●
                                                                </span>
                                                            ))}
                                                        </Box>
                                                        <Box>
                                                            <Link
                                                                to={
                                                                    '/newsDetail/' +
                                                                    data?.topBoards?.boards[currentSlide]?.board_id
                                                                }
                                                            >
                                                                <Button
                                                                    w={'128px'}
                                                                    h={'52px'}
                                                                    borderRadius={'48px'}
                                                                    border={'2px solid #131313'}
                                                                    backgroundColor={'#ffffff'}
                                                                    color={'#131313'}
                                                                    fontSize={'18px'}
                                                                    fontWeight={500}
                                                                    cursor={'pointer'}
                                                                    letterSpacing="-0.03em"
                                                                    right={'40px'}
                                                                    _hover={{
                                                                        color: '#3766C2',
                                                                        borderColor: '#3766C2',
                                                                    }}
                                                                >
                                                                    자세히 보기
                                                                </Button>
                                                            </Link>
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </Box>
                            </Flex>
                            {/* </Slider> */}
                        </Box>
                    </GridItem>
                </Grid>
            </Flex>
            <Flex w={'100%'} mt={'80px'} p={0} className="main_new_image_mobile" px={'20px'}>
                <Slider {...mobileSettings}>
                    {data?.topBoards?.boards?.map((item: TBoard) => {
                        const { board_id, boadr_thumbnail_path, title, contents, created_at } = item;
                        return (
                            <Flex
                                key={board_id}
                                onClick={() => {
                                    history.push('/newsDetail/' + data?.topBoards?.boards[currentSlide]?.board_id);
                                }}
                            >
                                <Link to={'/newsDetail/' + data?.topBoards?.boards[currentSlide]?.board_id}>
                                    <Image src={boadr_thumbnail_path || ''} borderRadius={'8px'} w={'100%'} />
                                    <Flex px={'20px'} pt={'24px'}>
                                        <Flex w={'80px'} flexDir={'column'}>
                                            <Text
                                                fontWeight={'500'}
                                                fontSize={'14px'}
                                                lineHeight={'21px'}
                                                letterSpacing="-0.03em"
                                                m={0}
                                            >
                                                {moment(created_at).format('YYYY.MM')}
                                            </Text>
                                            <Text
                                                fontWeight={700}
                                                fontSize={'35px'}
                                                lineHeight={'35px'}
                                                letterSpacing="-0.03em"
                                                m={0}
                                            >
                                                {moment(created_at).format('DD')}
                                            </Text>
                                        </Flex>
                                        <Flex flexDir={'column'} pl={'16px'}>
                                            <Text
                                                fontWeight={700}
                                                fontSize={'25px'}
                                                lineHeight={'35px'}
                                                letterSpacing="-0.03em"
                                                m={0}
                                            >
                                                {title}
                                            </Text>
                                            <Box
                                                fontWeight={500}
                                                fontSize={'16px'}
                                                lineHeight={'24px'}
                                                color={'#757983'}
                                                letterSpacing="-0.03em"
                                                m={0}
                                                mt={'8px'}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        MakeDotDotDot({
                                                            length: 114,
                                                            text: contents || '',
                                                        }) || '',
                                                }}
                                            ></Box>
                                        </Flex>
                                    </Flex>
                                </Link>
                            </Flex>
                        );
                    })}
                </Slider>
            </Flex>
        </>
    );
};

export default TopBoardsSection;
