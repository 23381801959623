import React, { useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

export interface PaginationProps {
    page: number;
    pageSize: number;
    totalPage: number;
    totalCount?: number;
    totalLength?: number;
    sort?: string;
    handlerPagination: (page: number) => void;
}

const Pagination = (pageProps: PaginationProps) => {
    const [pageCount, setPageCount] = useState<number>(pageProps?.totalPage!);
    const [pageIndex, setPageIndex] = useState<number>(pageProps?.page! - 1);
    useEffect(() => {
        setPageCount(pageProps?.totalPage!);
        setPageIndex(pageProps?.page! - 1);
    }, [pageProps]);

    const filterPages = useCallback(
        (visiblePages: any, totalPages: number) => {
            return visiblePages.filter((page: any) => page <= pageCount);
        },
        [pageCount]
    );

    /**
     * handle visible pages
     */
    const getVisiblePages = useCallback(
        (page: number | null, total: number) => {
            if (total < 7) {
                return filterPages([1, 2, 3, 4, 5, 6], total);
            } else {
                if (page! % 5 >= 0 && page! > 4 && page! + 2 < total) {
                    return [1, page! - 1, page!, page! + 1, total];
                } else if (page! % 5 >= 0 && page! > 4 && page! + 2 >= total) {
                    return [1, total - 3, total - 2, total - 1, total];
                } else {
                    return [1, 2, 3, 4, 5, total];
                }
            }
        },
        [filterPages]
    );

    const changePage = (page: number) => {
        const activePage = pageIndex + 1;

        if (page === activePage) {
            return;
        }

        const visiblePages = getVisiblePages(page, pageCount);
        setVisiblePages(filterPages(visiblePages, pageCount));
        pageProps?.handlerPagination(page);
    };

    useEffect(() => {
        const visiblePages = getVisiblePages(null, pageCount);
        setVisiblePages(visiblePages);
    }, [pageCount, getVisiblePages]);

    const [visiblePages, setVisiblePages] = useState<number[]>(getVisiblePages(null, pageCount));

    const activePage: number = pageIndex + 1;

    return (
        <>
            <div className="d-lg-flex align-items-center text-center pb-1">
                <ul className="pagination pagination-rounded d-inline-flex m-auto align-item-center mb-0">
                    {pageProps?.page !== 1 && (
                        <li
                            key="prevpage"
                            className={classNames(activePage !== 1 && 'active', {
                                disabled: activePage === 1,
                            })}
                            onClick={() => {
                                if (activePage === 1) return;
                                changePage(activePage - 1);
                            }}
                        >
                            <Link to="#" className="page-link">
                                〈
                            </Link>
                        </li>
                    )}
                    {(visiblePages || []).map((page, index, array) => {
                        return array[index - 1] + 1 < page ? (
                            <React.Fragment key={page}>
                                <li className="page-item disabled d-none d-xl-inline-block">
                                    <Link to="#" className="page-link">
                                        ...
                                    </Link>
                                </li>
                                <li
                                    className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                                        active: activePage === page,
                                    })}
                                    onClick={(e: any) => changePage(page)}
                                >
                                    <Link to="#" className="page-link">
                                        {page}
                                    </Link>
                                </li>
                            </React.Fragment>
                        ) : (
                            <li
                                key={page}
                                className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                                    active: activePage === page,
                                })}
                                onClick={(e: any) => changePage(page)}
                            >
                                <Link to="#" className="page-link">
                                    {page}
                                </Link>
                            </li>
                        );
                    })}
                    {pageProps?.totalPage > pageProps?.page && (
                        <li
                            key="nextpage"
                            className={classNames(activePage !== pageCount && 'active', {
                                disabled: activePage === pageCount,
                            })}
                            onClick={() => {
                                if (activePage === pageCount) return;
                                changePage(activePage + 1);
                            }}
                        >
                            <Link to="#" className="page-link">
                                〉
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
};

export default Pagination;
