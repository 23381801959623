import { getBoardDetail, getBoardList, getPressDetail, getQnaList } from "../helpers/api/boards";
import { getEmploymentDetail, getEmploymentList } from "../helpers/api/employment";

import { useQuery } from "react-query";

interface useFetchBoardsListParam {
  page: number;
  pageSize: number;
  jobType?: string;
}

interface useFetchBoardReturn {
  errorCode: string | null;
  message: string | null;
  data: any;
  status: string | null;
  pageInfo?: any;
}

interface useFetchPressReturn {
  errorCode: string | null;
  message: string | null;
  data: any;
  status: string | null;
  next?: { title?: string; boardId?: string };
  pre?: { title?: string; boardId?: string };
}

export const useFetchEmployments = ({
  page,
  pageSize,
  jobType,
}: useFetchBoardsListParam) => {
  const { data, isLoading, refetch } = useQuery<useFetchBoardReturn>(
    ["getEmploymentList", { page, pageSize, jobType }],
    getEmploymentList,
    {
      refetchOnWindowFocus: false,
      enabled: !!page,
      onError: () => {
        console.error("통신에러");
      },
    }
  );

  let items, status, pageConfig;
  if (data?.status === "OK") {
    items = data.data?.jobPostings;
    status = data?.status;
    let totalPage = 0;
    if (data?.data?.totalCount / data?.data?.length > 1) {
      totalPage = Number.isInteger(data?.data?.totalCount / data?.data?.length)
        ? data?.data?.totalCount / data?.data?.length
        : Math.floor(data?.data?.totalCount / data?.data?.length) + 1;
    }
    pageConfig = {
      totalLength: data?.data?.totalCount,
      listLength: totalPage,
      totalCount: data?.data?.totalCount,
    };
  } else {
    items = [];
    status = data?.status;
    pageConfig = null;
  }
  console.log(items);

  return {
    status,
    items,
    pageConfig,
    isLoading,
    refetch,
  };
}; 


export const useFetchEmploymentsDetail = (id: string) => {
  const { data, isLoading } = useQuery<useFetchBoardReturn>(
    ["getEmploymentDetail", { id }],
    getEmploymentDetail,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        console.error("통신에러");
      },
    }
  );

  let item, status;
  if (data?.status === "OK") {
    item = data.data;
    status = data?.status;
  } else {
    item = {};
    status = data?.status;
  }
  return {
    status,
    item,
    isLoading,
  };
}; 
export const useFetchPressDetail = (id: string) => {
  const { data, isLoading } = useQuery<useFetchPressReturn>(
    ["getPressDetail", { id }],
    getPressDetail,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        console.error("통신에러");
      },
    }
  );

  let item, status, next, pre;
  if (data?.status === "OK") {
    item = data.data;
    status = data?.status;
    next = data?.next;
    pre = data?.pre;
  } else {
    item = {};
    status = data?.status;
    next = null;
    pre = null;
  }
  return {
    status,
    item,
    isLoading,
    next,
    pre
  };
}; 



export const useFetchQna = () => {
  const { data, isLoading, refetch } = useQuery<useFetchBoardReturn>(
    ["getQnaList", {}],
    getQnaList,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        console.error("통신에러");
      },
    }
  );
  let items, status;
  if (data?.status === "OK") {
    items = data.data;
    // if (items.length > 0) items = items.map((item: any) => item = {...item, active: false});
    status = data?.status;
  } else {
    items = [];
    status = data?.status;
  }
  return {
    status,
    items,
    isLoading,
    refetch,
  };
}; 
