

export const verifyEmail = (email: string) => {

  const regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  // 검증에 사용할 정규식 변수 regExp에 저장

  
  
  if (email.match(regExp) != null) {
    return true;
  } else {
    return false;
  }
};

export const verifyPassword = (password: string) => {

  const regExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  
  if (password.match(regExp) != null) {
    return true;
  } else {
    return false;
  }
};

export const verifyPhone = (phone: string) => {

	var regExp = /(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;
    console.log(regExp.test(phone));
    
  if (regExp.test(phone)) {
    return true;
  } else {
    return false;
  }
};