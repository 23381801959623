// import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import { configureStore } from './redux/store';
// import { createRoot } from 'react-dom/client';





// ReactDOM.render(
//     <Provider store={configureStore({})}>
        
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();




import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <Provider store={configureStore({})}>
        <App />
    </Provider>
);
reportWebVitals();



// function AppWithCallbackAfterRender() {
//   useEffect(() => {
//     console.log('rendered');
//   });

//   return (
//         <Provider store={configureStore({})}>
//             <App />
//         </Provider>
//     )
// }

// const container = document.getElementById('app');
// const root = createRoot(container!);
// root.render(<AppWithCallbackAfterRender />);
