import { Box, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { TBoard, useBoardYearsQuery, useBoardsQuery } from '../../generated/graphql';

import CardItem from '../CardItem';
import HoverTop from '../../assets/images/news/hoverTop.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import NewsSelectChecked from '../../assets/images/news/newsSelectChecked.svg';
import NewsSort from '../../assets/images/news/newsSort.svg';
import PaginationNews from '../PagingNews/Paging';
import PressCheck from '../../assets/images/news/pressCheck.svg';
import { isMobile } from 'react-device-detect';

const PressSection = () => {
    const [items, setItems] = useState<any>([]);

    const [hasMore, setHasMore] = useState(true);

    const [sort, setSort] = useState<string>('');

    const setSortHandlar = (sortType: '' | 'COUNT') => {
        setHasMore(true);
        setSort(sortType);
        setPageInfo({ ...pageInfo, page: 1 });
    };

    const content1Ref = useRef<HTMLDivElement>(null);
    const scrollToDiv = () => {
        content1Ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const [pageInfo, setPageInfo] = useState<any>({
        page: 1,
        handlerPagination: (page: number) => {
            setPageInfo({ ...pageInfo, page });
        },
    });

    const { data, loading, refetch } = useBoardsQuery({
        variables: {
            page: pageInfo?.page,
            sortType: sort,
            pageSize: !isMobile ? 9 : 12,
            boardCategory: 'BRT0103',
            boardType: 'BRT01',
        },
    });

    const fetchMoreData = async () => {
        if (loading || !hasMore) return;
        let pageSize = 0;
        let totalList = 0;
        if (data?.boards?.pageInfo?.totalLength) {
            totalList = data?.boards?.pageInfo?.totalLength;
        }
        if (data?.boards?.pageInfo?.listLength) {
            pageSize = data?.boards?.pageInfo?.listLength;
        }

        if (data?.boards?.pageInfo?.curPage === Math.ceil(totalList / pageSize)) {
            setHasMore(false);
            return;
        }
        try {
            // fetchMore를 사용하여 새로운 데이터를 불러오는 로직을 작성
            await refetch({
                page: (data?.boards?.pageInfo?.curPage || 0) + 1,
                boardCategory: 'BRT0103',
                sortType: sort || '',
            });
        } catch (error) {
            console.error('Error fetching more data:', error);
        }
        // 새로운 데이터를 불러오는 로직을 작성

        // setPageProps({
        //     ...pageProps,
        //     page: pageProps?.page + 1,
        //     pageSize: (pageProps?.page + 1) * pageProps?.pageSize,
        // });
    };

    useEffect(() => {
        if (isMobile) {
            if (data?.boards?.boards && data?.boards?.boards?.length > 0) {
                if (data?.boards?.pageInfo?.curPage === 1) {
                    setItems([...data.boards.boards]);
                } else {
                    if (items?.length > 0) {
                        setItems([...items, ...data.boards.boards]);
                    } else {
                        setItems([...data.boards.boards]);
                    }
                }
            } else {
                setItems([]);
            }
        }
    }, [data]);

    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop || 0;
        const divStartPosition = scrollHeight - 1811 - 450;

        if (scrollHeight - scrollPosition <= windowHeight + 450) {
            setShowButton(false); // 페이지 하단에 도달하면 버튼 감춤
        } else if (scrollPosition <= divStartPosition) {
            setShowButton(false); // 페이지 하단에 도달하면 버튼 감춤
        } else {
            setShowButton(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="newsList-custom" style={{ padding: 0 }}>
                <Flex
                    mb={'20px'}
                    justifyContent={'end'}
                    alignItems={'center'}
                    w={'100%'}
                    display={['none', 'none', 'flex']}
                >
                    <Flex alignItems={'center'}>
                        <Flex
                            ml={'24px'}
                            cursor={sort !== '' ? 'pointer' : ''}
                            onClick={() => {
                                if (sort !== '') {
                                    setSortHandlar('');
                                }
                            }}
                        >
                            <Image src={PressCheck} mr={'9.5px'} w={'12px'} opacity={sort === '' ? 1 : 0.2} />
                            <Text
                                letterSpacing="-0.03em"
                                fontSize={'18px'}
                                lineHeight={'27px'}
                                fontWeight={500}
                                opacity={sort === '' ? 1 : 0.2}
                            >
                                최신 순
                            </Text>
                        </Flex>
                        <Flex
                            ml={'24px'}
                            cursor={sort !== 'COUNT' ? 'pointer' : ''}
                            onClick={() => {
                                if (sort !== 'COUNT') {
                                    setSortHandlar('COUNT');
                                }
                            }}
                        >
                            <Image src={PressCheck} mr={'9.5px'} w={'12px'} opacity={sort === 'COUNT' ? 1 : 0.2} />
                            <Text
                                letterSpacing="-0.03em"
                                fontSize={'18px'}
                                lineHeight={'27px'}
                                fontWeight={500}
                                opacity={sort === 'COUNT' ? 1 : 0.2}
                                onClick={() => {
                                    if (sort !== 'COUNT') {
                                        setSortHandlar('COUNT');
                                    }
                                }}
                            >
                                조회수 순
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    mb={'20px'}
                    justifyContent={'start'}
                    alignItems={'center'}
                    w={'100%'}
                    display={['flex', 'flex', 'none']}
                >
                    <Menu>
                        <MenuButton
                            w={sort === 'COUNT' ? '120px' : '93px'}
                            h={'40px'}
                            ml={'16px'}
                            borderRadius={'44px'}
                            p={'0px, 16px'}
                            backgroundColor={'#ffffff'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            border={'1.5px solid #131313'}
                            cursor={'pointer'}
                            // _expanded={{ bg: 'blue.400' }}
                            // _focus={{ boxShadow: 'outline' }}
                        >
                            <Flex justifyContent={'space-around'} p={'0px, 16px'}>
                                <Image src={NewsSort || ''} pl={'16px'} />
                                <Text
                                    fontSize={'16px'}
                                    fontWeight={'500'}
                                    lineHeight={'24px'}
                                    letterSpacing="-0.03em"
                                    color={'#131313'}
                                    pr={'16px'}
                                >
                                    {sort === '' ? '최신순' : '조회수순'}
                                </Text>
                            </Flex>
                        </MenuButton>
                        <MenuList w={'170px'} py={0}>
                            <MenuItem
                                value={''}
                                h={'44.5px'}
                                onClick={() => setSortHandlar('')}
                                // _hover={{ bg: 'gray.600' }} // 마우스 호버 시 배경색 변경
                            >
                                <Flex>
                                    {sort === '' && (
                                        <Image
                                            src={NewsSelectChecked}
                                            // w={'24px'}
                                            // h={'44px'}
                                        />
                                    )}

                                    <Text ml={sort !== '' ? '24px' : '8px'}>최신순</Text>
                                </Flex>
                            </MenuItem>
                            <MenuItem
                                value={''}
                                h={'44.5px'}
                                onClick={() => setSortHandlar('COUNT')}
                                // _hover={{ bg: 'gray.600' }} // 마우스 호버 시 배경색 변경
                            >
                                <Flex>
                                    {sort === 'COUNT' && (
                                        <Image
                                            src={NewsSelectChecked}
                                            // w={'24px'}
                                            // h={'44px'}
                                        />
                                    )}

                                    <Text ml={sort !== 'COUNT' ? '24px' : '8px'}>조회수순</Text>
                                </Flex>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>

                {isMobile && (
                    <Box className="newsList-custom-box" w={'100%'}>
                        <Flex overflow={'auto'} id={'listDiv'} h={['', '', '1211px']} w={'100%'}>
                            <Flex w={'100%'} display={'inline-block'}>
                                <Flex w={'100%'} flexDir={'column'} ref={content1Ref}>
                                    {data?.boards?.boards && data?.boards?.boards?.length > 0 && (
                                        <InfiniteScroll
                                            dataLength={items?.length}
                                            next={fetchMoreData}
                                            hasMore={!loading} // loading 중에는 추가 데이터 요청을 막음
                                            loader={''}
                                            style={{
                                                justifyContent: 'center',
                                                flexWrap: 'wrap',
                                                width: '100%',

                                                display: 'flex',
                                            }}
                                            // loader={<h4>Loading...</h4>}
                                        >
                                            {items?.map((item: TBoard, index: number) => {
                                                const { board_id, title, created_at, boadr_thumbnail_path } = item;
                                                return (
                                                    <CardItem
                                                        key={board_id}
                                                        type="news"
                                                        item={{
                                                            link: `/newsDetail/${board_id}`,
                                                            title: title || '',
                                                            midTitle: '',
                                                            smallTitle: '',
                                                            createdAt: created_at,
                                                            thumbnail: boadr_thumbnail_path || '',
                                                        }}
                                                    />
                                                );
                                            })}
                                        </InfiniteScroll>
                                    )}
                                </Flex>
                                {/* {displayButton && ( */}
                                {/* )} */}
                            </Flex>
                            <Flex
                                position={'fixed'}
                                right={['20px', '20px', '50px', '50px', '50px', '200px']}
                                justifyContent={'end'}
                                p={'15px'}
                                bottom={'20px'}
                                // display={['none', 'none', 'block']}
                                display={['none', showButton ? 'block' : 'none', showButton ? 'block' : 'none']}
                            >
                                <Flex
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    // position={'fixed'}
                                    w={'48px'}
                                    h={'48px'}
                                    borderRadius={'48px'}
                                    bottom={'-100px'}
                                    backgroundColor={'#FFFFFF'}
                                    opacity={0.5}
                                    _hover={{
                                        opacity: 1,
                                    }}
                                    boxShadow={'0px 4px 8px 0px #00000029'}
                                    cursor={'pointer'}
                                    right={0}
                                    onClick={scrollToDiv}
                                >
                                    <Image src={HoverTop} h={'24px'} />
                                </Flex>
                            </Flex>
                            <Flex
                                position={'fixed'}
                                right={['20px', '20px', '50px', '50px', '50px', '200px']}
                                justifyContent={'end'}
                                p={'15px'}
                                bottom={'20px'}
                                // display={showButton ? 'block' : 'none'}
                                display={['block', 'block', 'none']}
                            >
                                <Flex
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    // position={'fixed'}
                                    w={'48px'}
                                    h={'48px'}
                                    borderRadius={'48px'}
                                    bottom={'-100px'}
                                    backgroundColor={'#FFFFFF'}
                                    opacity={0.5}
                                    _hover={{
                                        opacity: 1,
                                    }}
                                    boxShadow={'0px 4px 8px 0px #00000029'}
                                    cursor={'pointer'}
                                    right={0}
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }}
                                >
                                    <Image src={HoverTop} h={'24px'} />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Box>
                )}
                {!isMobile && (
                    <Box className="newsList-custom-box" w={'100%'}>
                        <Flex w={'100%'}>
                            <Flex w={'100%'} display={'inline-block'}>
                                <Flex w={'100%'} flexWrap={'wrap'} justifyContent={'center'}>
                                    {data?.boards?.boards?.map((item: TBoard, index: number) => {
                                        const { board_id, title, created_at, boadr_thumbnail_path } = item;
                                        return (
                                            <CardItem
                                                key={board_id}
                                                type="news"
                                                item={{
                                                    link: `/newsDetail/${board_id}`,
                                                    title: title || '',
                                                    midTitle: '',
                                                    smallTitle: '',
                                                    createdAt: created_at,
                                                    thumbnail: boadr_thumbnail_path || '',
                                                }}
                                            />
                                        );
                                    })}
                                </Flex>
                                {/* {displayButton && ( */}
                                {/* )} */}
                            </Flex>
                        </Flex>
                        <Flex mt={'2rem'} w={'100%'}>
                            {data?.boards.pageInfo?.totalLength &&
                                Math.ceil(data?.boards.pageInfo?.totalLength / 9) > 1 && (
                                    <PaginationNews
                                        page={pageInfo?.page}
                                        pageSize={9}
                                        totalPage={Math.ceil(data?.boards.pageInfo?.totalLength / 9)}
                                        handlerPagination={pageInfo?.handlerPagination}
                                    />
                                )}
                        </Flex>
                    </Box>
                )}
            </div>
        </>
    );
};

export default React.memo(PressSection);
