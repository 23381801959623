import React, { Suspense, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useRedux } from '../../hooks';
import Topbar from '../Topbar';
import Footer from './Footer';


const loading = () => <div className="text-center"></div>;

interface VerticalLayoutProps {
    children?: any;
}
const RightSidebar = React.lazy(() => import('./RightSidebar'));
const MainLayout = ({ children }: VerticalLayoutProps) => {
    const { dispatch, appSelector } = useRedux();
    const {
        isOpenRightSideBar
    } = appSelector((state) => ({
        isOpenRightSideBar: state.Common.isOpenRightSideBar,
    }));
    return (
        <>
            <div id="wrapper" className="wrap" >
                <Suspense fallback={loading()}>
                    <Topbar  topbarDark={true} />
                </Suspense>

                <div className="content-page">
                    <div className="content">
                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>
            </div>
            {isOpenRightSideBar && (
                <Suspense fallback={loading()}>
                    <RightSidebar />
                </Suspense>
            )}
        </>
    );
};

export default MainLayout;
