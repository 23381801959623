

import { useQuery } from "react-query";
import { getBoardDetail, getBoardList, getPressDetail, getQnaList } from "../helpers/api/boards";


interface useFetchBoardsListParam {
  page: number;
  pageSize: number;
  boardType: string;
  title?: string;
  boardCategory?: string;
}

interface useFetchBoardReturn {
  errorCode: string | null;
  message: string | null;
  data: any;
  status: string | null;
  pageInfo?: any;
}

interface useFetchPressReturn {
  errorCode: string | null;
  message: string | null;
  data: any;
  status: string | null;
  next?: { title?: string; boardId?: string };
  pre?: { title?: string; boardId?: string };
}

export const useFetchBoards = ({
  page,
  pageSize,
  boardType,
  title,
  boardCategory,
}: useFetchBoardsListParam) => {
  const { data, isLoading, refetch } = useQuery<useFetchBoardReturn>(
    ["getBoardList", { page, pageSize, boardType, title, boardCategory }],
    getBoardList,
    {
      refetchOnWindowFocus: false,
      enabled: !!page,
      onError: () => {
        console.error("통신에러");
      },
    }
  );
  let items, status, pageConfig;
  
  
  if (data?.status === "OK") {
    console.log({ data });
    items = data.data?.boards;
    status = data?.status;
    let totalPage = 0;
    if (data?.data?.totalCount / data?.data?.length > 1) {
      totalPage = Number.isInteger(data?.data?.totalCount / data?.data?.length)
        ? data?.data?.totalCount / data?.data?.length
        : Math.floor(data?.data?.totalCount / data?.data?.length) + 1;
    }
    pageConfig = {
      totalLength: data?.data?.totalCount,
      listLength: totalPage,
    };
  } else {
    items = [];
    status = data?.status;
    pageConfig = null;
  }
  
  return {
    status,
    items,
    pageConfig,
    isLoading,
    refetch,
  };
}; 


export const useFetchBoardDetail = (id: string) => {
  const { data, isLoading } = useQuery<useFetchBoardReturn>(
    ["getBoardDetail", { id }],
    getBoardDetail,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        console.error("통신에러");
      },
    }
  );
    
  let item, status;
  if (data?.status === "OK") {
    item = data.data;
    status = data?.status;
  } else {
    item = {};
    status = data?.status;
  }
  return {
    status,
    item,
    isLoading,
  };
}; 
export const useFetchPressDetail = (id: string) => {
  const { data, isLoading } = useQuery<useFetchPressReturn>(
    ["getPressDetail", { id }],
    getPressDetail,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        console.error("통신에러");
      },
    }
  );

  let item, status, next, pre;
  if (data?.status === "OK") {
    item = data.data;
    status = data?.status;
    next = data?.next;
    pre = data?.pre;
  } else {
    item = {};
    status = data?.status;
    next = null;
    pre = null;
  }
  return {
    status,
    item,
    isLoading,
    next,
    pre
  };
}; 



export const useFetchQna = () => {
  const { data, isLoading, refetch } = useQuery<useFetchBoardReturn>(
    ["getQnaList", {}],
    getQnaList,
    {
      refetchOnWindowFocus: false,
      onError: () => {
        console.error("통신에러");
      },
    }
  );
  let items, status;
  if (data?.status === "OK") {
    items = data.data;
    // if (items.length > 0) items = items.map((item: any) => item = {...item, active: false});
    status = data?.status;
  } else {
    items = [];
    status = data?.status;
  }
  return {
    status,
    items,
    isLoading,
    refetch,
  };
}; 
