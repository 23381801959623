import 'react-toastify/dist/ReactToastify.css';

import { BoardFiles, BoardRelation, useBoardQuery, useRelatedMediaNewsQuery } from '../../generated/graphql';
import { Box, ChakraProvider, Flex, Image, Text } from '@chakra-ui/react';
import Pagination, { PaginationProps } from '../../components/Paging/Paging';
import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import IconCall from '../../assets/images/common/icon-call.svg';
import IconDownload from '../../assets/images/common/icon-download.svg';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { getBoardDetail } from '../../helpers/api/boards';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { useFetchPressDetail } from '../../hooks/useFetchBoards';

const NewsDetail = ({ match }: any) => {
    const { slug } = match.params;
    const moveToPage = (pageType: string, id: string) => {};
    const { data } = useRelatedMediaNewsQuery({
        variables: {
            boardId: slug,
        },
    });
    const { data: boardInfo } = useBoardQuery({
        variables: {
            boardId: slug,
        },
    });

    const handleCopyClipBoard = async () => {
        try {
            // await navigator.clipboard.writeText('https://piece.run/magazine/' + detail.magazineId);
            await navigator.clipboard.writeText('https://piece.run/magazine/');
            // alert('링크를 클립보드에 복사했습니다.');
            // toast.error('링크를 클립보드에 복사했습니다.', {
            //   autoClose: 3000,
            //   position: toast.POSITION.TOP_RIGHT
            // });
            if (isMobile) {
                toast.success('링크를 클립보드에 복사했습니다.', {
                    style: {
                        backgroundColor: '#262626',
                        color: '#ffffff',
                    },
                    autoClose: 1000,
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    theme: 'colored',
                });
            } else {
                toast.success('링크를 클립보드에 복사했습니다.', {
                    style: {
                        backgroundColor: '#262626',
                        color: '#ffffff',
                    },
                    autoClose: 1000,
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    theme: 'colored',
                });
            }
        } catch (error) {
            if (isMobile) {
                toast.error('링크를 복사 하는 도중 오류가 발생하였습니다.', {
                    autoClose: 1000,
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    theme: 'colored',
                });
            } else {
                toast.error('링크를 복사 하는 도중 오류가 발생하였습니다.', {
                    autoClose: 1000,
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    theme: 'colored',
                });
            }
        }
    };
    const download = (path: string, name: string) => {
        saveAs(path, name);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToScroll: 1, // 한 번에 스크롤될 슬라이드 개수
        autoplay: true,
        autoplaySpeed: 2000, // 자동으로 넘어가는 간격 (ms)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    // slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    // slidesToScroll: 2,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 10,
                    // slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <div className="sub">
                <div className="sub-title">PRESS</div>
                <div className="sub-content bbs-view">
                    <div className="sub-content-cont">
                        {/* <!-- 뷰페이지 상단 출력 --> */}
                        <div className="bbs-view-header">
                            <div className="title">{boardInfo?.board?.board?.title || ''}</div>
                            <div className="date">
                                {moment(boardInfo?.board?.board?.created_at || '').format('YYYY.MM.DD')}
                            </div>
                            <div className="btns">
                                <div className="btn-call">
                                    <span>
                                        <img src={IconCall} />
                                    </span>
                                    <span className="call-number">02-6737-8285</span>
                                </div>
                                {/* <style>
								.btn-etc { display: flex; }
								.btn-etc div { width: 38px; height: 38px; cursor: pointer; }
								.btn-etc .link_copy { background-image: url('./img/common/link-icon.svg'); background-repeat: no-repeat; background-size: cover; }
								.btn-etc .link_copy:hover { background-image: url('./img/common/link-icon-hover.svg'); }
								.btn-etc .download { background-image: url('./img/common/share-icon.svg'); background-repeat: no-repeat; background-size: cover; margin-left: 10px;}
								.btn-etc .download:hover { background-image: url('./img/common/share-icon-hover.svg'); }
							</style> */}
                                {boardInfo?.board?.board?.files &&
                                    boardInfo?.board?.board?.files?.length > 0 &&
                                    boardInfo?.board?.board?.files?.map((fileItem: any) => {
                                        return (
                                            <div className="btn-etc" key={fileItem?.file_id}>
                                                {/* <div className="link_copy"></div> */}
                                                <div
                                                    className="download"
                                                    onClick={() =>
                                                        download(fileItem?.cdn_file_path, fileItem?.cdnFileName)
                                                    }
                                                >
                                                    <img src={IconDownload} />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        {/* <!-- 뷰페이지 상단 출력 --> */}
                        {/* <!-- 뷰페이지 내용 출력 --> */}
                        <div className="bbs-view-cont">
                            <div className="bbs-view-cont-img">
                                <img src={boardInfo?.board?.board?.boadr_thumbnail_path || ''} />
                            </div>
                            <div className="bbs-view-cont-text">
                                <div
                                    dangerouslySetInnerHTML={{ __html: boardInfo?.board?.board?.contents || '' }}
                                ></div>
                            </div>
                            <div className="bbs-view-cont-next">
                                {boardInfo?.board?.preBoard?.board_id && (
                                    <Link
                                        to={'/newsDetail/' + boardInfo?.board?.preBoard?.board_id}
                                        className="cursor-pointer"
                                    >
                                        <div className="before cursor-pointer">
                                            <div className="before-icon"></div>
                                            <div className="before-type">이전글</div>
                                            <div className="before-text">
                                                {boardInfo?.board?.preBoard?.title || '-'}
                                            </div>
                                        </div>
                                    </Link>
                                )}
                                {boardInfo?.board?.nextBoard?.board_id && (
                                    <Link
                                        to={'/newsDetail/' + boardInfo?.board?.nextBoard?.board_id}
                                        className="cursor-pointer"
                                    >
                                        <div className="next cursor-pointer">
                                            <div className="next-icon"></div>
                                            <div className="next-type">다음글</div>
                                            <div className="next-text">{boardInfo?.board?.nextBoard?.title || '-'}</div>
                                        </div>
                                    </Link>
                                )}

                                {/* <a onClick={() => {moveToPage('NEXT', item?.nextId)}} className="cursor-pointer">
							<div className="next cursor-pointer">
								<div className="next-icon"></div>
								<div className="next-type">다음글</div>
								<div className="next-text">
									{
										item?.nextTitle || '-' 
									}
								</div>
							</div>
							</a> */}
                            </div>
                        </div>
                        {/* <!-- 뷰페이지 내용 출력 --> */}
                    </div>
                    <div className="bbs-btns">
                        <Link to="/news/PRESS">
                            <a>
                                <span className="bbs-btn">목록</span>
                            </a>
                        </Link>
                    </div>
                </div>
                <ToastContainer />
            </div>
            {data?.relatedMediaNews?.boardRelations && data?.relatedMediaNews?.boardRelations?.length > 0 && (
                <ChakraProvider>
                    <Flex
                        w={'100%'}
                        h={['429px', '768px']}
                        backgroundColor={'#F9F9F9'}
                        flexDir={'column'}
                        justifyContent={'center'}
                        px={['10px', '10px', '20px', '0px']}
                        margin="0 auto"
                        alignItems={'center'}
                    >
                        <Box maxW={'1440px'} w={'100%'}>
                            <Flex w={'100%'}>
                                <Text
                                    fontSize={['35px', '40px']}
                                    lineHeight={['52.5px', '60px']}
                                    fontWeight={700}
                                    letterSpacing="-0.03em"
                                    color={'#131313'}
                                    m={0}
                                    mb={'34px'}
                                >
                                    연관 미디어 뉴스
                                </Text>
                            </Flex>
                        </Box>
                        <Box overflowX="auto" w={'100%'} whiteSpace="nowrap" maxW={'1440px'}>
                            <Flex className="sub-list">
                                {/* <Flex
                                w={['160px', '448px']}
                                flexDir={'column'}
                                cursor={'pointer'}
                                _hover={{
                                    '> img': {
                                        transform: 'scale(1.05)', // 확대 비율 조절
                                    },
                                }}
                            >
                                <Image
                                    src={'https://placekitten.com/800/400'}
                                    w={['160px', '448px']}
                                    h={['100px', '280px']}
                                    borderRadius={'10px'}
                                />
                                <Flex flexDir={'column'} whiteSpace="normal" w={['160px', '448px']}>
                                    <Text
                                        fontSize={['16px', '24px']}
                                        lineHeight={['24px', '36px']}
                                        fontWeight={500}
                                        letterSpacing="-0.03em"
                                        color={'#131313'}
                                        m={0}
                                        mt={['16px', '24px']}
                                    >
                                        '조각투자' 바이셀스탠다드, 싱가포르 진출…"STO 발행"
                                    </Text>
                                    <Text
                                        fontSize={['13px', '16px']}
                                        lineHeight={['19.5px', '24px']}
                                        fontWeight={500}
                                        letterSpacing="-0.03em"
                                        color={'#8C919F'}
                                        m={0}
                                        mt={['16px', '24px']}
                                    >
                                        뉴시스 ∙ 2023. 12
                                    </Text>
                                </Flex>
                            </Flex> */}
                                {data?.relatedMediaNews?.boardRelations?.map((item: any, index: number) => {
                                    const { board } = item;
                                    return (
                                        <Flex
                                            key={board?.board_id}
                                            w={['160px', '448px']}
                                            flexDir={'column'}
                                            cursor={'pointer'}
                                            ml={index !== 0 ? ['12px', '48px'] : 0}
                                            _hover={{
                                                '> img': {
                                                    transform: 'scale(1.05)', // 확대 비율 조절
                                                },
                                            }}
                                        >
                                            <Image
                                                src={board?.boadr_thumbnail_path || ''}
                                                w={['160px', '448px']}
                                                h={['100px', '280px']}
                                                borderRadius={'10px'}
                                            />
                                            <Flex flexDir={'column'} whiteSpace="normal" w={['160px', '448px']}>
                                                <Text
                                                    fontSize={['16px', '24px']}
                                                    lineHeight={['24px', '36px']}
                                                    fontWeight={500}
                                                    letterSpacing="-0.03em"
                                                    color={'#131313'}
                                                    m={0}
                                                    mt={['16px', '24px']}
                                                >
                                                    {board?.title || '-'}
                                                </Text>
                                                <Text
                                                    fontSize={['13px', '16px']}
                                                    lineHeight={['19.5px', '24px']}
                                                    fontWeight={500}
                                                    letterSpacing="-0.03em"
                                                    color={'#8C919F'}
                                                    m={0}
                                                    mt={['16px', '24px']}
                                                >
                                                    {board?.press || '-'} ∙{' '}
                                                    {moment(board?.created_at).format('YYYY. MM')}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    );
                                })}
                            </Flex>
                        </Box>
                    </Flex>
                </ChakraProvider>
            )}
        </>
    );
};

export default NewsDetail;
