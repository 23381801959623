import { Flex, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';

export interface PaginationProps {
    page: number;
    pageSize: number;
    totalPage: number;
    totalCount?: number;
    totalLength?: number;
    sort?: string;
    handlerPagination: (page: number) => void;
}

const PaginationNews = (pageProps: PaginationProps) => {
    const [pageCount, setPageCount] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState<number>(0);
    // const [pageCount, setPageCount] = useState<number>(pageProps?.totalPage!);
    // const [pageIndex, setPageIndex] = useState<number>(pageProps?.page! - 1);
    useEffect(() => {
        setPageCount(pageProps?.totalPage!);
        setPageIndex(pageProps?.page! - 1);
    }, [pageProps]);

    const filterPages = useCallback(
        async (visiblePages: any, totalPages: number) => {
            return await visiblePages.filter((page: any) => page <= pageCount);
        },
        [pageCount]
    );

    /**
     * handle visible pages
     */
    const getVisiblePages = useCallback(
        (page: number | null, total: number) => {
            if (total < 7) {
                return filterPages([1, 2, 3, 4, 5, 6], total);
            } else {
                if (page! % 5 >= 0 && page! > 4 && page! + 2 < total) {
                    return [1, page! - 1, page!, page! + 1, total];
                } else if (page! % 5 >= 0 && page! > 4 && page! + 2 >= total) {
                    return [1, total - 3, total - 2, total - 1, total];
                } else {
                    return [1, 2, 3, 4, 5, total];
                }
            }
        },
        [filterPages]
    );

    const changePage = async (page: number) => {
        const activePage = pageIndex + 1;

        if (page === activePage) {
            return;
        }

        const visiblePages = await getVisiblePages(page, pageCount);
        setVisiblePages(await filterPages(visiblePages, pageCount));
        pageProps?.handlerPagination(page);
    };

    useEffect(() => {
        visiblePagesSetting();
    }, [pageCount, getVisiblePages]);

    const visiblePagesSetting = async () => {
        const visiblePages = await getVisiblePages(pageProps?.page, pageCount);
        setVisiblePages(visiblePages);
    };

    // const [visiblePages, setVisiblePages] = useState<number[]>(getVisiblePages(null, pageCount));
    const [visiblePages, setVisiblePages] = useState<number[]>([]);

    const activePage: number = pageIndex + 1;

    return (
        <>
            <Flex flexDir={'column'} w={'100%'}>
                <Flex justifyContent={'center'}>
                    {pageProps?.page !== 1 && (
                        <Flex
                            key="prevpage"
                            w={'40px'}
                            h={'40px'}
                            cursor={'pointer'}
                            _hover={{
                                color: '#F2F3F4',
                            }}
                            // backgroundColor={'#F2F3F4'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={'4px'}
                            onClick={() => {
                                if (activePage === 1) return;
                                changePage(activePage - 1);
                            }}
                        >
                            〈
                        </Flex>
                    )}
                    {(visiblePages || []).map((page, index, array) => {
                        return array[index - 1] + 1 < page ? (
                            <Flex key={page}>
                                {/* <Box className="page-item disabled d-none d-xl-inline-block">
                                    <Link to="#" className="page-link">
                                        ...
                                    </Link>
                                </Box> */}
                                <Flex
                                    w={'40px'}
                                    h={'40px'}
                                    _hover={{
                                        color: '#F2F3F4',
                                    }}
                                    // backgroundColor={'#F2F3F4'}
                                    justifyContent={'center'}
                                    cursor={'pointer'}
                                    color={'#8C919F'}
                                    alignItems={'center'}
                                    borderRadius={'4px'}
                                    onClick={(e: any) => changePage(page)}
                                >
                                    <Text fontWeight={500} fontSize={'20px'} lineHeight={'30px'}>
                                        ...
                                    </Text>
                                </Flex>
                                <Flex
                                    w={'40px'}
                                    h={'40px'}
                                    cursor={'pointer'}
                                    _hover={{
                                        color: '#F2F3F4',
                                    }}
                                    backgroundColor={page === activePage ? '#F2F3F4' : ''}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    borderRadius={'4px'}
                                    color={page === activePage ? '#131313' : '#8C919F'}
                                    onClick={(e: any) => changePage(page)}
                                >
                                    <Text fontWeight={500} fontSize={'20px'} lineHeight={'30px'}>
                                        {page}
                                    </Text>
                                </Flex>
                            </Flex>
                        ) : (
                            <Flex
                                key={page}
                                w={'40px'}
                                h={'40px'}
                                cursor={'pointer'}
                                _hover={{
                                    color: '#F2F3F4',
                                }}
                                backgroundColor={page === activePage ? '#F2F3F4' : ''}
                                justifyContent={'center'}
                                color={page === activePage ? '#131313' : '#8C919F'}
                                alignItems={'center'}
                                borderRadius={'4px'}
                                onClick={(e: any) => changePage(page)}
                            >
                                <Text fontWeight={500} fontSize={'20px'} lineHeight={'30px'}>
                                    {page}
                                </Text>
                            </Flex>
                        );
                    })}
                    {pageProps?.totalPage > pageProps?.page && (
                        // <li
                        //     key="nextpage"
                        //     className={classNames(activePage !== pageCount && 'active', {
                        //         disabled: activePage === pageCount,
                        //     })}
                        //     onClick={() => {
                        //         if (activePage === pageCount) return;
                        //         changePage(activePage + 1);
                        //     }}
                        // >
                        //     <Link to="#" className="page-link">
                        //         〉
                        //     </Link>
                        // </li>
                        <Flex
                            w={'40px'}
                            h={'40px'}
                            cursor={'pointer'}
                            _hover={{
                                color: '#F2F3F4',
                            }}
                            // backgroundColor={'#F2F3F4'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={'4px'}
                            onClick={() => {
                                if (activePage === pageCount) return;
                                changePage(activePage + 1);
                            }}
                        >
                            <Text fontWeight={500} fontSize={'20px'} lineHeight={'30px'}>
                                〉
                            </Text>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </>
    );
};

export default PaginationNews;
