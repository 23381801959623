import { Box, Flex, Image, Text } from '@chakra-ui/react';

import DefaultThumbnail from '../../assets/images/news/default_thumbnail2.png';
import React from 'react';
import { TBoard } from '../../generated/graphql';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

interface NewsCardItemProps {
    item: TBoard;
}

const NewsCardItem = ({ item }: NewsCardItemProps) => {
    return (
        <>
            <Flex
                minH={['100px', '100px', '173px', '173px']}
                w={'100%'}
                cursor={'pointer'}
                py={'24px'}
                borderRadius={'16px'}
                // _hover={{
                //     backgroundColor: '#F2F3F4',
                //     paddingLeft: '24px',
                //     paddingRight: '24px',
                // }}
            >
                <Box
                    position="relative"
                    overflow={'hidden'}
                    w={['120px', '120px', '200px', '200px']}
                    minW={['120px', '120px', '200px', '200px']}
                    h={['75px', '75px', '125px', '125px']}
                    borderRadius={'10px'}
                >
                    <Image
                        src={item?.boadr_thumbnail_path || DefaultThumbnail}
                        backgroundColor={'gray'}
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        minWidth="100%"
                        minHeight="100%"
                        objectFit={'cover'}
                        // border={'1px solid #666666'}
                    />
                </Box>

                <Flex flexDir={'column'} pl={'32px'}>
                    <Text
                        fontSize={['13px', '13px', '16px', '16px']}
                        lineHeight={['19.5px', '19.5px', '24px', '24px']}
                        color={'#8C919F'}
                        fontWeight={500}
                        letterSpacing="-0.03em"
                    >
                        {item?.press} ∙ {moment(item?.created_at)?.format('YYYY. MM')}
                    </Text>
                    <Text
                        fontSize={['16px', '16px', '24px', '24px']}
                        lineHeight={['24px', '24px', '36px', '36px']}
                        letterSpacing="-0.03em"
                        fontWeight={500}
                    >
                        {item?.title}
                    </Text>
                </Flex>
            </Flex>
        </>
    );
};

export default React.memo(NewsCardItem);
