import { APICore } from "./apiCore";

const api = new APICore();

const getEmploymentList = async ({ queryKey }: { queryKey: any }) => {
  const [, params] = queryKey;

  const baseUrl = "/service/job";
  let paramsData: any = {
    page: params.page,
    length: params.pageSize || 10,
  };

  if (params?.jobType && params?.jobType !== 'ALL') paramsData.jobType = params.jobType;

  const { data }: { data: any } = await api.get(`${baseUrl}`, {
    params: paramsData,
  });

  return data;
};

//게시물 상세조회
const getEmploymentDetail = async ({ queryKey }: { queryKey: any }) => {
  const [, slug] = queryKey;
  if (!slug?.id) return;

  const baseUrl = `/service/job/${slug?.id}`;

  const { data }: { data: any } = await api.get(`${baseUrl}`, {});

  return data;
};

export { getEmploymentList, getEmploymentDetail };
