
import { CommonActionTypes } from './constants';

const INIT_STATE = {
    isLoadding: false,
    isAlert: false,
    isCommonConfirm: false,
    isOpenRightSideBar: false,
};

interface State {
  isLoadding?: boolean;
  isAlert?: boolean;
  isCommonConfirm?: boolean;
  isOpenRightSideBar?: boolean;
}

interface CommonActionType {
    type:
        | CommonActionTypes.LOADDING
        | CommonActionTypes.ALERT
        | CommonActionTypes.CONFIRM_ALERT
        | CommonActionTypes.SHOW_RIGHT_SIDEBAR
        | CommonActionTypes.HIDE_RIGHT_SIDEBAR;
    payload: {
        data: boolean;
    }
}

const Common = (state: State = INIT_STATE, action: CommonActionType) => {
    switch (action.type) {
        case CommonActionTypes.LOADDING:
            return {
                ...state,
                isLoadding: action.payload
            }
        case CommonActionTypes.CONFIRM_ALERT:
            return {
                ...state,
                isCommonConfirm: action.payload
            }
        case CommonActionTypes.ALERT:
            return {
                ...state,
                isAlert: action.payload
            }
        case CommonActionTypes.SHOW_RIGHT_SIDEBAR:
            return {
                ...state,
                isOpenRightSideBar: true,
            };
        case CommonActionTypes.HIDE_RIGHT_SIDEBAR:
            return {
                ...state,
                isOpenRightSideBar: false,
            };
        
        default: 
        return state 
    }
}

export default Common;