import {
    Box,
    Button,
    ChakraProvider,
    Flex,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

import GIF1 from '../../assets/images/news/image_press_banner_tag.gif';
import SubscribeModalGIF from '../../assets/images/news/SubscribeModal.gif';
import { useCreateReceptionMutation } from '../../generated/graphql';
import { useState } from 'react';
import { verifyEmail } from '../../utills/regularExpression';

const SendNewsLetter = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [verifyEmailYn, setVerifyEmailYn] = useState<boolean>(true);
    const [verifyTermYn, setVerifyTermYn] = useState<boolean>(true);
    const [createReception] = useCreateReceptionMutation();
    const userIdOnChange = async (e: any) => {
        setEmail(e.target.value);
    };
    const { isOpen, onOpen, onClose } = useDisclosure();
    const sendEmailInfoHandlar = async () => {
        let emailCheck = true;
        if (!email) {
            emailCheck = false;
        } else {
            const verifyEmailCheck = await verifyEmail(email);
            if (!verifyEmailCheck) emailCheck = false;
        }

        if (!emailCheck || !isChecked) {
            if (!emailCheck) setVerifyEmailYn(false);
            if (!isChecked) setVerifyTermYn(false);
            setTimeout(() => {
                setVerifyEmailYn(true);
                setVerifyTermYn(true);
            }, 3000);
            return;
        }

        try {
            const response = await createReception({
                variables: { email },
            });
            if (response.data?.createReception?.result === 'FAIL') {
                alert('뉴스레터 구독 신청을 하는 도중 오류가 발생하였습니다.');
                return false;
            } else if (response.data?.createReception?.result === 'SUCCESS') {
                setIsChecked(false);
                onOpen();
            }
        } catch (error) {
            alert('뉴스레터 구독 신청을 하는 도중 오류가 발생하였습니다.');
        }
    };

    return (
        <>
            <Flex mt={'120px'} className="news_letter_web">
                <Flex
                    bg={'#101F40'}
                    w={'100%'}
                    h={'330px'}
                    borderRadius={'10px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    px={'80px'}
                >
                    <Flex>
                        <Flex flexDirection={'column'}>
                            <Text
                                fontSize={'20px'}
                                fontWeight={500}
                                lineHeight={'30px'}
                                letterSpacing="-0.03em"
                                color={'#83A1DC'}
                                m={0}
                            >
                                Buysell Standards Newsletter
                            </Text>
                            <Flex flexDirection={'column'} w={'100%'} mt={'18px'}>
                                <Flex alignContent={'center'}>
                                    <Text
                                        fontSize={'40px'}
                                        fontWeight={700}
                                        lineHeight={'70px'}
                                        letterSpacing="-0.03em"
                                        color={'#FFFFFF'}
                                        m={0}
                                    >
                                        STO 산업
                                    </Text>
                                    <Flex w={'192px'} justifyContent={'center'} alignItems={'center'}>
                                        <Image src={GIF1} h={'64px'} w={'160px'} />
                                    </Flex>
                                    <Text
                                        fontSize={'40px'}
                                        fontWeight={700}
                                        lineHeight={'70px'}
                                        letterSpacing="-0.03em"
                                        color={'#FFFFFF'}
                                        m={0}
                                    >
                                        정보를
                                    </Text>
                                </Flex>{' '}
                                <Text
                                    fontSize={'40px'}
                                    fontWeight={700}
                                    lineHeight={'60px'}
                                    letterSpacing="-0.03em"
                                    color={'#FFFFFF'}
                                    m={0}
                                >
                                    가장 빠르게 만나보세요
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={'column'}>
                        <Box>
                            <Box position={'relative'}>
                                <Input
                                    width="448px"
                                    height="64px"
                                    borderRadius="32px"
                                    placeholder="이메일을 입력해주세요"
                                    padding="0px 32px 0px 32px"
                                    fontSize={'20px'}
                                    fontWeight={'500'}
                                    lineHeight={'30px'}
                                    letterSpacing="-0.03em"
                                    value={email}
                                    onChange={userIdOnChange}
                                    // margin="0 0 10px 0"
                                />
                                {!verifyEmailYn && (
                                    <Box
                                        position={'absolute'}
                                        top={'-60px'}
                                        backgroundColor={'#FADBDE'}
                                        w={'225px'}
                                        h={'41px'}
                                        borderRadius={'10px'}
                                        textAlign={'center'}
                                        p={'10px 16px'}
                                        left={'110px'}
                                    >
                                        <Box
                                            fontSize={'14px'}
                                            fontWeight={'500'}
                                            lineHeight={'21px'}
                                            letterSpacing="-0.03em"
                                            color={'#E4253C'}
                                        >
                                            이메일을 올바르게 입력해 주세요
                                        </Box>
                                        <Box
                                            w="0"
                                            h="0"
                                            borderStyle="solid"
                                            borderWidth="10px 5px 0 5px"
                                            borderColor="#FADBDE transparent transparent transparent"
                                            position="absolute"
                                            bottom="-10px"
                                            left="50%"
                                            transform="translateX(-50%)"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <Flex mt={'24px'} justifyContent={'space-between'}>
                            <Flex alignItems={'center'} position={'relative'}>
                                {/* <Checkbox
                                                    // isChecked={true} // 체크 여부 설정
                                                    size="lg"
                                                    colorScheme="white"
                                                    color={'#ffffff'}
                                                /> */}
                                <Flex
                                    w={'24px'}
                                    h={'24px'}
                                    border={'1px solid #FFFFFF4D'}
                                    borderRadius={'6px'}
                                    backgroundColor={isChecked ? '#ffffff' : ''}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    cursor={'pointer'}
                                    _hover={{
                                        border: '1px solid #ffffff',
                                    }}
                                    onClick={() => {
                                        setIsChecked(!isChecked);
                                    }}
                                >
                                    {isChecked && <CheckIcon w={'13px'} />}
                                </Flex>
                                <Text
                                    fontSize={'16px'}
                                    fontWeight={500}
                                    lineHeight={'24px'}
                                    letterSpacing="-0.03em"
                                    color={'#ffffff'}
                                    m={0}
                                    ml="12px"
                                >
                                    콘텐츠 수신을 위해 개인정보(이메일)
                                    <br /> 수집 및 제공에 동의합니다
                                </Text>
                                {!verifyTermYn && (
                                    <Box
                                        position={'absolute'}
                                        top={'75px'}
                                        backgroundColor={'#FADBDE'}
                                        w={'245px'}
                                        h={'41px'}
                                        borderRadius={'10px'}
                                        textAlign={'center'}
                                        p={'10px 16px'}
                                        left={'10px'}
                                    >
                                        <Box
                                            fontSize={'14px'}
                                            fontWeight={'500'}
                                            lineHeight={'21px'}
                                            letterSpacing="-0.03em"
                                            color={'#E4253C'}
                                        >
                                            개인정보 수집 및 제공에 동의해 주세요
                                        </Box>
                                        <Box
                                            w="0"
                                            h="0"
                                            borderStyle="solid"
                                            borderWidth="0px 5px 10px 5px"
                                            borderColor="transparent transparent #FADBDE transparent"
                                            position="absolute"
                                            top="-10px"
                                            left="50%"
                                            transform="translateX(-50%)"
                                        />
                                    </Box>
                                )}
                            </Flex>
                            <Box>
                                <Button
                                    w={'132px'}
                                    h={'64px'}
                                    borderRadius={'48px'}
                                    border={0}
                                    p={'14px, 32px, 14px, 32px'}
                                    fontSize={'20px'}
                                    fontWeight={500}
                                    lineHeight={'30px'}
                                    letterSpacing="-0.03em"
                                    color={'#ffffff'}
                                    backgroundColor={'#3766C2'}
                                    cursor={'pointer'}
                                    _hover={{
                                        backgroundColor: '#2C519A',
                                    }}
                                    onClick={() => {
                                        sendEmailInfoHandlar();
                                    }}
                                >
                                    신청하기
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex mt={'120px'} className="news_letter_mobile" minH={'508px'}>
                <Flex
                    className="news_letter_mobile_box"
                    bg={'#101F40'}
                    w={'100%'}
                    // borderRadius={'10px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    minH={'508px'}
                >
                    <Flex w={'100%'} justifyContent={'center'}>
                        <Flex flexDirection={'column'} w={'100%'} justifyContent={'center'}>
                            <Flex alignContent={'center'} justifyContent={'center'} w={'100%'}>
                                <Text
                                    fontSize={'18px'}
                                    fontWeight={500}
                                    lineHeight={'27px'}
                                    letterSpacing="-0.03em"
                                    color={'#83A1DC'}
                                    m={0}
                                >
                                    Buysell Standards Newsletter
                                </Text>
                            </Flex>

                            <Flex flexDirection={'column'} w={'100%'} mt={'18px'} justifyContent={'center'}>
                                <Flex alignContent={'center'} justifyContent={'center'} w={'100%'}>
                                    <Text
                                        fontSize={'25px'}
                                        fontWeight={700}
                                        lineHeight={'35px'}
                                        letterSpacing="-0.03em"
                                        color={'#FFFFFF'}
                                        m={0}
                                    >
                                        STO 산업
                                    </Text>
                                    <Flex w={'106px'} justifyContent={'center'} alignItems={'center'}>
                                        <Image src={GIF1} h={'36px'} w={'90px'} />
                                    </Flex>
                                    <Text
                                        fontSize={'25px'}
                                        fontWeight={700}
                                        lineHeight={'35px'}
                                        letterSpacing="-0.03em"
                                        color={'#FFFFFF'}
                                        m={0}
                                    >
                                        정보를
                                    </Text>
                                </Flex>{' '}
                                <Flex alignContent={'center'} justifyContent={'center'} w={'100%'}>
                                    <Text
                                        fontSize={'25px'}
                                        fontWeight={700}
                                        lineHeight={'35px'}
                                        letterSpacing="-0.03em"
                                        color={'#FFFFFF'}
                                        m={0}
                                    >
                                        가장 빠르게 만나보세요
                                    </Text>
                                </Flex>
                            </Flex>
                            <Box position={'relative'}>
                                <Input
                                    width="100%"
                                    height="48px"
                                    borderRadius="32px"
                                    placeholder="이메일을 입력해주세요"
                                    padding="0px 32px 0px 32px"
                                    fontSize={'16px'}
                                    fontWeight={'500'}
                                    lineHeight={'24px'}
                                    letterSpacing="-0.03em"
                                    mt={'45px'}
                                    value={email}
                                    onChange={userIdOnChange}
                                    // margin="0 0 10px 0"
                                />
                                {!verifyEmailYn && (
                                    <Box
                                        position={'absolute'}
                                        top={'-20px'}
                                        backgroundColor={'#FADBDE'}
                                        w={'225px'}
                                        h={'41px'}
                                        borderRadius={'10px'}
                                        textAlign={'center'}
                                        p={'10px 16px'}
                                        left={'calc(50% - 112.5px)'}
                                    >
                                        <Box
                                            fontSize={'14px'}
                                            fontWeight={'500'}
                                            lineHeight={'21px'}
                                            letterSpacing="-0.03em"
                                            color={'#E4253C'}
                                        >
                                            이메일을 올바르게 입력해 주세요
                                        </Box>
                                        <Box
                                            w="0"
                                            h="0"
                                            borderStyle="solid"
                                            borderWidth="10px 5px 0 5px"
                                            borderColor="#FADBDE transparent transparent transparent"
                                            position="absolute"
                                            bottom="-10px"
                                            left="50%"
                                            transform="translateX(-50%)"
                                        />
                                    </Box>
                                )}
                            </Box>

                            <Flex mt={'16px'} justifyContent={'center'} flexDir={'column'} w={'100%'}>
                                <Flex alignItems={'center'} w={'100%'} position={'relative'}>
                                    {/* <Checkbox
                                                    // isChecked={true} // 체크 여부 설정
                                                    size="lg"
                                                    colorScheme="white"
                                                    color={'#ffffff'}
                                                /> */}
                                    <Flex
                                        w={'20px'}
                                        h={'20px'}
                                        border={'1px solid #FFFFFF4D'}
                                        borderRadius={'6px'}
                                        backgroundColor={isChecked ? '#ffffff' : ''}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        cursor={'pointer'}
                                        _hover={{
                                            border: '1px solid #ffffff',
                                        }}
                                        onClick={() => {
                                            setIsChecked(!isChecked);
                                        }}
                                    >
                                        {isChecked && <CheckIcon w={'13px'} />}
                                    </Flex>
                                    <Text
                                        fontSize={'14px'}
                                        fontWeight={500}
                                        lineHeight={'21px'}
                                        letterSpacing="-0.03em"
                                        color={'#ffffff'}
                                        m={0}
                                        ml="12px"
                                    >
                                        콘텐츠 수신을 위해 개인정보(이메일) 수집 및 제공에 동의합니다
                                    </Text>
                                    {!verifyTermYn && (
                                        <Box
                                            position={'absolute'}
                                            top={'60px'}
                                            backgroundColor={'#FADBDE'}
                                            w={'245px'}
                                            h={'41px'}
                                            borderRadius={'10px'}
                                            textAlign={'center'}
                                            p={'10px 16px'}
                                            left={'calc(50% - 112.5px)'}
                                            zIndex={10}
                                        >
                                            <Box
                                                fontSize={'14px'}
                                                fontWeight={'500'}
                                                lineHeight={'21px'}
                                                letterSpacing="-0.03em"
                                                color={'#E4253C'}
                                            >
                                                개인정보 수집 및 제공에 동의해 주세요
                                            </Box>
                                            <Box
                                                w="0"
                                                h="0"
                                                borderStyle="solid"
                                                borderWidth="0px 5px 10px 5px"
                                                borderColor="transparent transparent #FADBDE transparent"
                                                position="absolute"
                                                top="-10px"
                                                left="50%"
                                                transform="translateX(-50%)"
                                            />
                                        </Box>
                                    )}
                                </Flex>
                                <Box mt={'32px'}>
                                    <Button
                                        w={'100%'}
                                        h={'48px'}
                                        borderRadius={'48px'}
                                        border={0}
                                        p={'14px, 32px, 14px, 32px'}
                                        fontSize={'16px'}
                                        fontWeight={500}
                                        lineHeight={'24px'}
                                        letterSpacing="-0.03em"
                                        color={'#ffffff'}
                                        backgroundColor={'#3766C2'}
                                        cursor={'pointer'}
                                        _hover={{
                                            backgroundColor: '#2C519A',
                                        }}
                                        onClick={() => {
                                            if (window.confirm('뉴스레터 구독 신청하시겠습니까?')) {
                                                sendEmailInfoHandlar();
                                            }
                                        }}
                                    >
                                        신청하기
                                    </Button>
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <ChakraProvider>
                <SubscribeModal isOpen={isOpen} onClose={onClose} email={email} setEmail={setEmail} />
            </ChakraProvider>
        </>
    );
};

function SubscribeModal({
    isOpen,
    onClose,
    email,
    setEmail,
}: {
    isOpen: any;
    onClose: any;
    email?: any;
    setEmail?: any;
}) {
    const closeModal = () => {
        setEmail('');
        onClose();
    };
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
                <ModalOverlay backdropFilter="blur(20px)" />
                <ModalContent w={'320px'} h={'352px'} borderRadius={'10px'}>
                    <ModalBody p={0} w={'320px'} position={'relative'}>
                        <Flex flexDir={'column'}>
                            <Image src={SubscribeModalGIF} h={'200px'} w={'320px'} borderTopRadius={'10px'} />
                            <Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'} h={'152px'}>
                                <Box px={'50px'}>
                                    <Text
                                        fontSize={'20px'}
                                        fontWeight={700}
                                        lineHeight={'30px'}
                                        letterSpacing="-0.03em"
                                        color={'#131313'}
                                    >
                                        구독 신청이 완료되었습니다
                                    </Text>
                                </Box>

                                <Box w={'100%'} px={'55px'} mt={'8px'} textAlign={'center'}>
                                    <Text
                                        as={'span'}
                                        fontSize={'14px'}
                                        fontWeight={500}
                                        lineHeight={'21px'}
                                        letterSpacing="-0.03em"
                                        color={'#131313'}
                                    >
                                        신청한 이메일로 뉴스레터가 발송됩니다
                                        <br />
                                        <Text as={'span'} color={'#3766C2'}>
                                            {email || '-'}
                                        </Text>
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>
                        <Flex
                            borderRadius={'50%'}
                            backgroundColor={'#131313'}
                            w={'52px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            h={'52px'}
                            left={'calc(50% - 26px)'}
                            bottom={'-80px'}
                            position={'absolute'}
                            cursor={'pointer'}
                            onClick={() => {
                                closeModal();
                            }}
                            _hover={{
                                opacity: 0.7,
                            }}
                        >
                            <CloseIcon w={'14px'} h={'14px'} color={'#FFFFFF'} />
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default SendNewsLetter;
