import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type BlackDashboardsResponse = {
  __typename?: 'BlackDashboardsResponse';
  blocks: Array<BlockDataType>;
  transactions: Array<TransactionDataType>;
};

export type BlackDetailResponse = {
  __typename?: 'BlackDetailResponse';
  block?: Maybe<BlockDataType>;
};

export type BlacksResponse = {
  __typename?: 'BlacksResponse';
  blocks: Array<BlockDataType>;
  pageInfo: PageInfoType;
};

export type BlockDataType = {
  __typename?: 'BlockDataType';
  block_hash: Scalars['String']['output'];
  number: Scalars['Float']['output'];
  parent_hash: Scalars['String']['output'];
  state_root: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  transactions?: Maybe<Array<TransactionDataType>>;
  tx_root: Scalars['String']['output'];
};

export type BoardFiles = {
  __typename?: 'BoardFiles';
  board?: Maybe<TBoard>;
  board_id: Scalars['String']['output'];
  cdn_file_name: Scalars['String']['output'];
  cdn_file_path: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  display_order: Scalars['Float']['output'];
  file_id: Scalars['String']['output'];
  is_delete: Scalars['String']['output'];
  origin_file_name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type BoardInquiry = {
  __typename?: 'BoardInquiry';
  board?: Maybe<TBoard>;
  board_id: Scalars['String']['output'];
  inquiry_at: Scalars['DateTime']['output'];
  inquiry_ip: Scalars['String']['output'];
};

export type BoardRelation = {
  __typename?: 'BoardRelation';
  board?: Maybe<TBoard>;
  board_id: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  relation_board_id: Scalars['String']['output'];
};

export type BoardResponse = {
  __typename?: 'BoardResponse';
  board: TBoard;
  nextBoard?: Maybe<TBoard>;
  preBoard?: Maybe<TBoard>;
};

export type BoardsResponse = {
  __typename?: 'BoardsResponse';
  boards: Array<TBoard>;
  pageInfo: PageInfoType;
  searchYearList: Array<YearInfo>;
};

export type CompanyHistory = {
  __typename?: 'CompanyHistory';
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  day?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  history_id: Scalars['String']['output'];
  month?: Maybe<Scalars['Float']['output']>;
  updated_at: Scalars['DateTime']['output'];
  updated_by: Scalars['String']['output'];
  year?: Maybe<Scalars['Float']['output']>;
};

export type CompanyHistorysResponse = {
  __typename?: 'CompanyHistorysResponse';
  companyHistorys: Array<GroupedHistory>;
  pageInfo: PageInfoType;
};

export type Event = {
  __typename?: 'Event';
  app_contents?: Maybe<Scalars['String']['output']>;
  contents?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  event_begin_date?: Maybe<Scalars['String']['output']>;
  event_end_date?: Maybe<Scalars['String']['output']>;
  event_id: Scalars['String']['output'];
  represent_image_path?: Maybe<Scalars['String']['output']>;
  represent_mobile_image_path?: Maybe<Scalars['String']['output']>;
  represent_thumbnail_path?: Maybe<Scalars['String']['output']>;
  share_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  web_contents?: Maybe<Scalars['String']['output']>;
};

export type EventInput = {
  app_contents?: InputMaybe<Scalars['String']['input']>;
  contents?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  event_begin_date?: InputMaybe<Scalars['String']['input']>;
  event_end_date?: InputMaybe<Scalars['String']['input']>;
  represent_image_path?: InputMaybe<Scalars['String']['input']>;
  represent_mobile_image_path?: InputMaybe<Scalars['String']['input']>;
  represent_thumbnail_path?: InputMaybe<Scalars['String']['input']>;
  share_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  web_contents?: InputMaybe<Scalars['String']['input']>;
};

export type EventsResponse = {
  __typename?: 'EventsResponse';
  events: Array<Event>;
  pageInfo: PageInfoType;
};

export type GroupedHistory = {
  __typename?: 'GroupedHistory';
  months: Array<GroupedHistoryMonth>;
  year: Scalars['String']['output'];
};

export type GroupedHistoryDay = {
  __typename?: 'GroupedHistoryDay';
  day: Scalars['String']['output'];
  histories: Array<CompanyHistory>;
};

export type GroupedHistoryMonth = {
  __typename?: 'GroupedHistoryMonth';
  days: Array<GroupedHistoryDay>;
  month: Scalars['String']['output'];
};

export type InvestmentDisclosureDetailResponse = {
  __typename?: 'InvestmentDisclosureDetailResponse';
  board?: Maybe<InvestmentDisclosureList>;
};

export type InvestmentDisclosureList = {
  __typename?: 'InvestmentDisclosureList';
  board_category?: Maybe<Scalars['String']['output']>;
  board_category_nm?: Maybe<Scalars['String']['output']>;
  board_id?: Maybe<Scalars['String']['output']>;
  contents?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  portfolio_id?: Maybe<Scalars['String']['output']>;
  portfolio_title?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type InvestmentDisclosureListResponse = {
  __typename?: 'InvestmentDisclosureListResponse';
  boards: Array<InvestmentDisclosureList>;
  pageInfo: PageInfoType;
};

export type MagazinesResponse = {
  __typename?: 'MagazinesResponse';
  megazines: Array<T_Magazine>;
  pageInfo: PageInfoType;
};

export type Mutation = {
  __typename?: 'Mutation';
  createMessage: Event;
  createReception: ReceptionsPostResponse;
  pubSubMutation: Scalars['Boolean']['output'];
  publisherMutation: Event;
  publisherMutation2: Event;
  searchBlockchain: SearchBlockchainResponse;
  sendMessage: Scalars['String']['output'];
  updateEvent?: Maybe<Event>;
};


export type MutationCreateReceptionArgs = {
  email: Scalars['String']['input'];
};


export type MutationSearchBlockchainArgs = {
  searchKey: Scalars['String']['input'];
};


export type MutationSendMessageArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdateEventArgs = {
  event_id: Scalars['String']['input'];
  input: EventInput;
};

export type Notification2 = {
  __typename?: 'Notification2';
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type NotificationEvent = {
  __typename?: 'NotificationEvent';
  date: Scalars['DateTime']['output'];
  ids: Array<Scalars['String']['output']>;
  items?: Maybe<Array<Event>>;
  type: NotificationUpdateTypes;
};

export type NotificationMessage = {
  __typename?: 'NotificationMessage';
  date: Scalars['DateTime']['output'];
  ids: Array<Scalars['String']['output']>;
  items?: Maybe<Array<Event>>;
  targetIds: Array<Scalars['String']['output']>;
  type: NotificationUpdateTypes;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum NotificationUpdateTypes {
  Created = 'created',
  Deleted = 'deleted',
  Updated = 'updated'
}

export type PageInfoType = {
  __typename?: 'PageInfoType';
  curPage: Scalars['Float']['output'];
  listLength: Scalars['Float']['output'];
  totalLength: Scalars['Float']['output'];
};

export type Popup = {
  __typename?: 'Popup';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  end_at?: Maybe<Scalars['DateTime']['output']>;
  is_active?: Maybe<Scalars['String']['output']>;
  popup_id: Scalars['String']['output'];
  popup_image_path?: Maybe<Scalars['String']['output']>;
  popup_link_type?: Maybe<Scalars['String']['output']>;
  popup_link_url?: Maybe<Scalars['String']['output']>;
  popup_title?: Maybe<Scalars['String']['output']>;
  popup_type?: Maybe<Scalars['String']['output']>;
  start_at?: Maybe<Scalars['DateTime']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  cashability_point?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  disposal_expecatation_date?: Maybe<Scalars['DateTime']['output']>;
  disposal_fee?: Maybe<Scalars['Float']['output']>;
  disposal_fee_method?: Maybe<Scalars['String']['output']>;
  dividends_expecatation_date?: Maybe<Scalars['DateTime']['output']>;
  expectation_profit_rate?: Maybe<Scalars['Float']['output']>;
  fee_rate?: Maybe<Scalars['String']['output']>;
  general_grade?: Maybe<Scalars['String']['output']>;
  is_show?: Maybe<Scalars['String']['output']>;
  is_tax?: Maybe<Scalars['String']['output']>;
  is_vat?: Maybe<Scalars['String']['output']>;
  magazin_link_url?: Maybe<Scalars['String']['output']>;
  max_purchase_amount?: Maybe<Scalars['Float']['output']>;
  min_purchase_amount?: Maybe<Scalars['Float']['output']>;
  portfolio_code?: Maybe<Scalars['String']['output']>;
  portfolio_id: Scalars['String']['output'];
  profitability_point?: Maybe<Scalars['String']['output']>;
  purchase_end_date?: Maybe<Scalars['DateTime']['output']>;
  purchase_fee?: Maybe<Scalars['Float']['output']>;
  purchase_fee_method?: Maybe<Scalars['String']['output']>;
  recruitment_amount?: Maybe<Scalars['Float']['output']>;
  recruitment_begin_date?: Maybe<Scalars['DateTime']['output']>;
  recruitment_end_date?: Maybe<Scalars['DateTime']['output']>;
  recruitment_state?: Maybe<Scalars['String']['output']>;
  recruitment_state_nm?: Maybe<Scalars['String']['output']>;
  represent_image_path?: Maybe<Scalars['String']['output']>;
  represent_thumbnail_image_path?: Maybe<Scalars['String']['output']>;
  retraction_possible_date?: Maybe<Scalars['DateTime']['output']>;
  share_url?: Maybe<Scalars['String']['output']>;
  soldout_at?: Maybe<Scalars['DateTime']['output']>;
  stability_point?: Maybe<Scalars['String']['output']>;
  tax_rate?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  total_piece_volume?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

export type PortfolioAttachFile = {
  __typename?: 'PortfolioAttachFile';
  attach_file_code?: Maybe<Scalars['String']['output']>;
  attach_file_code_nm?: Maybe<Scalars['String']['output']>;
  attach_file_path?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  display_order?: Maybe<Scalars['Float']['output']>;
  portfolio_id: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type PortfolioProduct = {
  __typename?: 'PortfolioProduct';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  display_order?: Maybe<Scalars['Float']['output']>;
  portfolio_id: Scalars['String']['output'];
  product?: Maybe<Product>;
  productStorage?: Maybe<ProductStorage>;
  product_id?: Maybe<Scalars['String']['output']>;
  storage?: Maybe<Product>;
};

export type PortfolioProductList = {
  __typename?: 'PortfolioProductList';
  author?: Maybe<Scalars['String']['output']>;
  product_detail_info?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  product_material?: Maybe<Scalars['String']['output']>;
  product_size?: Maybe<Scalars['String']['output']>;
  represent_thumbnail_image_path?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PortfolioPurchaseGuideList = {
  __typename?: 'PortfolioPurchaseGuideList';
  description?: Maybe<Scalars['String']['output']>;
  guide_icon_path?: Maybe<Scalars['String']['output']>;
  guide_id?: Maybe<Scalars['String']['output']>;
  guide_name?: Maybe<Scalars['String']['output']>;
};

export type PortfoliosResponse = {
  __typename?: 'PortfoliosResponse';
  pageInfo: PageInfoType;
  portfolios: Array<Portfolio>;
};

export type Product = {
  __typename?: 'Product';
  author?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  disposal_amount?: Maybe<Scalars['Float']['output']>;
  disposal_date?: Maybe<Scalars['String']['output']>;
  expectation_disposal_amount?: Maybe<Scalars['Float']['output']>;
  is_delete?: Maybe<Scalars['String']['output']>;
  is_tax?: Maybe<Scalars['String']['output']>;
  is_vat?: Maybe<Scalars['String']['output']>;
  manage_fee_amount?: Maybe<Scalars['Float']['output']>;
  portfolioProduct?: Maybe<PortfolioProduct>;
  product_code?: Maybe<Scalars['String']['output']>;
  product_condition?: Maybe<Scalars['String']['output']>;
  product_detail_info?: Maybe<Scalars['String']['output']>;
  product_id: Scalars['String']['output'];
  product_material?: Maybe<Scalars['String']['output']>;
  product_package_condition?: Maybe<Scalars['String']['output']>;
  product_size?: Maybe<Scalars['String']['output']>;
  production_year?: Maybe<Scalars['String']['output']>;
  purchase_amount?: Maybe<Scalars['Float']['output']>;
  purchase_date?: Maybe<Scalars['String']['output']>;
  recruitment_amount?: Maybe<Scalars['Float']['output']>;
  represent_image_path?: Maybe<Scalars['String']['output']>;
  represent_thumbnail_image_path?: Maybe<Scalars['String']['output']>;
  storage?: Maybe<TStorage>;
  tax_rate?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  vat_rate?: Maybe<Scalars['Float']['output']>;
};

export type ProductStorage = {
  __typename?: 'ProductStorage';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  product_id: Scalars['String']['output'];
  storage: TStorage;
  storage_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  blockDetail: BlackDetailResponse;
  blockchainDashboard: BlackDashboardsResponse;
  blocks: BlacksResponse;
  board?: Maybe<BoardResponse>;
  boardYears: BoardsResponse;
  boards: BoardsResponse;
  companyHistorys: CompanyHistorysResponse;
  event?: Maybe<Event>;
  events: EventsResponse;
  hello: Scalars['String']['output'];
  investmentDisclosureDetail: InvestmentDisclosureDetailResponse;
  investmentDisclosureList: InvestmentDisclosureListResponse;
  megazine?: Maybe<T_Magazine>;
  megazineByPortfolioId?: Maybe<T_Magazine>;
  megazines: MagazinesResponse;
  popup?: Maybe<Popup>;
  portfolio?: Maybe<Portfolio>;
  portfolioAttachFiles?: Maybe<Array<PortfolioAttachFile>>;
  portfolioProductList?: Maybe<Array<PortfolioProductList>>;
  portfolioPurchaseGuideList?: Maybe<Array<PortfolioPurchaseGuideList>>;
  portfolioSelectList: PortfoliosResponse;
  portfolios: PortfoliosResponse;
  relatedMediaNews: RelatedMediaNewsResponse;
  signerDetail: SignerDetailResponse;
  storages?: Maybe<Array<PortfolioProduct>>;
  topBoards: BoardsResponse;
  transactionDetail: TransactionDetailResponse;
  transactions: TransactionsResponse;
};


export type QueryBlockDetailArgs = {
  number: Scalars['Int']['input'];
};


export type QueryBlocksArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryBoardArgs = {
  boardId: Scalars['String']['input'];
};


export type QueryBoardYearsArgs = {
  boardCategory?: InputMaybe<Scalars['String']['input']>;
  boardType: Scalars['String']['input'];
};


export type QueryBoardsArgs = {
  boardCategory: Scalars['String']['input'];
  boardType: Scalars['String']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectYear?: InputMaybe<Scalars['String']['input']>;
  sortType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEventArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryEventsArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryInvestmentDisclosureDetailArgs = {
  board_id: Scalars['String']['input'];
};


export type QueryInvestmentDisclosureListArgs = {
  boardCategory?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  portfolio_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMegazineArgs = {
  magazine_id: Scalars['String']['input'];
};


export type QueryMegazineByPortfolioIdArgs = {
  portfolio_id: Scalars['String']['input'];
};


export type QueryMegazinesArgs = {
  magazineType: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryPortfolioArgs = {
  portfolio_id: Scalars['String']['input'];
};


export type QueryPortfolioAttachFilesArgs = {
  portfolio_id: Scalars['String']['input'];
};


export type QueryPortfolioProductListArgs = {
  portfolio_id: Scalars['String']['input'];
};


export type QueryPortfolioPurchaseGuideListArgs = {
  portfolio_id: Scalars['String']['input'];
};


export type QueryPortfoliosArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type QueryRelatedMediaNewsArgs = {
  boardId: Scalars['String']['input'];
};


export type QuerySignerDetailArgs = {
  signer: Scalars['String']['input'];
};


export type QueryStoragesArgs = {
  portfolio_id: Scalars['String']['input'];
};


export type QueryTransactionDetailArgs = {
  tx_hash: Scalars['String']['input'];
};


export type QueryTransactionsArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type ReceptionsPostResponse = {
  __typename?: 'ReceptionsPostResponse';
  result?: Maybe<Scalars['String']['output']>;
};

export type RelatedMediaNewsResponse = {
  __typename?: 'RelatedMediaNewsResponse';
  boardRelations: Array<BoardRelation>;
};

export type SearchBlockchainResponse = {
  __typename?: 'SearchBlockchainResponse';
  link?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
};

export type SignerDetailResponse = {
  __typename?: 'SignerDetailResponse';
  signer: SignerType;
  transactions: Array<TransactionDataType>;
};

export type SignerType = {
  __typename?: 'SignerType';
  signer: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  transactionCount: Scalars['Float']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  eventChanged: NotificationEvent;
  messageChanged: NotificationMessage;
  newNotification: Scalars['String']['output'];
  subscriptionWithFilter: Notification2;
};


export type SubscriptionMessageChangedArgs = {
  token: Scalars['String']['input'];
};

export type TBoard = {
  __typename?: 'TBoard';
  boadr_thumbnail_path?: Maybe<Scalars['String']['output']>;
  boardInquiries?: Maybe<Array<BoardInquiry>>;
  board_category?: Maybe<Scalars['String']['output']>;
  board_id: Scalars['String']['output'];
  board_type?: Maybe<Scalars['String']['output']>;
  contents?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  display_order?: Maybe<Scalars['Float']['output']>;
  files?: Maybe<Array<BoardFiles>>;
  is_delete?: Maybe<Scalars['String']['output']>;
  is_top_board?: Maybe<Scalars['String']['output']>;
  press?: Maybe<Scalars['String']['output']>;
  relations?: Maybe<Array<BoardRelation>>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type TStorage = {
  __typename?: 'TStorage';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  portfolioProduct?: Maybe<PortfolioProduct>;
  product?: Maybe<Product>;
  storage_detail?: Maybe<Scalars['String']['output']>;
  storage_id: Scalars['String']['output'];
  storage_image_path?: Maybe<Scalars['String']['output']>;
  storage_location?: Maybe<Scalars['String']['output']>;
  storage_title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type T_Magazine = {
  __typename?: 'T_MAGAZINE';
  author: Scalars['String']['output'];
  contents: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  display_order: Scalars['Float']['output'];
  is_delete: Scalars['String']['output'];
  magazine_id: Scalars['String']['output'];
  magazine_type: Scalars['String']['output'];
  mid_title: Scalars['String']['output'];
  represent_image_path: Scalars['String']['output'];
  represent_thumbnail_path: Scalars['String']['output'];
  share_url: Scalars['String']['output'];
  small_title: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  updated_by: Scalars['String']['output'];
};

export type TransactionDataType = {
  __typename?: 'TransactionDataType';
  amount: Scalars['Float']['output'];
  block_number: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  signer: Scalars['String']['output'];
  tx_hash: Scalars['String']['output'];
};

export type TransactionDetailResponse = {
  __typename?: 'TransactionDetailResponse';
  transaction?: Maybe<TransactionDataType>;
};

export type TransactionsResponse = {
  __typename?: 'TransactionsResponse';
  pageInfo: PageInfoType;
  transactions: Array<TransactionDataType>;
};

export type YearInfo = {
  __typename?: 'YearInfo';
  year: Scalars['Int']['output'];
};

export type CreateReceptionMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type CreateReceptionMutation = { __typename?: 'Mutation', createReception: { __typename?: 'ReceptionsPostResponse', result?: string | null } };

export type BoardQueryVariables = Exact<{
  boardId: Scalars['String']['input'];
}>;


export type BoardQuery = { __typename?: 'Query', board?: { __typename?: 'BoardResponse', board: { __typename?: 'TBoard', board_id: string, title?: string | null, contents?: string | null, boadr_thumbnail_path?: string | null, created_at?: any | null, files?: Array<{ __typename?: 'BoardFiles', file_id: string, origin_file_name: string, cdn_file_name: string, cdn_file_path: string }> | null }, preBoard?: { __typename?: 'TBoard', board_id: string, title?: string | null, contents?: string | null, created_at?: any | null } | null, nextBoard?: { __typename?: 'TBoard', board_id: string, title?: string | null, contents?: string | null, created_at?: any | null } | null } | null };

export type BoardYearsQueryVariables = Exact<{
  boardType: Scalars['String']['input'];
  boardCategory: Scalars['String']['input'];
}>;


export type BoardYearsQuery = { __typename?: 'Query', boardYears: { __typename?: 'BoardsResponse', searchYearList: Array<{ __typename?: 'YearInfo', year: number }> } };

export type BoardsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  boardCategory: Scalars['String']['input'];
  boardType: Scalars['String']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  selectYear?: InputMaybe<Scalars['String']['input']>;
  sortType?: InputMaybe<Scalars['String']['input']>;
}>;


export type BoardsQuery = { __typename?: 'Query', boards: { __typename?: 'BoardsResponse', boards: Array<{ __typename?: 'TBoard', board_category?: string | null, board_id: string, board_type?: string | null, title?: string | null, press?: string | null, contents?: string | null, created_at?: any | null, boadr_thumbnail_path?: string | null }>, pageInfo: { __typename?: 'PageInfoType', curPage: number, listLength: number, totalLength: number } }, topBoards: { __typename?: 'BoardsResponse', boards: Array<{ __typename?: 'TBoard', board_category?: string | null, board_id: string, board_type?: string | null, title?: string | null, press?: string | null, contents?: string | null, created_at?: any | null, boadr_thumbnail_path?: string | null }> } };

export type CompanyHistorysQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyHistorysQuery = { __typename?: 'Query', companyHistorys: { __typename?: 'CompanyHistorysResponse', companyHistorys: Array<{ __typename?: 'GroupedHistory', year: string, months: Array<{ __typename?: 'GroupedHistoryMonth', month: string, days: Array<{ __typename?: 'GroupedHistoryDay', day: string, histories: Array<{ __typename?: 'CompanyHistory', history_id: string, year?: number | null, month?: number | null, day?: number | null, description?: string | null }> }> }> }> } };

export type MegazineQueryVariables = Exact<{
  magazine_id: Scalars['String']['input'];
}>;


export type MegazineQuery = { __typename?: 'Query', megazine?: { __typename?: 'T_MAGAZINE', title: string, author: string, contents: string, magazine_id: string, mid_title: string, small_title: string, represent_thumbnail_path: string, created_at: any } | null };

export type MegazinesQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  magazineType: Scalars['String']['input'];
}>;


export type MegazinesQuery = { __typename?: 'Query', megazines: { __typename?: 'MagazinesResponse', megazines: Array<{ __typename?: 'T_MAGAZINE', magazine_id: string, title: string, mid_title: string, small_title: string, represent_thumbnail_path: string, created_at: any }>, pageInfo: { __typename?: 'PageInfoType', curPage: number, listLength: number, totalLength: number } } };

export type RelatedMediaNewsQueryVariables = Exact<{
  boardId: Scalars['String']['input'];
}>;


export type RelatedMediaNewsQuery = { __typename?: 'Query', relatedMediaNews: { __typename?: 'RelatedMediaNewsResponse', boardRelations: Array<{ __typename?: 'BoardRelation', board?: { __typename?: 'TBoard', board_id: string, boadr_thumbnail_path?: string | null, title?: string | null, press?: string | null, created_at?: any | null } | null }> } };

export type TopBoardsQueryVariables = Exact<{ [key: string]: never; }>;


export type TopBoardsQuery = { __typename?: 'Query', topBoards: { __typename?: 'BoardsResponse', boards: Array<{ __typename?: 'TBoard', board_category?: string | null, board_id: string, board_type?: string | null, title?: string | null, press?: string | null, contents?: string | null, created_at?: any | null, boadr_thumbnail_path?: string | null }> } };

export type EventChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type EventChangedSubscription = { __typename?: 'Subscription', eventChanged: { __typename?: 'NotificationEvent', ids: Array<string>, type: NotificationUpdateTypes, date: any, items?: Array<{ __typename?: 'Event', event_id: string, title?: string | null, contents?: string | null, event_end_date?: string | null }> | null } };


export const CreateReceptionDocument = gql`
    mutation CreateReception($email: String!) {
  createReception(email: $email) {
    result
  }
}
    `;
export type CreateReceptionMutationFn = Apollo.MutationFunction<CreateReceptionMutation, CreateReceptionMutationVariables>;

/**
 * __useCreateReceptionMutation__
 *
 * To run a mutation, you first call `useCreateReceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceptionMutation, { data, loading, error }] = useCreateReceptionMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateReceptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceptionMutation, CreateReceptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReceptionMutation, CreateReceptionMutationVariables>(CreateReceptionDocument, options);
      }
export type CreateReceptionMutationHookResult = ReturnType<typeof useCreateReceptionMutation>;
export type CreateReceptionMutationResult = Apollo.MutationResult<CreateReceptionMutation>;
export type CreateReceptionMutationOptions = Apollo.BaseMutationOptions<CreateReceptionMutation, CreateReceptionMutationVariables>;
export const BoardDocument = gql`
    query Board($boardId: String!) {
  board(boardId: $boardId) {
    board {
      board_id
      title
      contents
      boadr_thumbnail_path
      created_at
      files {
        file_id
        origin_file_name
        cdn_file_name
        cdn_file_path
      }
    }
    preBoard {
      board_id
      title
      contents
      created_at
    }
    nextBoard {
      board_id
      title
      contents
      created_at
    }
  }
}
    `;

/**
 * __useBoardQuery__
 *
 * To run a query within a React component, call `useBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useBoardQuery(baseOptions: Apollo.QueryHookOptions<BoardQuery, BoardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoardQuery, BoardQueryVariables>(BoardDocument, options);
      }
export function useBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoardQuery, BoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoardQuery, BoardQueryVariables>(BoardDocument, options);
        }
export function useBoardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BoardQuery, BoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BoardQuery, BoardQueryVariables>(BoardDocument, options);
        }
export type BoardQueryHookResult = ReturnType<typeof useBoardQuery>;
export type BoardLazyQueryHookResult = ReturnType<typeof useBoardLazyQuery>;
export type BoardSuspenseQueryHookResult = ReturnType<typeof useBoardSuspenseQuery>;
export type BoardQueryResult = Apollo.QueryResult<BoardQuery, BoardQueryVariables>;
export const BoardYearsDocument = gql`
    query BoardYears($boardType: String!, $boardCategory: String!) {
  boardYears(boardCategory: $boardCategory, boardType: $boardType) {
    searchYearList {
      year
    }
  }
}
    `;

/**
 * __useBoardYearsQuery__
 *
 * To run a query within a React component, call `useBoardYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardYearsQuery({
 *   variables: {
 *      boardType: // value for 'boardType'
 *      boardCategory: // value for 'boardCategory'
 *   },
 * });
 */
export function useBoardYearsQuery(baseOptions: Apollo.QueryHookOptions<BoardYearsQuery, BoardYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoardYearsQuery, BoardYearsQueryVariables>(BoardYearsDocument, options);
      }
export function useBoardYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoardYearsQuery, BoardYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoardYearsQuery, BoardYearsQueryVariables>(BoardYearsDocument, options);
        }
export function useBoardYearsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BoardYearsQuery, BoardYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BoardYearsQuery, BoardYearsQueryVariables>(BoardYearsDocument, options);
        }
export type BoardYearsQueryHookResult = ReturnType<typeof useBoardYearsQuery>;
export type BoardYearsLazyQueryHookResult = ReturnType<typeof useBoardYearsLazyQuery>;
export type BoardYearsSuspenseQueryHookResult = ReturnType<typeof useBoardYearsSuspenseQuery>;
export type BoardYearsQueryResult = Apollo.QueryResult<BoardYearsQuery, BoardYearsQueryVariables>;
export const BoardsDocument = gql`
    query Boards($page: Int!, $pageSize: Int!, $boardCategory: String!, $boardType: String!, $keyword: String, $selectYear: String, $sortType: String) {
  boards(
    page: $page
    pageSize: $pageSize
    boardCategory: $boardCategory
    boardType: $boardType
    keyword: $keyword
    selectYear: $selectYear
    sortType: $sortType
  ) {
    boards {
      board_category
      board_id
      board_type
      title
      press
      contents
      created_at
      boadr_thumbnail_path
    }
    pageInfo {
      curPage
      listLength
      totalLength
    }
  }
  topBoards {
    boards {
      board_category
      board_id
      board_type
      title
      press
      contents
      created_at
      boadr_thumbnail_path
    }
  }
}
    `;

/**
 * __useBoardsQuery__
 *
 * To run a query within a React component, call `useBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      boardCategory: // value for 'boardCategory'
 *      boardType: // value for 'boardType'
 *      keyword: // value for 'keyword'
 *      selectYear: // value for 'selectYear'
 *      sortType: // value for 'sortType'
 *   },
 * });
 */
export function useBoardsQuery(baseOptions: Apollo.QueryHookOptions<BoardsQuery, BoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoardsQuery, BoardsQueryVariables>(BoardsDocument, options);
      }
export function useBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoardsQuery, BoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoardsQuery, BoardsQueryVariables>(BoardsDocument, options);
        }
export function useBoardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BoardsQuery, BoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BoardsQuery, BoardsQueryVariables>(BoardsDocument, options);
        }
export type BoardsQueryHookResult = ReturnType<typeof useBoardsQuery>;
export type BoardsLazyQueryHookResult = ReturnType<typeof useBoardsLazyQuery>;
export type BoardsSuspenseQueryHookResult = ReturnType<typeof useBoardsSuspenseQuery>;
export type BoardsQueryResult = Apollo.QueryResult<BoardsQuery, BoardsQueryVariables>;
export const CompanyHistorysDocument = gql`
    query CompanyHistorys {
  companyHistorys {
    companyHistorys {
      year
      months {
        month
        days {
          day
          histories {
            history_id
            year
            month
            day
            description
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCompanyHistorysQuery__
 *
 * To run a query within a React component, call `useCompanyHistorysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHistorysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHistorysQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyHistorysQuery(baseOptions?: Apollo.QueryHookOptions<CompanyHistorysQuery, CompanyHistorysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyHistorysQuery, CompanyHistorysQueryVariables>(CompanyHistorysDocument, options);
      }
export function useCompanyHistorysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyHistorysQuery, CompanyHistorysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyHistorysQuery, CompanyHistorysQueryVariables>(CompanyHistorysDocument, options);
        }
export function useCompanyHistorysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompanyHistorysQuery, CompanyHistorysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyHistorysQuery, CompanyHistorysQueryVariables>(CompanyHistorysDocument, options);
        }
export type CompanyHistorysQueryHookResult = ReturnType<typeof useCompanyHistorysQuery>;
export type CompanyHistorysLazyQueryHookResult = ReturnType<typeof useCompanyHistorysLazyQuery>;
export type CompanyHistorysSuspenseQueryHookResult = ReturnType<typeof useCompanyHistorysSuspenseQuery>;
export type CompanyHistorysQueryResult = Apollo.QueryResult<CompanyHistorysQuery, CompanyHistorysQueryVariables>;
export const MegazineDocument = gql`
    query Megazine($magazine_id: String!) {
  megazine(magazine_id: $magazine_id) {
    title
    author
    contents
    magazine_id
    mid_title
    small_title
    represent_thumbnail_path
    created_at
  }
}
    `;

/**
 * __useMegazineQuery__
 *
 * To run a query within a React component, call `useMegazineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMegazineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMegazineQuery({
 *   variables: {
 *      magazine_id: // value for 'magazine_id'
 *   },
 * });
 */
export function useMegazineQuery(baseOptions: Apollo.QueryHookOptions<MegazineQuery, MegazineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MegazineQuery, MegazineQueryVariables>(MegazineDocument, options);
      }
export function useMegazineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MegazineQuery, MegazineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MegazineQuery, MegazineQueryVariables>(MegazineDocument, options);
        }
export function useMegazineSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MegazineQuery, MegazineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MegazineQuery, MegazineQueryVariables>(MegazineDocument, options);
        }
export type MegazineQueryHookResult = ReturnType<typeof useMegazineQuery>;
export type MegazineLazyQueryHookResult = ReturnType<typeof useMegazineLazyQuery>;
export type MegazineSuspenseQueryHookResult = ReturnType<typeof useMegazineSuspenseQuery>;
export type MegazineQueryResult = Apollo.QueryResult<MegazineQuery, MegazineQueryVariables>;
export const MegazinesDocument = gql`
    query Megazines($page: Int!, $pageSize: Int!, $magazineType: String!) {
  megazines(page: $page, pageSize: $pageSize, magazineType: $magazineType) {
    megazines {
      magazine_id
      title
      mid_title
      small_title
      represent_thumbnail_path
      created_at
    }
    pageInfo {
      curPage
      listLength
      totalLength
    }
  }
}
    `;

/**
 * __useMegazinesQuery__
 *
 * To run a query within a React component, call `useMegazinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMegazinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMegazinesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      magazineType: // value for 'magazineType'
 *   },
 * });
 */
export function useMegazinesQuery(baseOptions: Apollo.QueryHookOptions<MegazinesQuery, MegazinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MegazinesQuery, MegazinesQueryVariables>(MegazinesDocument, options);
      }
export function useMegazinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MegazinesQuery, MegazinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MegazinesQuery, MegazinesQueryVariables>(MegazinesDocument, options);
        }
export function useMegazinesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MegazinesQuery, MegazinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MegazinesQuery, MegazinesQueryVariables>(MegazinesDocument, options);
        }
export type MegazinesQueryHookResult = ReturnType<typeof useMegazinesQuery>;
export type MegazinesLazyQueryHookResult = ReturnType<typeof useMegazinesLazyQuery>;
export type MegazinesSuspenseQueryHookResult = ReturnType<typeof useMegazinesSuspenseQuery>;
export type MegazinesQueryResult = Apollo.QueryResult<MegazinesQuery, MegazinesQueryVariables>;
export const RelatedMediaNewsDocument = gql`
    query RelatedMediaNews($boardId: String!) {
  relatedMediaNews(boardId: $boardId) {
    boardRelations {
      board {
        board_id
        boadr_thumbnail_path
        title
        press
        created_at
      }
    }
  }
}
    `;

/**
 * __useRelatedMediaNewsQuery__
 *
 * To run a query within a React component, call `useRelatedMediaNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedMediaNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedMediaNewsQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useRelatedMediaNewsQuery(baseOptions: Apollo.QueryHookOptions<RelatedMediaNewsQuery, RelatedMediaNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelatedMediaNewsQuery, RelatedMediaNewsQueryVariables>(RelatedMediaNewsDocument, options);
      }
export function useRelatedMediaNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedMediaNewsQuery, RelatedMediaNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelatedMediaNewsQuery, RelatedMediaNewsQueryVariables>(RelatedMediaNewsDocument, options);
        }
export function useRelatedMediaNewsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RelatedMediaNewsQuery, RelatedMediaNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RelatedMediaNewsQuery, RelatedMediaNewsQueryVariables>(RelatedMediaNewsDocument, options);
        }
export type RelatedMediaNewsQueryHookResult = ReturnType<typeof useRelatedMediaNewsQuery>;
export type RelatedMediaNewsLazyQueryHookResult = ReturnType<typeof useRelatedMediaNewsLazyQuery>;
export type RelatedMediaNewsSuspenseQueryHookResult = ReturnType<typeof useRelatedMediaNewsSuspenseQuery>;
export type RelatedMediaNewsQueryResult = Apollo.QueryResult<RelatedMediaNewsQuery, RelatedMediaNewsQueryVariables>;
export const TopBoardsDocument = gql`
    query TopBoards {
  topBoards {
    boards {
      board_category
      board_id
      board_type
      title
      press
      contents
      created_at
      boadr_thumbnail_path
    }
  }
}
    `;

/**
 * __useTopBoardsQuery__
 *
 * To run a query within a React component, call `useTopBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopBoardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopBoardsQuery(baseOptions?: Apollo.QueryHookOptions<TopBoardsQuery, TopBoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopBoardsQuery, TopBoardsQueryVariables>(TopBoardsDocument, options);
      }
export function useTopBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopBoardsQuery, TopBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopBoardsQuery, TopBoardsQueryVariables>(TopBoardsDocument, options);
        }
export function useTopBoardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TopBoardsQuery, TopBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopBoardsQuery, TopBoardsQueryVariables>(TopBoardsDocument, options);
        }
export type TopBoardsQueryHookResult = ReturnType<typeof useTopBoardsQuery>;
export type TopBoardsLazyQueryHookResult = ReturnType<typeof useTopBoardsLazyQuery>;
export type TopBoardsSuspenseQueryHookResult = ReturnType<typeof useTopBoardsSuspenseQuery>;
export type TopBoardsQueryResult = Apollo.QueryResult<TopBoardsQuery, TopBoardsQueryVariables>;
export const EventChangedDocument = gql`
    subscription EventChanged {
  eventChanged {
    ids
    type
    items {
      event_id
      title
      contents
      event_end_date
    }
    date
  }
}
    `;

/**
 * __useEventChangedSubscription__
 *
 * To run a query within a React component, call `useEventChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEventChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<EventChangedSubscription, EventChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<EventChangedSubscription, EventChangedSubscriptionVariables>(EventChangedDocument, options);
      }
export type EventChangedSubscriptionHookResult = ReturnType<typeof useEventChangedSubscription>;
export type EventChangedSubscriptionResult = Apollo.SubscriptionResult<EventChangedSubscription>;