import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import MainImageMobile1 from '../../../assets/images/main/main-top-mobile-1.png';
import MainImageMobile2 from '../../../assets/images/main/main-top-mobile-2.png';
import MainImageMobile3 from '../../../assets/images/main/main-top-mobile-3.png';
import MainImageMobile4 from '../../../assets/images/main/main-top-mobile-4.png';
import MainImageWeb1 from '../../../assets/images/main/main-top-web-1.jpg';
import MainImageWeb2 from '../../../assets/images/main/main-top-web-2.jpeg';
import MainImageWeb3 from '../../../assets/images/main/main-top-web-3.jpeg';
import MainImageWeb4 from '../../../assets/images/main/main-top-web-4.jpeg';

interface MainVideoProps {
    videoRef: React.MutableRefObject<any>;
}
function MainVideo(props: MainVideoProps) {
    const { videoRef } = props;
    let slider1: Slider | null;
    const sliderRef = useRef<Slider>(null);
    const imgSettings: Settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        appendDots: (dots: any) => (
            <div
                style={{
                    width: '100%',
                    position: 'absolute',
                    bottom: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ul> {dots} </ul>
            </div>
        ),
        dotsClass: 'dots_custom',

        // customPaging: (index: number) => (
        //     <Flex
        //         justifyContent={'center'}
        //         w={'100%'}
        //         alignItems={'center'}
        //         flexDirection={'row'}
        //         position={'absolute'}
        //         bottom={'140px'}
        //         className="main-slider-img-mobile"
        //     >
        //         <Box>
        //             {Array.from({ length: slideCount }).map((_, index) => (
        //                 <span
        //                     key={index}
        //                     onClick={() => goToSlide(index)}
        //                     style={{
        //                         cursor: 'pointer',
        //                         marginRight: '4px',
        //                         marginLeft: '4px',
        //                         fontSize: '13px',
        //                         color: currentSlide === index ? '#ffffff' : '#B8BCC8',
        //                     }}
        //                 >
        //                     ●
        //                 </span>
        //             ))}
        //         </Box>
        //     </Flex>
        // ),
    };
    const imgSettings2: Settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        appendDots: (dots: any) => (
            <div
                style={{
                    width: '100%',
                    position: 'absolute',
                    bottom: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ul> {dots} </ul>
            </div>
            // <Flex flexDir={'column'}>
            //     <span
            //         // onClick={() => goToSlide(index)}
            //         style={{
            //             cursor: 'pointer',
            //             marginRight: '4px',
            //             marginLeft: '4px',
            //             fontSize: '16px',
            //             // color: currentSlide === index ? '#ffffff' : '#B8BCC8',
            //         }}
            //     >
            //         ●
            //     </span>
            // </Flex>
        ),
        dotsClass: 'dots_custom',
    };
    const [slideCount, setSlideCount] = useState(4); // 슬라이드의 총 개수를 설정하세요
    const [currentSlide, setCurrentSlide] = React.useState(0);

    let slider2: Slider | null;

    const goToSlide = (index: number) => {
        slider1 && slider1.slickGoTo(index);
        slider2 && slider2.slickGoTo(index);

        setCurrentSlide(index);
    };

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (currentSlide === slideCount - 1) {
    //             goToSlide(0);
    //         } else {
    //             goToSlide(currentSlide + 1);
    //         }
    //     }, 4000);

    //     return () => clearInterval(intervalId); // 컴포넌트 언마운트 시에 clearInterval 호출
    // }, [currentSlide, slideCount]);

    return (
        // <ChakraProvider>
        <Box ref={videoRef} position={'relative'}>
            <Box className="main-slider-img-web" justifyContent={'center'} w={'100%'}>
                <Box maxW="1920px" maxH={'1012px'} w={'100%'}>
                    <Slider {...imgSettings2} ref={(sliderRef: Slider | null) => (slider1 = sliderRef)}>
                        <Flex w={'100%'} position={'relative'}>
                            <Image
                                src={MainImageWeb1}
                                w={'100%'}
                                backgroundColor={'gray'}
                                sx={{
                                    filter: 'brightness(40%)', // 40% 어둡게
                                }}
                                // px={'48px'}
                            />
                            <Flex
                                flexDir={'column'}
                                position={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'} // 중앙 정렬
                                w={'100%'}
                            >
                                <Flex justifyContent={'center'} textAlign={'center'} flexDir={'column'} w={'100%'}>
                                    <Text
                                        className="title"
                                        fontSize={'90px'}
                                        lineHeight={'99px'}
                                        fontWeight={700}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                        m={0}
                                    >
                                        Driving Innovation,
                                        <br />
                                        Defining the Standard
                                    </Text>
                                    <Text
                                        className="sub-title"
                                        m={0}
                                        mt={'48px'}
                                        fontSize={'40px'}
                                        lineHeight={'52px'}
                                        fontWeight={400}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                    >
                                        바이셀스탠다드, 신 금융의 기준입니다
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex w={'100%'} position={'relative'}>
                            <Image
                                src={MainImageWeb2}
                                w={'100%'}
                                backgroundColor={'gray'}
                                sx={{
                                    filter: 'brightness(40%)', // 40% 어둡게
                                }}
                                // px={'48px'}
                            />
                            <Flex
                                flexDir={'column'}
                                position={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'} // 중앙 정렬
                                w={'100%'}
                            >
                                <Flex justifyContent={'center'} textAlign={'center'} flexDir={'column'} w={'100%'}>
                                    <Text
                                        fontSize={'90px'}
                                        lineHeight={'99px'}
                                        fontWeight={700}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                        m={0}
                                    >
                                        Exceptional Team,
                                        <br />
                                        Extraordinary Impact
                                    </Text>
                                    <Text
                                        m={0}
                                        mt={'48px'}
                                        fontSize={'40px'}
                                        lineHeight={'52px'}
                                        fontWeight={400}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                    >
                                        최고 전문가들이 비전을 현실로 만듭니다
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex w={'100%'} position={'relative'}>
                            <Image
                                src={MainImageWeb3}
                                w={'100%'}
                                backgroundColor={'gray'}
                                sx={{
                                    filter: 'brightness(40%)', // 40% 어둡게
                                }}
                                // px={'48px'}
                            />
                            <Flex
                                flexDir={'column'}
                                position={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'} // 중앙 정렬
                                w={'100%'}
                            >
                                <Flex justifyContent={'center'} textAlign={'center'} flexDir={'column'} w={'100%'}>
                                    <Text
                                        fontSize={'90px'}
                                        lineHeight={'99px'}
                                        fontWeight={700}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                        m={0}
                                    >
                                        PMF Radar,
                                        <br />
                                        Navigating Products to Market
                                    </Text>
                                    <Text
                                        m={0}
                                        mt={'48px'}
                                        fontSize={'40px'}
                                        lineHeight={'52px'}
                                        fontWeight={400}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                    >
                                        우리 가는 길이 시장의 선택이 됩니다
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex w={'100%'} position={'relative'}>
                            <Image
                                src={MainImageWeb4}
                                w={'100%'}
                                backgroundColor={'gray'}
                                sx={{
                                    filter: 'brightness(40%)', // 40% 어둡게
                                }}
                                // px={'48px'}
                            />
                            <Flex
                                flexDir={'column'}
                                position={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'} // 중앙 정렬
                                w={'100%'}
                            >
                                <Flex justifyContent={'center'} textAlign={'center'} flexDir={'column'} w={'100%'}>
                                    <Text
                                        fontSize={'90px'}
                                        lineHeight={'99px'}
                                        fontWeight={700}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                        m={0}
                                    >
                                        As one Team,
                                        <br />
                                        Achieve your Goals
                                    </Text>
                                    <Text
                                        m={0}
                                        mt={'48px'}
                                        fontSize={'40px'}
                                        lineHeight={'52px'}
                                        fontWeight={400}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                    >
                                        우리는 당신을 위한 '원팀'입니다
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Slider>
                </Box>
            </Box>
            <Box className="main-slider-img-mobile" justifyContent={'center'} w={'100%'}>
                <Box w={'100%'} h={'100vh'}>
                    <Slider {...imgSettings} ref={(sliderRef: Slider | null) => (slider2 = sliderRef)}>
                        <Flex w={'100%'} position={'relative'}>
                            <Image
                                src={MainImageMobile1}
                                w={'100%'}
                                h={'calc(100vh - 60px)'}
                                backgroundColor={'gray'}
                                objectFit={'cover'}
                                sx={{
                                    filter: 'brightness(40%)', // 40% 어둡게
                                }}
                                // px={'48px'}
                            />
                            <Flex
                                flexDir={'column'}
                                position={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'} // 중앙 정렬
                                w={'100%'}
                            >
                                <Flex justifyContent={'center'} textAlign={'center'} flexDir={'column'} w={'100%'}>
                                    <Text
                                        fontSize={'36px'}
                                        lineHeight={'43.2px'}
                                        fontWeight={700}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                        m={0}
                                    >
                                        Innovation &
                                        <br />
                                        Standard
                                    </Text>
                                    <Text
                                        m={0}
                                        mt={'16px'}
                                        fontSize={'18px'}
                                        lineHeight={'23.4px'}
                                        fontWeight={400}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                    >
                                        바이셀스탠다드, 신 금융의 기준입니다
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex w={'100%'} position={'relative'}>
                            <Image
                                src={MainImageMobile2}
                                w={'100%'}
                                h={'calc(100vh - 60px)'}
                                objectFit={'cover'}
                                backgroundColor={'gray'}
                                sx={{
                                    filter: 'brightness(40%)', // 40% 어둡게
                                }}
                                // px={'48px'}
                            />
                            <Flex
                                flexDir={'column'}
                                position={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'} // 중앙 정렬
                                w={'100%'}
                            >
                                <Flex justifyContent={'center'} textAlign={'center'} flexDir={'column'} w={'100%'}>
                                    <Text
                                        fontSize={'36px'}
                                        lineHeight={'43.2px'}
                                        fontWeight={700}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                        m={0}
                                    >
                                        Exceptional
                                        <br />
                                        Team
                                    </Text>
                                    <Text
                                        m={0}
                                        mt={'16px'}
                                        fontSize={'18px'}
                                        lineHeight={'23.4px'}
                                        fontWeight={400}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                    >
                                        최고 전문가들이 비전을 현실로 만듭니다
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex w={'100%'} position={'relative'}>
                            <Image
                                src={MainImageMobile3}
                                w={'100%'}
                                h={'calc(100vh - 60px)'}
                                objectFit={'cover'}
                                backgroundColor={'gray'}
                                sx={{
                                    filter: 'brightness(40%)', // 40% 어둡게
                                }}
                                // px={'48px'}
                            />
                            <Flex
                                flexDir={'column'}
                                position={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'} // 중앙 정렬
                                w={'100%'}
                            >
                                <Flex justifyContent={'center'} textAlign={'center'} flexDir={'column'} w={'100%'}>
                                    <Text
                                        fontSize={'36px'}
                                        lineHeight={'43.2px'}
                                        fontWeight={700}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                        m={0}
                                    >
                                        Product-
                                        <br />
                                        Market Fit
                                    </Text>
                                    <Text
                                        m={0}
                                        mt={'16px'}
                                        fontSize={'18px'}
                                        lineHeight={'23.4px'}
                                        fontWeight={400}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                    >
                                        우리 가는 길이 시장의 선택이 됩니다
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex w={'100%'} position={'relative'}>
                            <Image
                                src={MainImageMobile4}
                                w={'100%'}
                                h={'calc(100vh - 60px)'}
                                objectFit={'cover'}
                                backgroundColor={'gray'}
                                sx={{
                                    filter: 'brightness(40%)', // 40% 어둡게
                                }}
                                // px={'48px'}
                            />
                            <Flex
                                flexDir={'column'}
                                position={'absolute'}
                                top={'50%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'} // 중앙 정렬
                                w={'100%'}
                            >
                                <Flex justifyContent={'center'} textAlign={'center'} flexDir={'column'} w={'100%'}>
                                    <Text
                                        fontSize={'36px'}
                                        lineHeight={'43.2px'}
                                        fontWeight={700}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                        m={0}
                                    >
                                        Band
                                        <br />
                                        Together
                                    </Text>
                                    <Text
                                        m={0}
                                        mt={'16px'}
                                        fontSize={'18px'}
                                        lineHeight={'23.4px'}
                                        fontWeight={400}
                                        letterSpacing={'-3%'}
                                        color={'#ffffff'}
                                    >
                                        우리는 당신을 위한 '원팀'입니다
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Slider>
                </Box>
            </Box>
            {/* <Flex
                justifyContent={'center'}
                w={'100%'}
                alignItems={'center'}
                flexDirection={'row'}
                position={'absolute'}
                bottom={'60px'}
                className="main-slider-img-web"
            >
                <Box>
                    {Array.from({ length: slideCount }).map((_, index) => (
                        <span
                            key={index}
                            onClick={() => goToSlide(index)}
                            style={{
                                cursor: 'pointer',
                                marginRight: '4px',
                                marginLeft: '4px',
                                fontSize: '16px',
                                color: currentSlide === index ? '#ffffff' : '#B8BCC8',
                            }}
                        >
                            ●
                        </span>
                    ))}
                </Box>
            </Flex>
            <Flex
                justifyContent={'center'}
                w={'100%'}
                alignItems={'center'}
                flexDirection={'row'}
                position={'absolute'}
                bottom={'140px'}
                className="main-slider-img-mobile"
            >
                <Box>
                    {Array.from({ length: slideCount }).map((_, index) => (
                        <span
                            key={index}
                            onClick={() => goToSlide(index)}
                            style={{
                                cursor: 'pointer',
                                marginRight: '4px',
                                marginLeft: '4px',
                                fontSize: '13px',
                                color: currentSlide === index ? '#ffffff' : '#B8BCC8',
                            }}
                        >
                            ●
                        </span>
                    ))}
                </Box>
            </Flex> */}
        </Box>
        // </ChakraProvider>
    );
}

export default MainVideo;
