import { Controls, PlayState, Timeline, Tween } from 'react-gsap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import CES from '../../../assets/images/common/ces.svg';
import UN from '../../../assets/images/main/parteners15.png';
import classNames from 'classnames';

interface PartnersProps {
    section04Ref: React.MutableRefObject<any>;
}
function Partners(props: PartnersProps) {
    const { section04Ref } = props;
    return (
        <div className="section04" ref={section04Ref}>
            <div className="section-title">
                <div className="title-text">
                    <p className="text-p1">PARTNERS</p>
                </div>
            </div>
            <div className="section-content">
                <ul>
                    <li>
                        <img src={require('../../../assets/images/main/woori2.png')} style={{ height: '39px' }} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners01.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners02.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners03.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners04.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners05.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners06.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners07.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners08.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners09.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners10.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners11.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners12.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners13.png')} />
                    </li>
                    <li>
                        <img src={require('../../../assets/images/main/parteners14.png')} />
                    </li>

                    <li>
                        <img src={require('../../../assets/images/main/parteners15.png')} />
                    </li>
                    {/* <li>
                        <img src={CES} />
                    </li> */}
                </ul>
            </div>
        </div>
    );
}

export default Partners;
