import { Col, Modal, Row } from 'react-bootstrap';
import React, { useState } from 'react';

import CES from '../../assets/images/common/ces.svg';
import DesignAward from '../../assets/images/common/designAward.svg';
import INTERNATIONAL from '../../assets/images/common/international.svg';
import Igc from '../../assets/images/common/igcImage.svg';
import { Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import UN from '../../assets/images/common/UN.svg';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [show, setShow] = useState(false);
    const modalHandler = () => setShow(!show);
    const [emailModalShow, setEmailModalShow] = useState(false);
    const emailModalHandler = () => setEmailModalShow(!emailModalShow);
    return (
        <React.Fragment>
            <div className="footer">
                <div className="footer-wrap">
                    <div className="footer-header">
                        <div className="footer-menu">
                            <ul>
                                <li className="cursor-pointer">
                                    <a onClick={() => window.open('https://piece.run', '_blank')}>서비스소개</a>
                                </li>
                                <li className="cursor-pointer">
                                    <a
                                        onClick={() => {
                                            emailModalHandler();
                                        }}
                                    >
                                        제휴문의
                                    </a>
                                </li>
                            </ul>
                            <ul style={{ marginTop: '50px' }} className="sns">
                                <li
                                    className="layer-popup"
                                    onClick={() => {
                                        modalHandler();
                                    }}
                                >
                                    <img src={require('../../assets/images/common/icon-insta.png')} />
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/piecekorea2021/" target="_blank">
                                        <img src={require('../../assets/images/common/icon-face.png')} />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://blog.naver.com/buysellstandards" target="_blank">
                                        <img src={require('../../assets/images/common/icon-blog.png')} />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="footer-sns-icon">
                            <ul>
                                <li className="layer-popup">
                                    <div style={{ marginLeft: '40px' }}>
                                        <Image src={DesignAward} className="footer_image" />
                                        <p
                                            style={{
                                                fontFamily: 'Pretendard',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                letterSpacing: '-0.03em',
                                                color: '#FFFFFF',
                                                textAlign: 'center',
                                            }}
                                        >
                                            iF Design Award 2022 <br />
                                            본상 수상
                                        </p>
                                    </div>
                                </li>
                                <li className="layer-popup">
                                    <div style={{ marginLeft: '32px' }}>
                                        <Image src={CES} className="footer_image" />
                                        <p
                                            style={{
                                                fontFamily: 'Pretendard',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                letterSpacing: '-0.03em',
                                                color: '#FFFFFF',
                                                textAlign: 'center',
                                            }}
                                        >
                                            2023 CES
                                            <br />
                                            선정기업
                                        </p>
                                    </div>
                                </li>
                                <li className="layer-popup">
                                    <div style={{ marginLeft: '40px' }}>
                                        <Image src={UN} className="footer_image" />
                                        <p
                                            style={{
                                                fontFamily: 'Pretendard',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                letterSpacing: '-0.03em',
                                                color: '#FFFFFF',
                                                textAlign: 'center',
                                            }}
                                        >
                                            UN SDGs
                                            <br />
                                            정회원 가입
                                        </p>
                                    </div>
                                </li>
                                <li className="layer-popup">
                                    <div style={{ marginLeft: '40px' }}>
                                        <Image src={INTERNATIONAL} className="footer_image" />
                                        <p
                                            style={{
                                                fontFamily: 'Pretendard',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                letterSpacing: '-0.03em',
                                                color: '#FFFFFF',
                                                textAlign: 'center',
                                            }}
                                        >
                                            아시안 뱅커지 선정
                                            <br />
                                            DeFi 최우수 혁신상 수상
                                        </p>
                                    </div>
                                </li>

                                <li className="layer-popup">
                                    <div style={{ marginLeft: '40px' }}>
                                        <Image src={Igc} className="footer_image" />
                                        <p
                                            style={{
                                                fontFamily: 'Pretendard',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                letterSpacing: '-0.03em',
                                                color: '#FFFFFF',
                                                textAlign: 'center',
                                            }}
                                        >
                                            ISO27001 <br />
                                            정보보안 국제표준 획득
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-info">
                        {/* <div className="footer-info-logo">
                <img
                  src={require("../../assets/images/common/logo-footer.png")}
                />
              </div> */}
                        <div className="footer-info-cont">
                            <div className="footer-info-text">
                                <img src={require('../../assets/images/common/logo-footer.png')} />
                            </div>
                            <div className="footer-info-contact">
                                <span className="fw600">(주)바이셀스탠다드 | 대표이사 신범준</span>
                                <span className="fw600">서울시 영등포구 의사당대로 82 하나증권빌딩 11F</span>
                                <span className="fw600">ⓒ {currentYear} Buysell Standards. All rights reserved.</span>
                            </div>
                        </div>
                        {/* <div className="footer-info-cont">
                <div className="footer-info-text">
                  <span>(주)바이셀스탠다드 | 대표 신범준</span>
                  <span>서울시 강서구 공항대로 194 퀸즈파크12 10F</span>
                  <span>사업자등록번호 803-88-01202</span>
                  <span>
                    ⓒ {currentYear} Buysell Standards. All rights reserved.
                  </span>
                </div>
                <div className="footer-info-contact">
                  <span className="fw600">Contact</span>
                  <span>이메일 contact@buysellstandards.com</span>
                  <span>대표전화 02-6737-8282 | 팩스 02-6741-8282</span>
                  <span>운영시간 평일 09:00~18:00</span>
                  <span>점심시간 12:00~13:00 | 주말, 공휴일 휴무</span>
                </div>
              </div> */}
                    </div>
                </div>
                <SnsModal show={show} modalHandler={modalHandler} />
                <EmailModal emailModalShow={emailModalShow} emailModalHandler={emailModalHandler} />
            </div>
        </React.Fragment>
    );
};

interface SnsModalProps {
    show: boolean;
    modalHandler: () => void;
}

const SnsModal = (props: SnsModalProps) => {
    const { show, modalHandler } = props;
    return (
        <>
            {show && <div className="shadow" style={{ display: 'block' }}></div>}

            <Modal show={show} onHide={modalHandler} animation={false}>
                <div className="popup modal1">
                    {/* <button type="button" onClick={() => {closePopup()}} class="close">X</button> */}
                    {/* <button type="button" onClick={() => {closePopup()}} >X</button> */}
                    <button type="button" className={'close'} onClick={() => modalHandler()}>
                        X
                    </button>
                    <div className="popup-content">
                        <div
                            className="popup-content01 cursor-pointer"
                            onClick={() => window.open('https://www.instagram.com/piece_kr/', '_blank')}
                        >
                            <div className="popup-content01-img">
                                <div className="popup-content-circle">
                                    <img src={require('../../assets/images/common/footer_pop01.png')} />
                                </div>
                            </div>
                            <div className="popup-content-circle-title">피스 공식 계정</div>
                            <div className="popup-content-circle-text">피스의 최신 소식</div>
                            <div className="popup-content-circle-mail">@piece_kr</div>
                        </div>
                        <div
                            className="popup-content02 cursor-pointer"
                            onClick={() => window.open('https://www.instagram.com/piece_ticle/', '_blank')}
                        >
                            <div className="popup-content01-img">
                                <div className="popup-content-circle">
                                    <img src={require('../../assets/images/common/footer_pop02.png')} />
                                </div>
                            </div>
                            <div className="popup-content-circle-title">피스 티클</div>
                            <div className="popup-content-circle-text">트렌드∙패션∙라이프스타일∙경제</div>
                            <div className="popup-content-circle-mail">@piece_ticle</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
interface EmailModalProps {
    emailModalShow: boolean;
    emailModalHandler: () => void;
}

const EmailModal = (props: EmailModalProps) => {
    const { emailModalShow, emailModalHandler } = props;
    return (
        <>
            {emailModalShow && <div className="shadow" style={{ display: 'block' }}></div>}

            <Modal show={emailModalShow} onHide={emailModalHandler} animation={false}>
                <div className="popup modal1">
                    {/* <button type="button" onClick={() => {closePopup()}} class="close">X</button> */}
                    {/* <button type="button" onClick={() => {closePopup()}} >X</button> */}
                    <button type="button" className={'close'} onClick={() => emailModalHandler()}>
                        X
                    </button>
                    <div className="popup-content question-modal-content">
                        <img src={require('../../assets/images/main/question.png')} />
                        <p className="help-text">무엇을 도와드릴까요?</p>
                        <p className="help-number">02-6737-8282</p>
                        <p className="help-time">
                            평일 09:00 ~ 18:00
                            <br />
                            점심시간 12:00 ~ 13:00
                            <br />
                            토/일 공휴일 휴무
                        </p>
                        <button className="help-kakao" onClick={() => window.open('http://pf.kakao.com/_XLxjmK')}>
                            문의하기
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Footer;
