import { Controls, PlayState, Timeline, Tween } from 'react-gsap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TBoard, useBoardsQuery } from '../../../generated/graphql';

import { PaginationProps } from '../../Paging/Paging';
import classNames from 'classnames';
import moment from 'moment';
import { useFetchBoards } from '../../../hooks/useFetchBoards';

interface NewsProps {
    section05Ref: React.MutableRefObject<any>;
}
function News(props: NewsProps) {
    const { section05Ref } = props;

    const [news, setNews] = useState<TBoard[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(0);

    const { data, fetchMore } = useBoardsQuery({
        variables: {
            boardCategory: 'BRT0102',
            boardType: 'BRT01',
            page: 1,
            pageSize: 6,
        },
    });
    const handlerPagination = async () => {
        if (data?.boards?.pageInfo?.listLength && data?.boards?.pageInfo?.totalLength) {
            if (data?.boards?.pageInfo?.listLength >= data?.boards?.pageInfo?.totalLength) {
                alert('마지막 페이지입니다.');
            } else {
                const moreData = await fetchMore({
                    variables: {
                        page: page + 1,
                        pageSize: 6,
                    },
                });
                console.log({ moreData });
                setNews([...news, ...moreData?.data?.boards?.boards]);
                setPage(page + 1);
                const lastPageNumber = Math.ceil(moreData?.data?.boards?.pageInfo?.totalLength / 6);
                if (lastPageNumber !== totalPage) {
                    setTotalPage(lastPageNumber);
                }
            }
        }
    };

    useEffect(() => {
        if (page === 1 && data?.boards?.boards && data?.boards?.boards?.length > 0) {
            setNews(data?.boards?.boards);
            const lastPageNumber = Math.ceil(data?.boards?.pageInfo?.totalLength / 6);
            setTotalPage(lastPageNumber);
        }
    }, [data]);

    // useEffect(() => {
    //     if (status === 'OK') {
    //         if (!newList) {
    //             setNewList(items);
    //         } else {
    //             setNewList([...newList, ...items]);
    //         }
    //     }
    //     const listLength = pageConfig?.listLength ? pageConfig?.listLength : 0;
    //     const totalCount = pageConfig?.totalLength ? pageConfig?.totalLength : 0;
    //     setPageProps((prevState: any) => ({ ...prevState, totalPage: listLength, totalLength: totalCount }));
    // }, [items]);

    return (
        <div className="section05" ref={section05Ref}>
            <div className="section-title">
                <div className="title-text">
                    <p className="text-p1">NEWS</p>
                </div>
            </div>
            <div className="section-content">
                <div className="bbs-list">
                    <ul>
                        {(news || []).map((item: TBoard) => {
                            return (
                                <li key={item?.board_id}>
                                    <div className="bbs-list-li" style={{ cursor: 'pointer' }}>
                                        <div className="bbs-list-li-cont">
                                            <div className="list-cont-info">
                                                <span className="text-writer text-p1">{item?.press}</span>
                                                <span className="text-date">
                                                    {moment(item?.created_at).format('YYYY.MM')}
                                                </span>
                                            </div>
                                            <div className="list-cont-text mt20">{item?.title}</div>
                                        </div>
                                        <div
                                            className="bbs-list-li-icon"
                                            onClick={() => window.open(item?.contents || '', '_blank')}
                                        ></div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {page < totalPage && (
                    <div className="bbs-btns">
                        <a
                            onClick={() => {
                                handlerPagination();
                            }}
                            className="cursor-pointer"
                        >
                            <span className="bbs-btn">기사 더보기</span>
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default News;
