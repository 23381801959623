import { Image } from '@chakra-ui/react';
import MOBILE01 from '../../../assets/images/newProcess/process_mo.png';
import WEB from '../../../assets/images/newProcess/process_PC.png';

function Process() {
    return (
        <>
            {/* <div className="sub-content-cont"> */}
            {/* <div className="sub-content-text">
                <div className="sp-br01" style={spBr01Style}>
                    힘을 합쳐 혼자서는 이루기 힘든 목표에 도전하고
                </div>
                <br />
                <div className="sp-br01">성과의 열매를 나눌 좋은 동료를 찾습니다.</div>
            </div> */}
            {/* <style>
                .sp-br01 { display: inline-block !important; }
                .sp-br02 { display: inline-block !important; }
                .wrap .sub .sub-content.recruit02 .sub-content-cont .sub-content-step .sub-content-step-img div{ width: 100%; text-align: center; color: #000;}
                @media all and (max-width: 768px) {
                    .sp-br01 { display: block !important; }

                }

            </style> */}
            <div className="sub-content recruit02" style={{ marginTop: '50px' }}>
                <Image src={WEB} w={'100%'} className="web" />
                <Image src={MOBILE01} w={'100%'} className="mobile" />
            </div>
            {/* </div> */}
        </>
    );
}

export default Process;
