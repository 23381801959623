import { Link } from 'react-router-dom';
import React from 'react';

interface CiBiProps {
    section06Ref: React.MutableRefObject<any>;
}
function CiBi(props: CiBiProps) {
    const { section06Ref } = props;
    const downloadFile = async (type: string) => {
        // const url = type === 'BI' ? 'https://cdn.piece.run/static/homev2/PIECE_BI.png' :  'https://cdn.piece.run/static/homev2/buysell_CI.png'
        const fileName = type === 'BI' ? 'PIECE_BI.png' : 'BUYSELLSTANDARDS_CI.png';
        // FileSaver.saveAs(url, fileName)

        const blob = new Blob(['../../../assets/images/common/footer_pop01.png'], { type: 'image/png' });
        console.log({ blob });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        // const url = type === 'BI' ? 'https://cdn.piece.run/static/homev2/PIECE_BI.png' :  'https://cdn.piece.run/static/homev2/buysell_CI.png'

        // fetch(url, { method: 'GET' })
        //     .then((res) => {
        //         return res.blob();
        //     })
        //     .then((blob) => {
        //         const url = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = fileName;
        //         document.body.appendChild(a);
        //         a.click();
        //         setTimeout(() => {
        //             window.URL.revokeObjectURL(url);
        //         }, 60000);
        //         a.remove();
        //         // setOpen(false);
        //     })
        //     .catch((err) => {
        //         console.error('err: ', err);
        //     });
    };

    return (
        <div className="section06" ref={section06Ref}>
            <div className="section-title">
                <div className="title-text">
                    <p className="text-p1">CI·BI</p>
                </div>
            </div>
            <div className="section-content">
                <div className="section-content-cont">
                    <span className="block">
                        회사 로고(CI)는 레드/블루 색상의 대비를 통해 금융 시장의 상승/하락 국면을 표현하고, 그 속에서도
                        투자의 원칙을 지키며 고객의 자산을 소중히 운용해 새로운 가치를 창출하겠다는 의지를 담고
                        있습니다.
                    </span>
                    <span>
                        브랜드 심벌(BI)은 국내 최초의 조각투자 서비스를 상징하는 민트 색상의 조각 이미지와 서비스 명칭을
                        조합해 형상화했으며, 조각투자 플랫폼으로서 국내 최초에 머물지 않고 세계의 표준이 되겠다는 의지를
                        담고 있습니다.
                    </span>
                </div>
                <div className="section-content-boxs mt50">
                    <div className="boxs">
                        <div className="boxs-title">바이셀스탠다드 로고</div>
                        <div className="boxs-logo">
                            <img src={require('../../../assets/images/main/ci-logo.png')} />
                        </div>
                        <div className="boxs-icon">
                            {/* <a onClick={() => {downloadFile('CI')}} className='cursorPointer'><img src={require("../../../assets/images/main/icon-download-m.png")} />다운로드</a> */}
                            {/* <a href={'https://cdn.piece.run/static/homev2/PIECE_BI.png'}>some link</a> */}
                            <Link to="/img/buysell_CI.png" target="_blank" download className="cursorPointer">
                                <img src={require('../../../assets/images/main/icon-download-m.png')} />
                                다운로드
                            </Link>
                        </div>
                    </div>
                    <div className="boxs-blank"></div>
                    <div className="boxs">
                        <div className="boxs-title">피스 로고</div>
                        <div className="boxs-logo">
                            <img
                                src={require('../../../assets/images/main/PIECE_BI.png')}
                                style={{ maxHeight: '50px' }}
                            />
                        </div>
                        <div className="boxs-icon">
                            <Link to="/img/PIECE_BI.png" target="_blank" download className="cursorPointer">
                                <img src={require('../../../assets/images/main/icon-download-m.png')} />
                                다운로드
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="section-content-cont-bottom mt20">
                    <span>
                        * CI, BI는 회사와 브랜드의 가치를 표현하는 중요한 역할을 하므로 형태 및 색상을 임의로 변경하여
                        사용할 수 없습니다.
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CiBi;
