import { Box, ChakraProvider, Flex, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import NewsSection from '../../components/NewsSection';
import PressSection from '../../components/PressSection';
import SendNewsLetter from '../../components/SendNewsLetter/SendNewsLetter';
import TopBoardsSection from '../../components/TopBoardsSection';

interface NewsItemType {
    boadrThumbnailPath: string;
    boardCategory: string;
    boardId: string;
    boardType: string;
    contents: string;
    createdAt: string;
    press: string;
    title: string;
}
const News = ({ match }: any) => {
    const { slug } = match.params;
    const scrollRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (slug === 'NEWS' || slug === 'ALL') {
            if (slug === 'NEWS') {
                setTimeout(() => {
                    if (scrollRef.current) {
                        scrollRef.current.scrollIntoView({
                            behavior: 'smooth', // 부드러운 스크롤 적용
                        });
                    }
                }, 500);
            }
            setBoardCategory('BRT0102');
        } else {
            setBoardCategory('BRT0103');
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView({
                        behavior: 'smooth', // 부드러운 스크롤 적용
                    });
                }
            }, 500);
        }
    }, [slug]);

    const [boardCategory, setBoardCategory] = useState<any>('BRT0102');

    return (
        <>
            <Flex w="100%" alignItems="center" justifyContent="center">
                <Flex w="100%" overflow={'hidden'} flexDir={'column'} className="pressList">
                    <Flex w="100%" alignItems="center" justifyContent="center">
                        <Flex w={'100%'} justifyContent="center" flexDir={'column'}>
                            <Flex w={'100%'} flexDirection={'column'}>
                                <Text className="name_press">PRESS</Text>
                                <Text
                                    className="press_title"
                                    // pt={'32px'}
                                >
                                    바이셀스탠다드가 만들어가는
                                    <br />
                                    새로운 이야기를 생생하게 전해 드립니다
                                </Text>
                            </Flex>
                            <TopBoardsSection />

                            <SendNewsLetter />
                            <Box ref={scrollRef}></Box>
                            <Flex mt={'120px'} w={'100%'} flexDir={'column'}>
                                <ChakraProvider>
                                    <Flex w={'100%'} borderBottom="none" mb={'24px'}>
                                        <Flex w={'100%'} borderBottom="none" px={['20px', '20px', '20px', 0]}>
                                            {/* <Link to={'/news/NEWS'}> */}
                                            <Box
                                                fontSize={['35px', '35px', '40px', '40px']}
                                                lineHeight={['52.5px', '52.5px', '60px', '60px']}
                                                fontWeight={700}
                                                letterSpacing="-0.03em"
                                                borderBottom="none"
                                                color={boardCategory === 'BRT0102' ? '#131313' : '#13131333'}
                                                p={0}
                                                cursor={'pointer'}
                                                onClick={() => {
                                                    setBoardCategory('BRT0102');
                                                }}
                                            >
                                                미디어 뉴스
                                            </Box>
                                            {/* </Link> */}
                                            {/* <Link to={'/news/PRESS'}> */}
                                            <Box
                                                fontSize={['35px', '35px', '40px', '40px']}
                                                lineHeight={['52.5px', '52.5px', '60px', '60px']}
                                                fontWeight={700}
                                                letterSpacing="-0.03em"
                                                color={boardCategory === 'BRT0103' ? '#131313' : '#13131333'}
                                                borderBottom="none"
                                                p={0}
                                                cursor={'pointer'}
                                                ml={['24px', '24px', '48px', '48px']}
                                                onClick={() => {
                                                    setBoardCategory('BRT0103');
                                                }}
                                            >
                                                보도자료
                                            </Box>
                                            {/* </Link> */}
                                        </Flex>
                                    </Flex>

                                    {boardCategory === 'BRT0102' && <NewsSection />}
                                    {boardCategory === 'BRT0103' && <PressSection />}

                                    <Flex pt={'24px'}></Flex>
                                </ChakraProvider>
                            </Flex>
                            <Flex h={'120px'}></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default News;
