import './App.css';
import './assets/css/common.css';
// import './assets/css/main.css';
import './assets/css/style.css';
import './assets/css/custom.css';
import './assets/css/font-awesome/css/font-awesome.css';
import './assets/css/font-awesome/css/font-awesome.min.css';
import './assets/scss/_right-sidebar.scss';
import './assets/fonts/pretendard/pretendard-subset.scss';
import './assets/fonts/pretendard/pretendard.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import Routes from './routes/Routes';

// import { ChakraProvider } from '@chakra-ui/react';
// import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => (
    // <ChakraProvider >
    <Routes />
    // </ChakraProvider>
);

export default App;
<link href="https://cdn.rawgit.com/michalsnik/aos/2.1.1/dist/aos.css" rel="stylesheet"></link>;
