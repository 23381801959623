import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import MainLayout from '../layouts/MainLayout';
import React from 'react';
import ScrollToTop from '../layouts/ScrollToTop';
import { client } from '../apollo';
import { homeRoutes } from './index';

interface RoutesProps {}

const Routes = (props: RoutesProps) => {
    const queryClient = new QueryClient();
    return (
        <BrowserRouter>
            <Switch>
                <ApolloProvider client={client}>
                    <QueryClientProvider client={queryClient}>
                        <Route path={homeRoutes.map((r: any) => r['path'])}>
                            <MainLayout {...props}>
                                <Switch>
                                    {homeRoutes.map((route: any, index: number) => {
                                        return (
                                            !route.children && (
                                                <route.route
                                                    key={index}
                                                    path={route.path}
                                                    roles={route.roles}
                                                    exact={route.exact}
                                                    component={route.component}
                                                />
                                            )
                                        );
                                    })}
                                </Switch>
                            </MainLayout>
                        </Route>
                    </QueryClientProvider>
                </ApolloProvider>
            </Switch>
            <ScrollToTop />
        </BrowserRouter>
    );
};

export default Routes;
