import React, { useEffect, useRef, useState } from 'react';

import Announcement from '../../components/Employment/Announcement/Announcement';
import { Link } from 'react-router-dom';
import { PaginationProps } from '../../components/Paging/Paging';
import Process from '../../components/Employment/Process/Process';
import Qna from '../../components/Employment/Qna/Qna';
import SubVisual01 from '../../assets/images/sub/sub-visual01.png';
import classNames from 'classnames';
import { useFetchEmployments } from '../../hooks/useFetchEmployment';

const subVisualStyle: any = {
    background: `url(${SubVisual01}) no-repeat center`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
const Employment = ({ match }: any) => {
    const { slug } = match.params;
    const [selectedTab, setSelectedTab] = useState<string>(
        !slug ? 'Announcement' : slug === 'NOTICE' ? 'Announcement' : slug === 'PROCESS' ? 'Process' : 'Qna'
    );
    const selectedTabHandler = (tab: string) => {
        setSelectedTab(tab);
    };

    useEffect(() => {
        if (slug === 'NOTICE') {
            setSelectedTab('Announcement');
        } else if (slug === 'PROCESS') {
            setSelectedTab('Process');
        } else {
            setSelectedTab('Qna');
        }
    }, [slug]);
    return (
        <>
            <div className="sub">
                <div className="sub-title">RECRUIT</div>
                <div className="sub-visual" style={subVisualStyle}>
                    <div className="sub-visual-text">날마다 성장하고 기록을 달성하는 여정에 함께하세요!</div>
                </div>
                <div
                    className={classNames(
                        'sub-content',
                        selectedTab === 'Announcement' && 'recruit01',
                        selectedTab === 'Process' && 'recruit02',
                        selectedTab === 'Qna' && 'recruit03'
                    )}
                >
                    <div className="sub-content-menu">
                        <ul>
                            <li
                                className={classNames('cursorPointer', selectedTab === 'Announcement' && 'active')}
                                onClick={() => {
                                    selectedTabHandler('Announcement');
                                }}
                            >
                                <Link to={'/employment/NOTICE'}>
                                    <span>NOTICE</span>
                                </Link>
                            </li>
                            <li
                                className={classNames('cursorPointer', selectedTab === 'Process' && 'active')}
                                onClick={() => {
                                    selectedTabHandler('Process');
                                }}
                            >
                                <Link to={'/employment/PROCESS'}>
                                    <span>PROCESS</span>
                                </Link>
                            </li>
                            <li
                                className={classNames('cursorPointer', selectedTab === 'Qna' && 'active')}
                                onClick={() => {
                                    selectedTabHandler('Qna');
                                }}
                            >
                                <Link to={'/employment/Q&A'}>
                                    <span>Q&A</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {selectedTab === 'Announcement' ? <Announcement /> : null}
                    {selectedTab === 'Process' ? <Process /> : null}
                    {selectedTab === 'Qna' ? <Qna /> : null}
                </div>
            </div>
        </>
    );
};

export default Employment;
