// constants
import { CommonActionTypes } from './constants';

export interface CommonActionType<T> {
  type:
    | CommonActionTypes.LOADDING
    | CommonActionTypes.CONFIRM_ALERT
    | CommonActionTypes.ALERT
    | CommonActionTypes.SHOW_RIGHT_SIDEBAR
    | CommonActionTypes.HIDE_RIGHT_SIDEBAR;
  payload?: T;
}

export interface CommonAlertProps {
    isAlert: boolean, alertClass: string, alertMessage: string, action?: () => void
}
export interface CommonConfirmProps {
    isCommonConfirm: boolean, confirmClass: string, contentsMessage: string, action: any, params:any
}
export const setLoadding = (isLoadding: boolean): CommonActionType<boolean> => ({
    type: CommonActionTypes.LOADDING,
    payload: isLoadding,
});
export const setAlert = (isAlert: boolean, alertClass: string, alertMessage: string, action?: () => void): CommonActionType<CommonAlertProps> => ({
        type: CommonActionTypes.ALERT,
        payload: action ? {isAlert, alertClass, alertMessage, action} : {isAlert, alertClass, alertMessage}
});
export const setConfirm = (isCommonConfirm: boolean, confirmClass: string, contentsMessage: string, action: any, params?: any): CommonActionType<CommonConfirmProps> => ({
    type: CommonActionTypes.CONFIRM_ALERT,
    payload: {isCommonConfirm, confirmClass, contentsMessage, action, params}
});

export const showRightSidebar = (): CommonActionType<null> => ({
  type: CommonActionTypes.SHOW_RIGHT_SIDEBAR,
});

export const hideRightSidebar = (): CommonActionType<null> => ({
  type: CommonActionTypes.HIDE_RIGHT_SIDEBAR,
});
