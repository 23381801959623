import "aos/dist/aos.css";

import * as yup from 'yup';

import { Alert, Button, Col, Row } from 'react-bootstrap';
import { Controls, PlayState, Timeline, Tween } from 'react-gsap';
import { Link, Redirect, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import AOS from "aos";
import CiBi from '../../components/Main/CiBi/CiBi';
import History from '../../components/Main/History/History';
import Investors from '../../components/Main/Investors/Investors';
import MainVideo from '../../components/Main/MainVideo/MainVideo';
import News from '../../components/Main/News/News';
import { PaginationProps } from '../../components/Paging/Paging';
import Partners from '../../components/Main/Partners/Partners';
import { TextPlugin } from 'gsap/TextPlugin';
import Vision from '../../components/Main/Vision/Vision';
import { gsap } from 'gsap';
import moment from "moment";
import { useFetchBoards } from '../../hooks/useFetchBoards';

const Main = () => {
    const mainRef = useRef<any>(null);
    const section01Ref = useRef<any>(null);
    const section02Ref = useRef<any>(null);
    const section03Ref = useRef<any>(null);
    const section04Ref = useRef<any>(null);
    const section05Ref = useRef<any>(null);
    const section06Ref = useRef<any>(null);
    const yearsRef = useRef<any>(null);
    const videoRef = useRef<any>(null);
    
    const [sectionSizes, setSectionSizes] = useState<any>()
    
    useEffect(() => {
        setSectionSizes({
            main: mainRef.current?.clientHeight,
            video: videoRef.current?.clientHeight,
            section01: section01Ref.current?.clientHeight,
            section02: section02Ref.current?.clientHeight,
            section03: section03Ref.current?.clientHeight,
            section04: section04Ref.current?.clientHeight,
            section05: section05Ref.current?.clientHeight,
            section06: section06Ref.current?.clientHeight,
        })
        
    }, [])

    gsap.registerPlugin(TextPlugin);
    useEffect(() => { AOS.init(); });

    const [scrollActive, setScrollActive] = useState(false);

    const handleFollow = () => {
        const section02 = section02Ref.current.getBoundingClientRect()
            setScrollActive(true);
        if(section02.top + 60 <= 0 && window.innerWidth > 1024 && (section02Ref.current.getBoundingClientRect().bottom - 60 - yearsRef.current.clientHeight) > 0) {  
            setScrollActive(true);
        } else {
            setScrollActive(false);
        }
    }

    useEffect(() => {
        const watch = () => {
            window.addEventListener('scroll', handleFollow);
        }
        watch(); // addEventListener 함수를 실행
        return () => {
        window.removeEventListener('scroll', handleFollow); // addEventListener 함수를 삭제
        }
    })


    return (
        <>
            <div className="main" ref={mainRef}>
                {/* <!-- 메인 상단 --> */}
                <MainVideo videoRef={videoRef} />
                {/* <!-- 메인 상단 --> */}
                <div className="main-content">
                    {/* <!-- VISION --> */}
                    <Vision section01Ref={section01Ref} />
                    {/* <!-- HISTORY --> */}
                    <History section02Ref={section02Ref} sectionSizes={sectionSizes} yearsRef={yearsRef} scrollActive={scrollActive} />
                    {/* <!-- INVESTORS --> */}
                    <Investors section03Ref={section03Ref} />
                    {/* <!-- PARTNERS --> */}
                    <Partners section04Ref={section04Ref} />
                    {/* <!-- NEWS --> */}
                    <News section05Ref={section05Ref} />
                    {/* <!-- CI·BI --> */}
                    <CiBi section06Ref={section06Ref} />
                </div>
            </div>
        </>
    );
};

export default Main;
