export const useDate = (__date: string) => {
  const dateObj = new Date(__date);

  const year = dateObj.getFullYear().toString();
  const _month = dateObj.getMonth() + 1;
  const month = _month < 10 ? '0' + _month : _month.toString();
  const _date = dateObj.getDate();
  const date = _date < 10 ? '0' + _date : _date.toString();
  return {
    year,
    month,
    date,
  };
};
