import { Box, Flex, Text } from '@chakra-ui/react';
import {
    CompanyHistory,
    GroupedHistory,
    GroupedHistoryDay,
    GroupedHistoryMonth,
    useCompanyHistorysQuery,
} from '../../../generated/graphql';
import { Controls, PlayState, Timeline, Tween } from 'react-gsap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ReturnSetHistoryData, defaultHistoryImg } from './historyPercent';

import classNames from 'classnames';
import { historyImgList } from './historyImg';

interface HistoryProps {
    section02Ref: React.MutableRefObject<any>;
    sectionSizes: any;
    yearsRef: React.MutableRefObject<any>;
    scrollActive: boolean;
}
function History(props: HistoryProps) {
    const { data } = useCompanyHistorysQuery();

    const [yearList, setYearList] = useState([]);
    useEffect(() => {
        if (data) {
            setHistoryList();
        }
    }, [data]);

    const setHistoryList = async () => {
        const yearItemList: any = [];
        await data?.companyHistorys?.companyHistorys?.map((item: any) => {
            yearItemList.unshift(item?.year);
        });
        setSelectedYear(yearItemList[0]);
        setYearList(yearItemList);
    };
    const [selectedYear, setSelectedYear] = useState<string>('');
    const selectedYearHandler = (year: string) => {
        setSelectedYear(year);
    };
    const { section02Ref, sectionSizes, yearsRef, scrollActive } = props;
    const [historyImgActive, setHistoryImgActive] = useState(defaultHistoryImg); // 스크롤값을 저장하기 위한 상태

    return (
        <div className="section02" ref={section02Ref}>
            <div className="section-title">
                <div className="title-text">
                    <p className="text-p1">HISTORY</p>
                </div>
            </div>
            <div className="section-content">
                <div className="history" id="history">
                    {/* <div style={{ width: '20%', display: !scrollActive ? 'none' : 'block' }}></div> */}
                    <div style={{ width: '20%', display: 'none' }}></div>
                    <div
                        // className={classNames('year', 'aos-init', {
                        //     'main-year-box-fixed': scrollActive,
                        // })}
                        className={classNames('year', 'aos-init')}
                        ref={yearsRef}
                        data-aos="fade-right"
                        data-aos-duration="1500"
                        data-aos-delay="300"
                    >
                        <ul>
                            {yearList?.map((item: string) => {
                                return (
                                    <div
                                        onClick={() => {
                                            selectedYearHandler(item);
                                        }}
                                        className="cursorPointer"
                                        key={item}
                                    >
                                        <Flex justifyContent={'space-between'}>
                                            <Timeline
                                                playState={
                                                    (historyImgActive.img01 && !historyImgActive.img03) ||
                                                    selectedYear === item
                                                        ? PlayState.restart
                                                        : PlayState.stop
                                                }
                                                target={
                                                    <li
                                                        className={classNames('li_year', 'li_2023', {
                                                            active:
                                                                (historyImgActive.img01 &&
                                                                    !historyImgActive.img03 &&
                                                                    !selectedYear) ||
                                                                selectedYear === item,
                                                        })}
                                                    >
                                                        {item}
                                                    </li>
                                                }
                                            >
                                                <Tween to={{ scaleX: 1.3, scaleY: 1.3 }} duration={0.8} />
                                                <Tween to={{ scaleX: 1, scaleY: 1 }} duration={0.3} />
                                            </Timeline>
                                            <Text style={{ paddingTop: '4px' }} as={'span'} className="history_icon">
                                                <Timeline
                                                    playState={
                                                        (historyImgActive.img04 && !selectedYear) ||
                                                        selectedYear === item
                                                            ? PlayState.play
                                                            : PlayState.stop
                                                    }
                                                    target={
                                                        <img
                                                            style={{ width: '28px', height: '28px' }}
                                                            src={
                                                                selectedYear === item
                                                                    ? historyImgList.img01
                                                                    : historyImgList.imgDisabled
                                                            }
                                                        />
                                                    }
                                                >
                                                    <Tween to={{ scaleX: 1.3, scaleY: 1.3 }} duration={0.4} />
                                                    <Tween to={{ scaleX: 1, scaleY: 1 }} duration={0.1} />
                                                </Timeline>
                                            </Text>
                                        </Flex>
                                    </div>
                                );
                            })}
                        </ul>
                        <div
                            style={{
                                background: '#e8e8f6',
                                width: '2px',
                                height: `${122 * (yearList?.length - 1)}px`,
                                position: 'absolute',
                                top: '23px',
                                right: '13px',
                                zIndex: '-1',
                            }}
                            className="history_icon"
                        ></div>
                    </div>
                    <div className="box aos-init" data-aos="fade-left" data-aos-duration="1500" data-aos-delay="600">
                        <ul id="first-list" style={{ background: 'none' }}>
                            {data?.companyHistorys?.companyHistorys?.map((item: any) => {
                                return (
                                    <>
                                        <div key={`${item?.year}`}>
                                            {item?.year?.toString() === selectedYear &&
                                                item?.months?.map((monthItem: GroupedHistoryMonth) => {
                                                    return (
                                                        <li id={item?.year} key={`${item?.year + monthItem?.month}`}>
                                                            <div className="info">
                                                                {monthItem?.days?.map((dayItem: GroupedHistoryDay) => {
                                                                    return (
                                                                        <div
                                                                            key={`${
                                                                                item?.year +
                                                                                monthItem?.month +
                                                                                dayItem?.day
                                                                            }`}
                                                                        >
                                                                            {dayItem?.histories?.map(
                                                                                (
                                                                                    companyHistoryItem: CompanyHistory
                                                                                ) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={`${companyHistoryItem?.history_id}`}
                                                                                        >
                                                                                            {
                                                                                                companyHistoryItem?.description
                                                                                            }
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div className="time">
                                                                <span>{monthItem?.month}월</span>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                        </div>
                                    </>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default History;
