import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useFetchBoards, useFetchQna } from '../../../hooks/useFetchBoards';
import { PaginationProps } from '../../Paging/Paging';

function Qna() {
    const [qnaList, setQnaList] = useState<any>();
    const openQnaHandler = (index: number) => {
        const list = qnaList;
        console.log(list[index]);
        list.map((item:any, idx:number) => {
            if (index !== idx) {
                item.active = false;
            } else {
                item.active = !list[index].active
            }
         

        })
        
        setQnaList([...list]);
    }




    const handlerPagination = () => {
        if (pageProps.page >= pageProps.totalLength!) {
            alert('마지막 페이지입니다.');
        }
        const nextPage = pageProps.page + 1;
        setPageProps((prevState) => ({...prevState, page: nextPage}));
    }
    const [pageProps, setPageProps] = useState<PaginationProps>({
        page: 1,
        pageSize: 10,
        totalPage: 0,
        totalLength: 0,
        handlerPagination
    });
    const {items, pageConfig, status} = useFetchBoards({
        page: pageProps.page, 
        pageSize: pageProps.pageSize, 
        boardType: 'BRT04', 
    })
    useEffect(() => {  
        if(status === 'OK') {
            if (!qnaList) {setQnaList(items)}
            else {setQnaList([...qnaList, ...items])}
            
        }
        const listLength = pageConfig?.listLength ? pageConfig?.listLength : 0;
        const totalCount = pageConfig?.totalLength ? pageConfig?.totalLength : 0;
        setPageProps((prevState: any) => ({...prevState, totalPage: listLength, totalLength: totalCount}))
    }, [items]);
    useEffect(() => {  
        console.log(pageProps);
        
    }, [pageProps]);



  return (
    <>
        <div className="sub-content-cont">
            <div className="qna">
                <div className="qnaHeader">
                    {/* <!--button type="button" className="showAll">답변 모두 여닫기</button--> */}
                </div>
                <ul className="qnaBody">
                    {
                        (qnaList || []).map((item: any, index: number) => {
                            return (
                                <li className="article" id="a1" key={item?.boardId}>
                                    <div className="q">
                                        <a onClick={()=> {openQnaHandler(index)}}>
                                            <div className="q-title">
                                                <div>{item?.title}</div>
                                                <div className={classNames('q-arrow', !item.active && 'active')}></div>
                                            </div>
                                        </a>
                                    </div>
                                    {
                                        item?.active &&
                                        <div className="a">
                                            {item?.contents}
                                        </div>
                                    }
                                    
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            {
                pageProps?.page < pageProps?.totalPage! &&
                    <div className="bbs-btns">
                        <a onClick={() => {handlerPagination()}} className="cursor-pointer">
                            <span className="bbs-btn">더보기</span>
                        </a>
                    </div>
            }
            
        </div>
    </>
  )
}
export default Qna;