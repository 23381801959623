import { APICore } from "./apiCore";


const api = new APICore();




const getBoardList = async ({ queryKey }: { queryKey: any }) => {
    const [, params] = queryKey;
    
    const baseUrl = '/board';
    let paramsData: any = {
      page: params.page,
      length: params.pageSize || 12,
      boardType: params.boardType,
      keyword: params.title || "",
    }; 
    console.log(params.pageSize);
    
    if (params?.boardCategory)
      paramsData.boardCategory = params.boardCategory;
      const { data }: { data: any } = await api.get(`${baseUrl}`, {
        params: paramsData,
      });

    return data;
};

//게시물 상세조회
const getBoardDetail = async ({ queryKey }: { queryKey: any }) => {
    const [, slug] = queryKey;      
        
      if (!slug?.id) return;

    const baseUrl = `/service/board/${slug?.id}`;
    
    const {data} : {data:any} = await api.get(`${baseUrl}`, {});
    
    return data;
};

//보도자료 상세조회
const getPressDetail = async ({ queryKey }: { queryKey: any }) => {
    const [, slug] = queryKey;      
        
      if (!slug?.id) return;

    const baseUrl = `/service/board/${slug?.id}`;
    
    const { data }: { data: any } = await api.get(`${baseUrl}`, {params: {
      boardCategory: "BRT0103",
    }});
    
    return data;
};

const getQnaList = async ({ queryKey }: { queryKey: any }) => {
    const [, params] = queryKey;
    
    const baseUrl = '/board/qna';
    let paramsData: any = {
      board_type: 'BRT03',
    }; 
    
    if (params?.board_category) paramsData.board_category = params.board_category;
      const { data }: { data: any } = await api.get(`${baseUrl}`, {
        params: paramsData,
      });

    return data;
};



export { getBoardList, getBoardDetail, getQnaList, getPressDetail };
