
interface MakeDotDotDotProps {
    length: number;
    text: string;

}

const MakeDotDotDot = (props: MakeDotDotDotProps) => {


    const { length, text } = props;
    const dotdotdot = text.substr(0, length) + '...';
    return text?.length > length ? dotdotdot : text;
    
}

export { MakeDotDotDot };