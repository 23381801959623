
import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Controls, PlayState, Timeline, Tween } from 'react-gsap';
import Vision01 from '../../../assets/images/main/main_Vision_1.svg'
interface VisionProps {
    section01Ref: React.MutableRefObject<any>;
}
function Vision(props : VisionProps) {
  const {section01Ref} = props;
  return (
    <div className="section01" ref={section01Ref}>
        <div className="section-title">
            <div className="title-text">
                <p className="text-p1">VISION</p>
            </div>
        </div>
        <div className="section-content">
            <div className='vision-content-list'>
                <div className="boxs-img" style={{height: '250px', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <img src={Vision01} style={{width: '214px'}} />
                </div>
                <div className="boxs aos-init" data-aos="fade-up" data-aos-duration="1500"  data-aos-delay="200">
                    <div className="boxs-title">FIRST MOVER</div>
                    
                    <div className="boxs-cont">
                        <p>퍼스트 무버 기업</p>
                        <span>국내 최초의 현물 조각투자 플랫폼 피스는 첫 포트폴리오 출시 이래 모든 포트폴리오의 연이은 완판을 기록하며 조각투자 업계의 ‘퍼스트 무버’ 기업으로 자리매김했습니다.</span>
                    </div>
                </div>
            </div>
            <div className='vision-content-list'>
                <div className="boxs-img" style={{height: '250px', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <img src={require("../../../assets/images/main/vision02.png")} style={{width: '186px'}} />
                </div>
                <div className="boxs aos-init" data-aos="fade-up" data-aos-duration="1500"  data-aos-delay="700">
                    <div className="boxs-title">STANDARD PIECE</div>
                    <div className="boxs-cont">
                        <p>조각투자의 표준 기업</p>
                        <span>조각투자 생태계의 성장을 이끈 피스는 출시 1년 만에 조각투자 산업의 표준으로 빠르게 성장했습니다. 바이셀스탠다드는 오늘도 조각투자 시장을 선도하며 새로운 투자 역사를 쓰고 있습니다.</span>
                    </div>
                </div>
            </div>
            <div className='vision-content-list'>
                <div className="boxs-img" style={{height: '250px', alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <img src={require("../../../assets/images/main/vision03.png")} style={{height: '238px'}} />
                </div>
                <div className="boxs aos-init" data-aos="fade-up" data-aos-duration="1500"  data-aos-delay="1200">
                    <div className="boxs-title">UNICORN PIECE</div>
                    <div className="boxs-cont">
                        <p>미래의 넥스트 유니콘</p>
                        <span>누구나 쉽고 안전하게 투자할 수 있는 피스는 소비와 금융을 잇는 유니크 에셋과 안정적이고 지속가능한 메가 에셋을 지속적으로 발굴하며 내일의 유니콘으로 성장하고 있습니다.</span>
                    </div>
                </div>
            </div>
            
            
            
        </div>
    </div>
  )
}


export default Vision;