import { Box, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import { PlayState, Timeline, Tween } from 'react-gsap';
import React, { useState } from 'react';

import DefaultThumbnail from '../../assets/images/news/default_thumbnail2.png';
import { Link } from 'react-router-dom';
import { useDate } from '../../hooks/useDate';

interface EventCardItemProps {
    type: string;
    item: {
        link: string;
        title: string;
        midTitle: string;
        smallTitle: string;
        thumbnail: string | null;
        createdAt: string;
    };
}

const EventCardItem = ({ type, item }: EventCardItemProps) => {
    const { link, thumbnail, title, midTitle, smallTitle, createdAt } = item;
    const { date, month, year } = useDate(createdAt);
    const color = useColorModeValue('blue.500', 'blue.300');
    const color2 = useColorModeValue('red.500', 'red.300');
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <Box
                className={`newsList-custom-item ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                pl={['0px !important', '0px !important', '0px !important', '2.5%']}
                pr={['0px !important', '0px !important', '0px !important', '2.5%']}
            >
                <Link to={link}>
                    <Box overflow={'hidden'} borderRadius={'10px'} w={['319px', '319px', '319px', '444px']}>
                        <Timeline
                            playState={isHovered ? PlayState.restart : PlayState.stop}
                            target={
                                <Image
                                    src={thumbnail ? thumbnail : DefaultThumbnail}
                                    // transform={isHovered ? 'scale(1.1)' : ''}
                                    w="100%"
                                />
                            }
                        >
                            <Tween to={{ scale: 1.05 }} duration={0.5} />
                        </Timeline>
                    </Box>

                    <div className="text-box">
                        {/* 제목 */}
                        <Box>
                            <Text
                                sx={{
                                    textDecoration: 'none',
                                    fontSize: '1em',
                                    position: 'relative',
                                    transition: 'all 0.6s',
                                    display: 'inline-block',
                                    whiteSpace: 'pre-wrap',

                                    // '&:before': {
                                    //     content: '""',
                                    //     width: !isHovered ? '0%' : '100%',
                                    //     height: '0.1em',
                                    //     textDecoration: 'underline',
                                    //     position: 'absolute',
                                    //     bottom: 0,
                                    //     left: 0,
                                    //     background: isHovered ? color2 : color,
                                    //     transition: 'all 0.3s',
                                    // },
                                    // color: { isHovered ? color2: ''}

                                    // '&:hover': {
                                    //     color: color2,
                                    //     '&:before': {
                                    //         width: '100%',
                                    //         left: 0,
                                    //         background: color2,
                                    //     },
                                    // },
                                }}
                            >
                                {title}
                            </Text>
                        </Box>

                        {/* 날짜 */}
                        <span>
                            {year}-{month}-{date}
                        </span>
                    </div>
                </Link>
            </Box>
        </>
    );
};

export default React.memo(EventCardItem);
