import { Box, Image } from '@chakra-ui/react';

import MOBILE01 from '../../assets/images/NewBenefits/piece benefit_mo_1.png';
import MOBILE02 from '../../assets/images/NewBenefits/piece benefit_mo_2.png';
import MOBILE03 from '../../assets/images/NewBenefits/piece benefit_mo_3.png';
import MOBILE04 from '../../assets/images/NewBenefits/piece benefit_mo_4.png';
import SubVisualWeb from '../../assets/images/sub/employee-benefits-img-web.png';
import WEB from '../../assets/images/NewBenefits/piece benefit_PC.png';

const EmployeeBenefits = () => {
    return (
        <>
            <div className="sub">
                <div className="sub-title">BENEFIT</div>
                <Box
                    className="sub-visual"
                    style={{
                        background: `url(${SubVisualWeb}) no-repeat center`,
                        backgroundSize: 'cover',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    // backgroundColor={'gray'}
                    // sx={{
                    //     filter: 'brightness(60%)', // 40% 어둡게
                    // }}
                >
                    <Box className="sub-visual-text">오직, 피스에서만 누릴 수 있는 복지와 문화를 함께해요!</Box>
                </Box>
                <div className="sub-content welfare">
                    <div className="sub-content-cont">
                        <Image src={WEB} w={'100%'} className="web" />
                        <Image src={MOBILE01} w={'100%'} className="mobile" />
                        <Image src={MOBILE02} w={'100%'} className="mobile" />
                        <Image src={MOBILE03} w={'100%'} className="mobile" />
                        <Image src={MOBILE04} w={'100%'} className="mobile" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeeBenefits;
