import Pagination, { PaginationProps } from '../../Paging/Paging';
import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { useFetchEmployments } from '../../../hooks/useFetchEmployment';

function Announcement() {

    const [currentTap, setCurrentTap] = useState<'ALL' | 'JOB0101' | 'JOB0102' | 'JOB0103' | 'JOB0104' | 'JOB0105'>('ALL');
    const handlerPagination = (page: number) => {
        setPageProps((prevState) => ({...prevState, page}));
    }
    const [totalEmployment, setTotalEmployment] = useState<number>(0);
    const [employmentList, setEmploymentList] = useState<any[] | []>([]);
    const [pageProps, setPageProps] = useState<PaginationProps>({
        page: 1,
        pageSize: 15,
        totalPage: 0,
        handlerPagination
    });
    const {items, pageConfig} = useFetchEmployments({
        page: pageProps.page, 
        pageSize: pageProps.pageSize, 
        jobType: currentTap
    })
    // const items: any[] = [];
    useEffect(() => {  
        if(items) {
            const listLength = pageConfig?.listLength ? pageConfig?.listLength : 0;
            setTotalEmployment(pageConfig?.totalCount || 0)
            setPageProps({...pageProps, totalPage: listLength})    
            setEmploymentList([...items])
        }
        
    }, [items])
    const moveToMenu = (url: string) => {window.location.href = url;}
    const currentTapHandler = (tap: 'ALL' | 'JOB0101' | 'JOB0102' | 'JOB0103' | 'JOB0104' | 'JOB0105') => {
        setCurrentTap(tap);
    }
  return (
    <>
      <div className="sub-content-cont">
        <div className="tab-content-cnt">
          <span>{totalEmployment}</span>건의 채용 공고가 있습니다.
        </div>
        <ul className="tabs-custom">
          <li
            className={classNames(
              "tab-link",
              currentTap === "ALL" && "current"
            )}
            onClick={() => {
              currentTapHandler("ALL");
            }}
          >
            전체
          </li>
          <li
            className={classNames(
              "tab-link",
              currentTap === "JOB0101" && "current"
            )}
            onClick={() => {
              currentTapHandler("JOB0101");
            }}
          >
            콘텐츠
          </li>
          <li
            className={classNames(
              "tab-link",
              currentTap === "JOB0102" && "current"
            )}
            onClick={() => {
              currentTapHandler("JOB0102");
            }}
          >
            기획 운영
          </li>
          <li
            className={classNames(
              "tab-link",
              currentTap === "JOB0103" && "current"
            )}
            onClick={() => {
              currentTapHandler("JOB0103");
            }}
          >
            IT
          </li>
          <li
            className={classNames(
              "tab-link",
              currentTap === "JOB0104" && "current"
            )}
            onClick={() => {
              currentTapHandler("JOB0104");
            }}
          >
            디자인
          </li>
          <li
            className={classNames(
              "tab-link",
              currentTap === "JOB0105" && "current"
            )}
            onClick={() => {
              currentTapHandler("JOB0105");
            }}
          >
            금융
          </li>
        </ul>
        <div
          id="tab-1"
          className="tab-content current"
          style={{ marginTop: "30px" }}
        >
          <div className="bbs-list bbs-list-web">
            <ul>
              <li className="bbs-list-ul-header">
                <ul>
                  <li
                    style={{
                      textAlign: "center",
                      width: `calc(((100%/7)/10)*7)`,
                    }}
                  >
                    경력
                  </li>
                  <li style={{ width: `calc(100%/7)` }}>부서</li>
                  <li style={{ width: `calc(100%/7 + ((100%/7)/10)*4)` }}>
                    포지션
                  </li>
                  <li style={{ width: `calc(100%/7 + ((100%/7)/10)*2)` }}>
                    직위
                  </li>
                  <li style={{ width: `calc(((100%/7)/10)*7)` }}>직책</li>
                  <li style={{ width: `calc(100%/7 + ((100%/7)/10)*2)` }}>
                    접수마감일
                  </li>
                  <li style={{ width: `calc(((100%/7)/10)*7)` }}>진행 상황</li>
                </ul>
              </li>
              {(employmentList || []).map((item: any, index: number) => {
                return (
                  <li className="bbs-list-ul-content" key={item?.postingId}>
                    <a
                      onClick={() => {
                        window.open(
                          item.contents,
                          "_blank",
                          "noopener, noreferrer"
                        );
                      }}
                    >
                      <ul>
                        <li
                          style={{
                            textAlign: "center",
                            width: `calc(((100%/7)/10)*7)`,
                          }}
                        >
                          {item?.careerDvnName}
                        </li>
                        <li style={{ width: `calc(100%/7)` }}>
                          {item?.deptDvn}
                        </li>
                        <li style={{ width: `calc(100%/7 + ((100%/7)/10)*4)` }}>
                          {item?.workDvn}
                        </li>
                        <li style={{ width: `calc(100%/7 + ((100%/7)/10)*2)` }}>
                          {item?.minSpotDvnName !== item?.maxSpotDvnName
                            ? item?.minSpotDvnName + "~" + item?.maxSpotDvnName
                            : item?.minSpotDvnName}
                          {/* {item?.maxSpotDvnName 
                            ? item?.minSpotDvnName + "~" + item?.maxSpotDvnName
                            : item?.minSpotDvnName} */}
                        </li>
                        <li style={{ width: `calc(((100%/7)/10)*7)` }}>
                          {item?.positionDvnName}
                        </li>
                        <li style={{ width: `calc(100%/7 + ((100%/7)/10)*2)` }}>
                          {item?.postingEndDate
                            ? moment(item?.postingEndDate).format("YYYY.MM.DD")
                            : "채용시 마감"}
                        </li>
                        <li style={{ width: `calc(((100%/7)/10)*8)` }}>
                          {item?.statusName}
                        </li>
                      </ul>
                    </a>
                  </li>
                );
              })}
              contents
            </ul>
          </div>

          <div className="bbs-list bbs-list-mobile">
            {(employmentList || []).map((item: any, index: number) => {
              console.log(item);

              return (
                <div
                  className="bbs-list-ul-content-mobile"
                  key={item?.postingId}
                >
                  <div
                    className="bbs-list-ul-content-mobile1"
                    onClick={() => {
                      moveToMenu(`/employmentDetail/${item.postingId}`);
                    }}
                  >
                    <div className="bbs-list-careerTypeName-mobile">
                      {item?.careerDvnName} |{" "}
                      {item?.minSpotDvnName !== item?.maxSpotDvnName
                        ? item?.minSpotDvnName + "~" + item?.maxSpotDvnName
                        : item?.minSpotDvnName}{" "}
                      | {item?.positionDvnName}
                    </div>
                    <div className="bbs-list-jobCategoryName-mobile">
                      [{item?.deptDvn}] {item?.workDvn}
                    </div>
                  </div>
                  <div className="bbs-list-ul-content-mobile2">
                    <div className="bbs-list-postingEndDate-mobile">
                      {item?.postingEndDate
                        ? moment(item?.postingEndDate).format("YYYY.MM.DD")
                        : "채용시 마감"}
                    </div>
                    <div className="bbs-list-statusName-mobile">
                      {item?.statusName}
                    </div>
                  </div>
                </div>
                // <li className="bbs-list-ul-content" key={item?.postingId}>
                //     <Link to={`/employmentDetail/${item.postingId}`}>
                //         <ul>
                //             <li>{item?.careerTypeName}</li>
                //             <li>{item?.jobCategoryName}</li>
                //             <li>{moment(item?.postingEndDate).format('YYYY.MM.DD')}</li>
                //             <li>{item?.statusName}</li>
                //         </ul>
                //     </Link>
                // </li>
              );
            })}
            {/* <ul>
                        <li className="bbs-list-ul-header">
                            <ul>
                                <li>구분</li>
                                <li>포지션</li>
                                <li>접수마감일</li>
                                <li>진행 상황</li>
                            </ul>
                        </li>
                        {
                            (employmentList || []).map((item: any, index: number) => {
                                return (
                                    <li className="bbs-list-ul-content" key={item?.postingId}>
                                        <Link to={`/employmentDetail/${item.postingId}`}>
                                            <ul>
                                                <li>{item?.careerTypeName}</li>
                                                <li>{item?.jobCategoryName}</li>
                                                <li>{moment(item?.postingEndDate).format('YYYY.MM.DD')}</li>
                                                <li>{item?.statusName}</li>
                                            </ul>
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul> */}
          </div>
        </div>
        <div className="tab-content-paging">
          <Pagination
            page={pageProps?.page}
            pageSize={pageProps?.pageSize}
            totalPage={pageProps?.totalPage}
            totalCount={pageProps?.totalCount}
            handlerPagination={pageProps?.handlerPagination}
          />
        </div>
      </div>
    </>
  );
}
export default Announcement;