import { Box, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { PlayState, Timeline, Tween } from 'react-gsap';
import React, { useEffect, useRef, useState } from 'react';
import { TBoard, YearInfo, useBoardYearsQuery, useBoardsQuery } from '../../generated/graphql';

import HoverTop from '../../assets/images/news/hoverTop.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import NewsCardItem from '../NewsCardItem';
import NewsSelect from '../../assets/images/news/newsSelect.svg';
import NewsSelectChecked from '../../assets/images/news/newsSelectChecked.svg';
import NewsSort from '../../assets/images/news/newsSort.svg';
import PaginationNews from '../PagingNews/Paging';
import PressCheck from '../../assets/images/news/pressCheck.svg';
import { isMobile } from 'react-device-detect';

interface NewsProps {}

const NewsSection = () => {
    const [items, setItems] = useState<any>([]);
    const [sort, setSort] = useState<string>('');
    const [selectedYear, setSelectedYear] = useState<string>('');
    const [hasMore, setHasMore] = useState(true);

    const setSelectedYearHandlar = (year: string) => {
        setSelectedYear(year);
        setHasMore(true);
        setPageInfo({ ...pageInfo, page: 1 });
        setSelectedYear(year);
        // refetch({
        //     page: 1,
        //     selectYear: year,
        //     sortType: sort,
        // });
    };

    const [pageInfo, setPageInfo] = useState<any>({
        page: 1,
        handlerPagination: (page: number) => {
            console.log(page);

            setPageInfo({ ...pageInfo, page, selectYear: yearsList, sortType: sort });
            // refetch({
            //     page: page,
            //     selectYear: selectedYear || '',
            //     sortType: sort || '',
            // });
        },
        selectedYear: selectedYear,
        sortType: sort,
    });

    useEffect(() => {
        console.log({ pageInfo });
    }, [pageInfo]);
    const { data: yearsList } = useBoardYearsQuery({
        variables: {
            boardCategory: 'BRT0102',
            boardType: 'BRT01',
        },
    });

    const setSortHandlar = (sortType: '' | 'COUNT') => {
        setHasMore(true);
        setSort(sortType);

        // refetch({
        //     page: 1,
        //     selectYear: selectedYear,
        //     sortType,
        // });
        // console.log({ selectedYear });
        // setPageInfo({ ...pageInfo, page: 1 });
        setPageInfo({
            ...pageInfo,
            page: 1,
            selectedYear,
            sortType,
        });
    };

    const content1Ref = useRef<HTMLDivElement>(null);
    const scrollToDiv = () => {
        content1Ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const { data, loading, refetch } = useBoardsQuery({
        variables: {
            page: pageInfo?.page,
            pageSize: !isMobile ? 10 : 12,
            boardCategory: 'BRT0102',
            boardType: 'BRT01',
            selectYear: selectedYear,
            sortType: sort,
        },
    });

    const fetchMoreData = async () => {
        if (loading || !hasMore) return;
        let pageSize = 0;
        let totalList = 0;
        if (data?.boards?.pageInfo?.totalLength) {
            totalList = data?.boards?.pageInfo?.totalLength;
        }
        if (data?.boards?.pageInfo?.listLength) {
            pageSize = data?.boards?.pageInfo?.listLength;
        }

        if (data?.boards?.pageInfo?.curPage === Math.ceil(totalList / pageSize)) {
            setHasMore(false);
            return;
        }
        try {
            // fetchMore를 사용하여 새로운 데이터를 불러오는 로직을 작성
            await refetch({
                page: (data?.boards?.pageInfo?.curPage || 0) + 1,
                boardCategory: 'BRT0102',
                selectYear: selectedYear || '',
            });
        } catch (error) {
            console.error('Error fetching more data:', error);
        }
        // 새로운 데이터를 불러오는 로직을 작성

        // setPageProps({
        //     ...pageProps,
        //     page: pageProps?.page + 1,
        //     pageSize: (pageProps?.page + 1) * pageProps?.pageSize,
        // });
    };

    useEffect(() => {
        if (isMobile) {
            if (data?.boards?.boards && data?.boards?.boards?.length > 0) {
                if (data?.boards?.pageInfo?.curPage === 1) {
                    setItems([...data.boards.boards]);
                } else {
                    if (items?.length > 0) {
                        setItems([...items, ...data.boards.boards]);
                    } else {
                        setItems([...data.boards.boards]);
                    }
                }
            } else {
                setItems([]);
            }
        }
    }, [data]);

    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;
        const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop || 0;
        const divStartPosition = scrollHeight - 1811 - 450;

        if (scrollHeight - scrollPosition <= windowHeight + 450) {
            setShowButton(false); // 페이지 하단에 도달하면 버튼 감춤
        } else if (scrollPosition <= divStartPosition) {
            setShowButton(false); // 페이지 하단에 도달하면 버튼 감춤
        } else {
            setShowButton(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [hoverBoardId, setHoverBoardId] = useState<string | null>(null);
    const handleMouseEnter = (boardId: string) => {
        setHoverBoardId(boardId);
    };

    const handleMouseLeave = () => {
        setHoverBoardId(null);
    };

    return (
        <>
            <Flex flexDir={'column'}>
                <Flex
                    mb={'20px'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    w={'100%'}
                    display={['none', 'none', 'flex']}
                >
                    <Flex>
                        <Flex
                            w={'74px'}
                            h={'44px'}
                            borderRadius={'44px'}
                            p={'0px, 20px, 0px, 20px'}
                            backgroundColor={selectedYear === '' ? '#131313' : '#EAECF0'}
                            color={selectedYear === '' ? '#ffffff' : '#131313'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            fontSize={'20px'}
                            fontWeight={'500'}
                            lineHeight={'30px'}
                            letterSpacing="-0.03em"
                            cursor={'pointer'}
                            onClick={() => {
                                setSelectedYearHandlar('');
                            }}
                        >
                            전체
                        </Flex>
                        {yearsList?.boardYears?.searchYearList?.map((item: YearInfo) => {
                            return (
                                <>
                                    {item?.year && (
                                        <Flex
                                            key={item?.year.toString()}
                                            w={'87px'}
                                            h={'44px'}
                                            borderRadius={'44px'}
                                            p={'0px, 20px, 0px, 20px'}
                                            backgroundColor={
                                                selectedYear === item?.year.toString() ? '#131313' : '#EAECF0'
                                            }
                                            color={selectedYear === item?.year.toString() ? '#ffffff' : '#131313'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            fontSize={'20px'}
                                            fontWeight={'500'}
                                            lineHeight={'30px'}
                                            letterSpacing="-0.03em"
                                            ml={'16px'}
                                            cursor={'pointer'}
                                            onClick={() => {
                                                setSelectedYearHandlar(item?.year.toString());
                                            }}
                                        >
                                            {item?.year.toString()}
                                        </Flex>
                                    )}
                                </>
                            );
                        })}
                    </Flex>
                    <Flex alignItems={'center'}>
                        <Flex
                            ml={'24px'}
                            cursor={sort !== '' ? 'pointer' : ''}
                            onClick={() => {
                                if (sort !== '') {
                                    setSortHandlar('');
                                }
                            }}
                        >
                            <Image src={PressCheck} mr={'9.5px'} w={'12px'} opacity={sort === '' ? 1 : 0.2} />
                            <Text
                                letterSpacing="-0.03em"
                                fontSize={'18px'}
                                lineHeight={'27px'}
                                fontWeight={500}
                                opacity={sort === '' ? 1 : 0.2}
                            >
                                최신 순
                            </Text>
                        </Flex>
                        <Flex
                            ml={'24px'}
                            cursor={sort !== 'COUNT' ? 'pointer' : ''}
                            onClick={() => {
                                if (sort !== 'COUNT') {
                                    setSortHandlar('COUNT');
                                }
                            }}
                        >
                            <Image src={PressCheck} mr={'9.5px'} w={'12px'} opacity={sort === 'COUNT' ? 1 : 0.2} />
                            <Text
                                letterSpacing="-0.03em"
                                fontSize={'18px'}
                                lineHeight={'27px'}
                                fontWeight={500}
                                opacity={sort === 'COUNT' ? 1 : 0.2}
                                onClick={() => {
                                    if (sort !== 'COUNT') {
                                        setSortHandlar('COUNT');
                                    }
                                }}
                            >
                                조회수 순
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    mb={'20px'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    w={'100%'}
                    display={['flex', 'flex', 'none']}
                    px={['20px', '20px', '20px', 0]}
                >
                    <Flex>
                        <Menu>
                            <MenuButton
                                w={'93px'}
                                h={'40px'}
                                borderRadius={'44px'}
                                p={'0px 16px'}
                                backgroundColor={'#131313'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                _hover={{ bg: 'gray.400' }}
                                // _expanded={{ bg: 'blue.400' }}
                                // _focus={{ boxShadow: 'outline' }}
                            >
                                <Flex justifyContent={'space-around'}>
                                    <Text
                                        fontSize={'16px'}
                                        fontWeight={'500'}
                                        lineHeight={'24px'}
                                        letterSpacing="-0.03em"
                                        color={'#ffffff'}
                                    >
                                        {selectedYear === '' ? '연도별' : selectedYear}
                                    </Text>

                                    <Image src={NewsSelect || ''} w={'16px'} />
                                </Flex>
                            </MenuButton>
                            <MenuList w={'170px'} py={0}>
                                <MenuItem
                                    value={''}
                                    h={'44.5px'}
                                    onClick={() => setSelectedYearHandlar('')}
                                    // _hover={{ bg: 'gray.600' }} // 마우스 호버 시 배경색 변경
                                >
                                    <Flex>
                                        {selectedYear === '' && (
                                            <Image
                                                src={NewsSelectChecked}
                                                // w={'24px'}
                                                // h={'44px'}
                                            />
                                        )}

                                        <Text ml={selectedYear !== '' ? '24px' : '8px'}>전체</Text>
                                    </Flex>
                                </MenuItem>
                                {yearsList?.boardYears?.searchYearList?.map(item => (
                                    <MenuItem
                                        key={item?.year.toString()}
                                        borderTop={'1px solid #11111140'}
                                        value={item?.year.toString()}
                                        h={'44.5px'}
                                        onClick={() => setSelectedYearHandlar(item?.year.toString())}

                                        // _hover={{ bg: 'gray.600' }} // 마우스 호버 시 배경색 변경
                                    >
                                        <Flex>
                                            {selectedYear === item?.year.toString() && (
                                                <Image
                                                    src={NewsSelectChecked}
                                                    // w={'24px'}
                                                    // h={'44px'}
                                                />
                                            )}
                                            <Text ml={selectedYear !== item?.year.toString() ? '24px' : '8px'}>
                                                {item?.year.toString()}
                                            </Text>
                                        </Flex>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                        <Menu>
                            <MenuButton
                                w={sort === 'COUNT' ? '120px' : '93px'}
                                h={'40px'}
                                ml={'16px'}
                                borderRadius={'44px'}
                                p={'0px, 16px'}
                                backgroundColor={'#ffffff'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                border={'1.5px solid #131313'}
                                cursor={'pointer'}
                                // _expanded={{ bg: 'blue.400' }}
                                // _focus={{ boxShadow: 'outline' }}
                            >
                                <Flex justifyContent={'space-around'} p={'0px, 16px'}>
                                    <Image src={NewsSort || ''} pl={'16px'} />
                                    <Text
                                        fontSize={'16px'}
                                        fontWeight={'500'}
                                        lineHeight={'24px'}
                                        letterSpacing="-0.03em"
                                        color={'#131313'}
                                        pr={'16px'}
                                    >
                                        {sort === '' ? '최신순' : '조회수순'}
                                    </Text>
                                </Flex>
                            </MenuButton>
                            <MenuList w={'170px'} py={0}>
                                <MenuItem
                                    value={''}
                                    h={'44.5px'}
                                    onClick={() => setSortHandlar('')}
                                    // _hover={{ bg: 'gray.600' }} // 마우스 호버 시 배경색 변경
                                >
                                    <Flex>
                                        {sort === '' && (
                                            <Image
                                                src={NewsSelectChecked}
                                                // w={'24px'}
                                                // h={'44px'}
                                            />
                                        )}

                                        <Text ml={sort !== '' ? '24px' : '8px'}>최신순</Text>
                                    </Flex>
                                </MenuItem>
                                <MenuItem
                                    value={''}
                                    h={'44.5px'}
                                    onClick={() => setSortHandlar('COUNT')}
                                    // _hover={{ bg: 'gray.600' }} // 마우스 호버 시 배경색 변경
                                >
                                    <Flex>
                                        {sort === 'COUNT' && (
                                            <Image
                                                src={NewsSelectChecked}
                                                // w={'24px'}
                                                // h={'44px'}
                                            />
                                        )}

                                        <Text ml={sort !== 'COUNT' ? '24px' : '8px'}>조회수순</Text>
                                    </Flex>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        {/* <Flex
                                                                w={'93px'}
                                                                h={'40px'}
                                                                ml={'16px'}
                                                                borderRadius={'44px'}
                                                                p={'0px, 16px, 0px, 16px'}
                                                                backgroundColor={'#ffffff'}
                                                                alignItems={'center'}
                                                                justifyContent={'center'}
                                                                fontSize={'16px'}
                                                                color={'#131313'}
                                                                border={'1.5px solid #131313'}
                                                                fontWeight={'500'}
                                                                lineHeight={'24px'}
                                                                letterSpacing="-0.03em"
                                                                cursor={'pointer'}
                                                                onClick={() => {
                                                                    setSelectedYearHandlar('');
                                                                }}
                                                            >
                                                                <Image src={NewsSort || ''} mr={'4px'} />
                                                                최신순
                                                            </Flex> */}
                    </Flex>
                </Flex>
                {!isMobile && (
                    <Flex flexDirection={'column'}>
                        <Flex w={'100%'} display={'inline-block'}>
                            <Flex w={'100%'} flexDir={'column'}>
                                <Flex w={'100%'} flexDir={'column'} minH={['1200px', '1200px', '1730px', '1730px']}>
                                    {data?.boards?.boards?.map((item: TBoard, index: number) => {
                                        return (
                                            <Box
                                                key={index}
                                                onMouseEnter={() => {
                                                    handleMouseEnter(item?.board_id);
                                                }}
                                                onMouseLeave={() => {
                                                    handleMouseLeave();
                                                }}
                                            >
                                                <Timeline
                                                    playState={
                                                        hoverBoardId === item?.board_id
                                                            ? PlayState.restart
                                                            : PlayState.stop
                                                    }
                                                    target={
                                                        <Box px={['20px', '20px', '20px', 0]}>
                                                            {item?.board_category === 'BRT0102' && (
                                                                <Box
                                                                    onClick={() => {
                                                                        window.open(item?.contents || '', '_blank');
                                                                    }}
                                                                >
                                                                    <NewsCardItem item={item} />
                                                                </Box>
                                                            )}
                                                            {item?.board_category === 'BRT0103' && (
                                                                <Link to={`/newsDetail/${item?.board_id}`}>
                                                                    <NewsCardItem item={item} />
                                                                </Link>
                                                            )}
                                                        </Box>
                                                    }
                                                >
                                                    <Tween
                                                        from={{ x: 0 }}
                                                        to={{
                                                            x: 10,
                                                            backgroundColor: '#F2F3F4',
                                                            paddingInlineStart: '24px',
                                                            paddingInlineEnd: '24px',
                                                            borderRadius: '16px',
                                                        }}
                                                        duration={0.3}
                                                    />
                                                </Timeline>
                                            </Box>
                                        );
                                    })}
                                </Flex>

                                <Flex mt={'2rem'}>
                                    {data?.boards.pageInfo?.totalLength &&
                                        Math.ceil(data?.boards.pageInfo?.totalLength / 10) > 1 && (
                                            <PaginationNews
                                                page={pageInfo?.page}
                                                pageSize={10}
                                                totalPage={Math.ceil(data?.boards.pageInfo?.totalLength / 10)}
                                                handlerPagination={pageInfo?.handlerPagination}
                                            />
                                        )}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                )}
                {isMobile && (
                    <Flex overflow={'auto'} id={'listDiv'} h={['', '', '1211px']} flexDirection={'column'}>
                        <Flex w={'100%'} display={'inline-block'}>
                            <Flex w={'100%'} flexDir={'column'} ref={content1Ref}>
                                {data?.boards?.boards && data?.boards?.boards?.length > 0 && (
                                    <InfiniteScroll
                                        dataLength={items?.length}
                                        next={fetchMoreData}
                                        hasMore={!loading && window.innerWidth <= 767}
                                        loader={''}
                                        // loader={<h4>Loading...</h4>}
                                    >
                                        {items?.map((item: TBoard, index: number) => {
                                            return (
                                                <Box
                                                    key={index}
                                                    onMouseEnter={() => {
                                                        handleMouseEnter(item?.board_id);
                                                    }}
                                                    onMouseLeave={() => {
                                                        handleMouseLeave();
                                                    }}
                                                >
                                                    <Box px={['20px', '20px', '20px', 0]}>
                                                        {item?.board_category === 'BRT0102' && (
                                                            <Box
                                                                onClick={() => {
                                                                    window.open(item?.contents || '', '_blank');
                                                                }}
                                                            >
                                                                <NewsCardItem item={item} />
                                                            </Box>
                                                        )}
                                                        {item?.board_category === 'BRT0103' && (
                                                            <Link to={`/newsDetail/${item?.board_id}`}>
                                                                <NewsCardItem item={item} />
                                                            </Link>
                                                        )}
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </InfiniteScroll>
                                )}
                                {/* <Flex
                                                                    position={'absolute'}
                                                                    justifyContent={'center'}
                                                                    alignItems={'center'}
                                                                    // position={'fixed'}
                                                                    w={'48px'}
                                                                    h={'48px'}
                                                                    borderRadius={'48px'}
                                                                    bottom={'-100px'}
                                                                    backgroundColor={'#FFFFFF'}
                                                                    opacity={0.5}
                                                                    _hover={{
                                                                        opacity: 1,
                                                                    }}
                                                                    boxShadow={'0px 4px 8px 0px #00000029'}
                                                                    cursor={'pointer'}
                                                                    right={0}
                                                                    onClick={scrollToDiv}
                                                                >
                                                                    <Image src={HoverTop} h={'24px'} />
                                                                </Flex> */}
                            </Flex>
                            {/* {displayButton && ( */}

                            {/* )} */}
                        </Flex>
                        <Flex
                            position={'fixed'}
                            right={['20px', '20px', '50px', '50px', '50px', '200px']}
                            justifyContent={'end'}
                            p={'15px'}
                            bottom={'20px'}
                            // display={['none', 'none', 'block']}
                            display={['none', showButton ? 'block' : 'none', showButton ? 'block' : 'none']}
                        >
                            <Flex
                                justifyContent={'center'}
                                alignItems={'center'}
                                // position={'fixed'}
                                w={'48px'}
                                h={'48px'}
                                borderRadius={'48px'}
                                bottom={'-100px'}
                                backgroundColor={'#FFFFFF'}
                                opacity={0.5}
                                _hover={{
                                    opacity: 1,
                                }}
                                boxShadow={'0px 4px 8px 0px #00000029'}
                                cursor={'pointer'}
                                right={0}
                                onClick={scrollToDiv}
                            >
                                <Image src={HoverTop} h={'24px'} />
                            </Flex>
                        </Flex>
                        <Flex
                            position={'fixed'}
                            right={['20px', '20px', '50px', '50px', '50px', '200px']}
                            justifyContent={'end'}
                            p={'15px'}
                            bottom={'20px'}
                            // display={showButton ? 'block' : 'none'}
                            display={['block', 'block', 'none']}
                        >
                            <Flex
                                justifyContent={'center'}
                                alignItems={'center'}
                                // position={'fixed'}
                                w={'48px'}
                                h={'48px'}
                                borderRadius={'48px'}
                                bottom={'-100px'}
                                backgroundColor={'#FFFFFF'}
                                opacity={0.5}
                                _hover={{
                                    opacity: 1,
                                }}
                                boxShadow={'0px 4px 8px 0px #00000029'}
                                cursor={'pointer'}
                                right={0}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }}
                            >
                                <Image src={HoverTop} h={'24px'} />
                            </Flex>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </>
    );
};

export default React.memo(NewsSection);
