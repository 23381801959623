export const historyImgList = {
    img01: require('../../../assets/images/main/history01.png'),
    img02: require('../../../assets/images/main/history02.png'),
    img03: require('../../../assets/images/main/history03.png'),
    img04: require('../../../assets/images/main/history04.png'),
    img05: require('../../../assets/images/main/history05.png'),
    img06: require('../../../assets/images/main/history06.png'),
    img07: require('../../../assets/images/main/history07.png'),
    img08: require('../../../assets/images/main/history08.png'),
    img09: require('../../../assets/images/main/history08.png'),
    img10: require('../../../assets/images/main/history09.png'),
    img11: require('../../../assets/images/main/history09.png'),
    img12: require('../../../assets/images/main/history10.png'),
    img13: require('../../../assets/images/main/history10.png'),
    img14: require('../../../assets/images/main/history11.png'),
    img15: require('../../../assets/images/main/history11.png'),
    img16: require('../../../assets/images/main/history12.png'),
    img17: require('../../../assets/images/main/history12.png'),
    img18: require('../../../assets/images/main/history13.png'),
    img19: require('../../../assets/images/main/history13.png'),
    imgNew: require('../../../assets/images/main/historyNew.png'),
    imgDisabled: require('../../../assets/images/main/historyDisabled.png'),
};
